import React, { FC } from 'react'
import { useUserContext } from '../../../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import { isMobileOrTabletDevice } from '../../../utils/deviceUtils'
import {
  APP_NAME,
  LANDING_PAGE_URL,
  APP_LOGO_WORDMARK_URL,
  CHROME_STORE_URL,
} from '../../../constants'
import { Box, Button, Heading, Img, Link, Text } from '@chakra-ui/react'

const heading = 'Great!'
const headingLineTwo = 'Almost there.'
const content = ['Check your email on your computer to install Skipper 💻']
const subtext = 'Not at your computer?'
const buttonText = 'See how Skipper works'

const MobileSignupPage: FC = () => {
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const onClickLogo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    captureAnalytics('mobile_signup_page:logo_click', {
      url: window.location.href,
      heading: heading,
      isMobileOrTabletDevice,
    })
    if (isMobileOrTabletDevice) {
      window.location.href = LANDING_PAGE_URL
    } else {
      navigate('/')
    }
  }

  const onClickPrimaryButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    captureAnalytics('mobile_signup_page:primary_button_click', {
      url: window.location.href,
      heading: heading,
      isMobileOrTabletDevice,
    })
    window.location.href = CHROME_STORE_URL
  }

  return (
    <Box
      width="100%"
      height="100%"
      px={{ base: '16px', md: '160px' }}
      py={{ base: '32px', md: '40px' }}
    >
      <Box mb={{ base: '80px', md: '160px' }}>
        <Link href={isMobileOrTabletDevice ? LANDING_PAGE_URL : '/'} onClick={onClickLogo}>
          <Img
            src={APP_LOGO_WORDMARK_URL}
            alt={`${APP_NAME} logo`}
            maxHeight={{ base: '24px', md: '35px' }}
          />
        </Link>
      </Box>
      <Heading
        size="3xl"
        maxWidth="360px"
        fontWeight="500"
        mb="40px"
        style={{ wordWrap: 'normal' }}
      >
        {heading}
        <br />
        {headingLineTwo}
      </Heading>
      <Box mb="80px">
        {content.map((paragraph, i) => (
          <Text
            key={i}
            fontSize="20px"
            fontWeight="400"
            lineHeight="28px"
            mb="20px"
            maxWidth="620px"
          >
            {paragraph}
          </Text>
        ))}
      </Box>
      <Box mb="20px">
        <Text color="#585858" fontSize="20px" fontWeight="400" lineHeight="28px">
          {subtext}
        </Text>
      </Box>
      <Box>
        <Button
          as="a"
          w="244px"
          h="46px"
          borderRadius="100px"
          border="1px solid #585858"
          backgroundColor="white"
          color="black"
          fontWeight="normal"
          fontSize="16px"
          lineHeight="22px"
          title={buttonText}
          rel="noreferrer"
          href={CHROME_STORE_URL}
          onClick={onClickPrimaryButton}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}

export default MobileSignupPage
