import styled from 'styled-components'

//https://www.pinkdroids.com/blog/svg-react-styled-components/
const SVG = styled.svg.attrs<{
  version?: string
  xmlns?: string
  xmlnsXlink?: string
  stroke?: string
  fill?: string
}>(({ version, xmlns, xmlnsXlink, stroke, fill }) => ({
  version: version ?? '1.0',
  xmlns: xmlns ?? 'http://www.w3.org/2000/svg',
  xmlnsXlink: xmlnsXlink ?? 'http://www.w3.org/1999/xlink',
  stroke: stroke ?? 'currentColor',
  fill: fill ?? 'currentColor',
}))``

export default SVG
