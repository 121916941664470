import { parse } from 'tldts'

const SPECIAL_CASES = {
  ['google.com']: {
    docs: {
      document: 'Google Docs',
      presentation: 'Google Slides',
      spreadsheets: 'Google Sheets',
      forms: 'Google Forms',
    },
    mail: 'Gmail',
    calendar: 'Google Calendar',
    drive: 'Google Drive',
    _default: 'Google',
  },
  ['amazon.com']: { aws: 'AWS', _default: 'Amazon' },
  ['LOCAL_FILE_DOMAIN']: { _default: 'Files' },
  ['youtube.com']: { _default: 'Youtube' },
  ['github.com']: { _default: 'Github' },
  ['linkedin.com']: { _default: 'LinkedIn' },
  ['reddit.com']: { _default: 'Reddit' },
  ['bing.com']: { _default: 'Bing' },
  ['nytimes.com']: { _default: 'The New York Times' },
  ['atlassian.net']: { _default: 'Atlassian' },
  ['openai.com']: { _default: 'OpenAI' },
  ['sharepoint.com']: { _default: 'SharePoint' },
  ['facebook.com']: { _default: 'Facebook' },
  ['wikipedia.org']: { _default: 'Wikipedia' },
  ['twitter.com']: { _default: 'Twitter' },
  ['microsoft.com']: { _default: 'Microsoft' },
  ['cmu.edu']: { _default: 'Carnegie Mellon University' },
  ['instagram.com']: { _default: 'Instagram' },
  ['medium.com']: { _default: 'Medium' },
  ['ebay.com']: { _default: 'eBay' },
  ['zoom.us']: { _default: 'Zoom' },
  ['canva.com']: { _default: 'Canva' },
  ['dexscreener.com']: { _default: 'Dexscreener' },
  ['stackoverflow.com']: { _default: 'Stack Overflow' },
  ['washingtonpost.com']: { _default: 'The Washington Post' },
  ['apple.com']: { _default: 'Apple' },
  ['figma.com']: { _default: 'Figma' },
  ['etsy.com']: { _default: 'Etsy' },
  ['cnn.com']: { _default: 'CNN' },
  ['notion.so']: { _default: 'Notion' },
  ['instructure.com']: { _default: 'Instructure' },
  ['yahoo.com']: { _default: 'Yahoo' },
  ['adobe.com']: { _default: 'Adobe' },
  ['msn.com']: { _default: 'MSN' },
  ['duckduckgo.com']: { _default: 'DuckDuckGo' },
  ['substack.com']: { _default: 'Substack' },
  ['dropbox.com']: { _default: 'Dropbox' },
  ['producthunt.com']: { _default: 'Product Hunt' },
  ['slack.com']: { _default: 'Slack' },
  ['perplexity.ai']: { _default: 'Perplexity' },
  ['force.com']: { _default: 'Salesforce' },
  ['hubspot.com']: { _default: 'HubSpot' },
  ['intuit.com']: { _default: 'Intuit' },
  ['espn.com']: { _default: 'ESPN' },
  ['office.com']: { _default: 'Microsoft Office' },
  ['shopify.com']: { _default: 'Shopify' },
  ['posthog.com']: { _default: 'PostHog' },
  ['dtveng.net']: { _default: 'DTV Engineering' },
  ['localhost']: { _default: 'Localhost' },
  ['tuttletwins.com']: { _default: 'Tuttle Twins' },
  ['spotify.com']: { _default: 'Spotify' },
  ['appsumo.com']: { _default: 'AppSumo' },
  ['craigslist.org']: { _default: 'Craigslist' },
  ['loom.com']: { _default: 'Loom' },
  ['github.io']: { _default: 'GitHub Pages' },
  ['atlassian.com']: { _default: 'Atlassian' },
  ['westlaw.com']: { _default: 'Westlaw' },
  ['redhat.com']: { _default: 'Red Hat' },
  ['indeed.com']: { _default: 'Indeed' },
  ['live.com']: { _default: 'Microsoft Live' },
  ['getalma.com']: { _default: 'Alma' },
  ['asana.com']: { _default: 'Asana' },
  ['irs.gov']: { _default: 'IRS' },
  ['wappalyzer.com']: { _default: 'Wappalyzer' },
  ['webflow.com']: { _default: 'Webflow' },
  ['ycombinator.com']: { news: 'HackerNews', _default: 'Y Combinator' },
  ['wix.com']: { _default: 'Wix' },
  ['microcosmpublishing.com']: { _default: 'Microcosm Publishing' },
  ['nih.gov']: { _default: 'NIH' },
  ['zoho.com']: { _default: 'Zoho' },
  ['stripe.com']: { _default: 'Stripe' },
  ['amazonaws.com']: { _default: 'Amazon Web Services' },
  ['kickstarter.com']: { _default: 'Kickstarter' },
  ['brave.com']: { _default: 'Brave' },
  ['superhuman.com']: { _default: 'Superhuman' },
  ['quora.com']: { _default: 'Quora' },
  ['upwork.com']: { _default: 'Upwork' },
  ['kajabi.com']: { _default: 'Kajabi' },
  ['box.com']: { _default: 'Box' },
  ['airtable.com']: { _default: 'Airtable' },
  ['notion.site']: { _default: 'Notion' },
  ['ezlynx.com']: { _default: 'EZLynx' },
  ['discord.com']: { _default: 'Discord' },
  ['calendly.com']: { _default: 'Calendly' },
  ['service-now.com']: { _default: 'ServiceNow' },
  ['duke.edu']: { _default: 'Duke University' },
  ['googleusercontent.com']: { _default: 'Google User Content' },
  ['miro.com']: { _default: 'Miro' },
  ['gamer.com.tw']: { _default: 'Gamer' },
  ['stackexchange.com']: { _default: 'Stack Exchange' },
  ['arxiv.org']: { _default: 'arXiv' },
  ['usc.edu']: { _default: 'University of Southern California' },
  ['coda.io']: { _default: 'Coda' },
  ['coursera.org']: { _default: 'Coursera' },
  ['larksuite.com']: { _default: 'Lark Suite' },
  ['fandom.com']: { _default: 'Fandom' },
  ['otter.ai']: { _default: 'Otter.ai' },
  ['dribbble.com']: { _default: 'Dribbble' },
  ['yelp.com']: { _default: 'Yelp' },
  ['myworkdayjobs.com']: { _default: 'Workday Jobs' },
  ['clickup.com']: { _default: 'ClickUp' },
  ['uberinternal.com']: { _default: 'Uber Internal' },
  ['costar.com']: { _default: 'CoStar' },
  ['mailchi.mp']: { _default: 'Mailchimp' },
  ['dynamics.com']: { _default: 'Microsoft Dynamics' },
  ['salesforce.com']: { _default: 'Salesforce' },
  ['netflix.com']: { _default: 'Netflix' },
  ['slate.com']: { _default: 'Slate' },
  ['patreon.com']: { _default: 'Patreon' },
  ['territorialseed.com']: { _default: 'Territorial Seed Company' },
  ['walmart.com']: { _default: 'Walmart' },
  ['betalist.com']: { _default: 'BetaList' },
  ['webflow.io']: { _default: 'Webflow' },
  ['cloudflare.com']: { _default: 'Cloudflare' },
  ['greenhouse.io']: { _default: 'Greenhouse' },
  ['ilga.gov']: { _default: 'Illinois General Assembly' },
  ['godaddy.com']: { _default: 'GoDaddy' },
  ['acm.org']: { _default: 'ACM' },
  ['loomissayles.com']: { _default: 'Loomis Sayles' },
  ['skool.com']: { _default: 'Skool' },
  ['homedepot.com']: { _default: 'The Home Depot' },
  ['airbnb.com']: { _default: 'Airbnb' },
  ['anthropic.com']: { _default: 'Anthropic' },
  ['monday.com']: { _default: 'Monday.com' },
  ['udemy.com']: { _default: 'Udemy' },
  ['crexi.com']: { _default: 'Crexi' },
  ['tryvital.io']: { _default: 'Vital' },
  ['cornell.edu']: { _default: 'Cornell University' },
  ['bestbuy.com']: { _default: 'Best Buy' },
  ['amazon.in']: { _default: 'Amazon India' },
  ['intercom.com']: { _default: 'Intercom' },
  ['zillow.com']: { _default: 'Zillow' },
  ['amplitude.com']: { _default: 'Amplitude' },
  ['mozilla.org']: { _default: 'Mozilla' },
  ['skeema.com']: { _default: 'Skeema' },
  ['skipper.com']: { _default: 'Skipper' },
  ['forbes.com']: { _default: 'Forbes' },
  ['squarespace.com']: { _default: 'Squarespace' },
  ['rei.com']: { _default: 'REI' },
  ['slickdeals.net']: { _default: 'Slickdeals' },
  ['leetcode.com']: { _default: 'LeetCode' },
  ['linktr.ee']: { _default: 'Linktree' },
  ['paypal.com']: { _default: 'PayPal' },
  ['ssense.com']: { _default: 'SSENSE' },
  ['partstown.com']: { _default: 'PartsTown' },
  ['tiktok.com']: { _default: 'TikTok' },
  ['bilibili.com']: { _default: 'Bilibili' },
  ['datacamp.com']: { _default: 'Datacamp' },
  ['bhphotovideo.com']: { _default: 'B&H Photo Video' },
  ['sciencedirect.com']: { _default: 'Science Direct' },
  ['csueastbay.edu']: { _default: 'CSU East Bay' },
  ['gdm-connect.com']: { _default: 'GDM Connect' },
  ['ariasystems.net']: { _default: 'Aria Systems' },
  ['whatsapp.com']: { _default: 'WhatsApp' },
  ['mykajabi.com']: { _default: 'My Kajabi' },
  ['zendesk.com']: { _default: 'Zendesk' },
  ['chase.com']: { _default: 'Chase Bank' },
  ['customer.io']: { _default: 'Customer.io' },
  ['getsavi.com']: { _default: 'Savi' },
  ['soundcloud.com']: { _default: 'SoundCloud' },
  ['burpee.com']: { _default: 'Burpee' },
  ['imdb.com']: { _default: 'IMDB' },
  ['netvoyage.com']: { _default: 'NetVoyage' },
  ['samsung.com']: { _default: 'Samsung' },
  ['lucid.app']: { _default: 'Lucid App' },
  ['zapier.com']: { _default: 'Zapier' },
  ['eventbrite.com']: { _default: 'Eventbrite' },
  ['rollingstone.com']: { _default: 'Rolling Stone' },
  ['coinmarketcap.com']: { _default: 'CoinMarketCap' },
  ['airbyte.com']: { _default: 'Airbyte' },
  ['redfin.com']: { _default: 'Redfin' },
  ['techcrunch.com']: { _default: 'TechCrunch' },
  ['scharp.org']: { _default: 'Scharp' },
  ['lensa.com']: { _default: 'Lensa' },
  ['vimeo.com']: { _default: 'Vimeo' },
  ['fiverr.com']: { _default: 'Fiverr' },
  ['okta.com']: { _default: 'Okta' },
  ['microsoftonline.com']: { _default: 'Microsoft Online' },
  ['blogspot.com']: { _default: 'Blogspot' },
  ['themeforest.net']: { _default: 'ThemeForest' },
  ['pinterest.com']: { _default: 'Pinterest' },
  ['tableau.com']: { _default: 'Tableau' },
  ['target.com']: { _default: 'Target' },
  ['fidelity.com']: { _default: 'Fidelity' },
  ['current-rms.com']: { _default: 'Current RMS' },
  ['syracuse.com']: { _default: 'Syracuse News' },
  ['temu.com']: { _default: 'Temu' },
  ['defector.com']: { _default: 'Defector' },
  ['claude.ai']: { _default: 'Claude AI' },
  ['harvard.edu']: { _default: 'Harvard University' },
  ['joinsecret.com']: { _default: 'Join Secret' },
  ['farfetch.com']: { _default: 'Farfetch' },
  ['geeksforgeeks.org']: { _default: 'GeeksforGeeks' },
  ['fathom.video']: { _default: 'Fathom Video' },
  ['churchofjesuschrist.org']: { _default: 'Church of Jesus Christ' },
  ['utah.edu']: { _default: 'University of Utah' },
  ['tennessee.edu']: { _default: 'University of Tennessee' },
  ['mit.edu']: { _default: 'Massachusetts Institute of Technology' },
  ['glassdoor.com']: { _default: 'Glassdoor' },
  ['linear.app']: { _default: 'Linear App' },
  ['aliexpress.us']: { _default: 'AliExpress US' },
  ['windows.net']: { _default: 'Windows Network' },
  ['drivethrurpg.com']: { _default: 'DriveThruRPG' },
  ['archive.ph']: { _default: 'Archive Today' },
  ['asapp.com']: { _default: 'ASAPP' },
  ['theathletic.com']: { _default: 'The Athletic' },
  ['crunchbase.com']: { _default: 'Crunchbase' },
  ['ecosia.org']: { _default: 'Ecosia' },
  ['wordpress.com']: { _default: 'WordPress' },
  ['wicourts.gov']: { _default: 'Wisconsin Courts' },
  ['w3schools.com']: { _default: 'W3Schools' },
  ['usps.com']: { _default: 'USPS' },
  ['ups.com']: { _default: 'UPS' },
  ['cnbc.com']: { _default: 'CNBC' },
  ['hex.tech']: { _default: 'Hex Technologies' },
  ['azure.com']: { _default: 'Microsoft Azure' },
  ['billpetro.com']: { _default: 'Bill Petro History' },
  ['aniwave.to']: { _default: 'AniWave' },
  ['sentry.io']: { _default: 'Sentry' },
  ['bladehq.com']: { _default: 'Blade HQ' },
  ['axios.com']: { _default: 'Axios' },
  ['wsj.com']: { _default: 'The Wall Street Journal' },
  ['qualtrics.com']: { _default: 'Qualtrics' },
  ['theatlantic.com']: { _default: 'The Atlantic' },
  ['trello.com']: { _default: 'Trello' },
  ['vrbo.com']: { _default: 'Vrbo' },
  ['freshdesk.com']: { _default: 'Freshdesk' },
  ['archive.org']: { _default: 'Internet Archive' },
  ['theverge.com']: { _default: 'The Verge' },
  ['tradepub.com']: { _default: 'TradePub' },
  ['lever.co']: { _default: 'Lever' },
  ['oreilly.com']: { _default: "O'Reilly Media" },
  ['microsoft365.com']: { _default: 'Microsoft 365' },
  ['nextdoor.com']: { _default: 'Nextdoor' },
  ['lowes.com']: { _default: "Lowe's" },
  ['dell.com']: { _default: 'Dell' },
  ['ticketmaster.com']: { _default: 'Ticketmaster' },
  ['gumroad.com']: { _default: 'Gumroad' },
  ['emory.edu']: { _default: 'Emory University' },
  ['npr.org']: { _default: 'NPR' },
  ['unsplash.com']: { _default: 'Unsplash' },
  ['behance.net']: { _default: 'Behance' },
  ['ca.gov']: { _default: 'California State Portal' },
} as Record<string, Record<string, string | Record<string, string>>>

interface UrlParseResult {
  domain: string | null
  subdomains: string[]
  pathFields: string[]
}

export function parseUrl(url: string): UrlParseResult {
  if (url.startsWith('file')) {
    return {
      domain: 'LOCAL_FILE_DOMAIN',
      subdomains: [],
      pathFields: [],
    }
  }

  if (url.startsWith('blob:')) {
    url = url.slice(5)
  }

  const { domain, subdomain } = parse(url)
  const subdomains = subdomain ? subdomain.split('.').reverse() : []

  const urlObj = new URL(url)
  const pathFields = urlObj.pathname.split('/').slice(1)

  return { domain, subdomains, pathFields }
}

export function getAppName(url: string): string | undefined {
  let parsedUrl
  try {
    parsedUrl = parseUrl(url)
  } catch (e) {
    console.error(`Error parsing url: ${url}`, e)
    return undefined
  }
  const domain = parsedUrl.domain
  const subdomain = parsedUrl.subdomains[0]
  const firstPathField = parsedUrl.pathFields[0]

  if (domain === null) {
    return undefined
  }

  if (SPECIAL_CASES[domain] !== undefined) {
    if (SPECIAL_CASES[domain][subdomain] !== undefined) {
      if (typeof SPECIAL_CASES[domain][subdomain] === 'string') {
        return SPECIAL_CASES[domain][subdomain] as string
      } else {
        const firstPathMap = SPECIAL_CASES[domain][subdomain] as Record<string, string>
        if (firstPathMap[firstPathField] !== undefined) {
          return firstPathMap[firstPathField]
        }
      }
    }
    if (SPECIAL_CASES[domain]._default !== undefined) {
      return SPECIAL_CASES[domain]._default as string
    }
  }

  return domain
}
