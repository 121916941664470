import React, { FC } from 'react'
import { Flex, Text, Spinner, Button } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa6'

interface NotificationToastProps {
  message: string
  isLoading?: boolean
  showButton?: boolean
  buttonText?: string
  onClickButton?: () => void
}

const NotificationToast: FC<NotificationToastProps> = (props) => {
  const { message, isLoading = false, showButton = false, buttonText = '', onClickButton } = props

  return (
    <Flex
      bg="#CCE3F9"
      color="#0071E3"
      borderRadius="40px"
      borderColor="#CCE3F9"
      border="1px solid #A3CCF5"
      boxShadow="0px 0px 4px 0px #0000000A, 0px 8px 16px 0px #00000014"
      mb="32px"
      gap="40px"
      p="8px 24px"
      alignItems="center"
    >
      {isLoading ? (
        <Text fontSize="md" fontWeight={500} ml={6}>
          <Spinner />
        </Text>
      ) : (
        <>
          <Text
            className="is-ellipsis-1"
            fontSize="md"
            fontWeight={500}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {message}
          </Text>
          {showButton && (
            <Button
              isLoading={isLoading}
              bg="#0071E3"
              color="white"
              borderRadius="100px"
              _hover={{ bg: '#0071E3' }}
            >
              <Text fontSize="md" fontWeight={500} ml={2} mr={2} onClick={onClickButton}>
                {buttonText}
              </Text>
              <FaArrowRight style={{ width: '18px', flexShrink: 0 }} />
            </Button>
          )}
        </>
      )}
    </Flex>
  )
}

export default NotificationToast
