import { skeemaApi } from '../skeema'
import {
  AI_ACTION_LOGS_PATH,
  AIActionEndpointType,
  AIActionRevertEndpointType,
} from '../../../models/endpoints.types'

export const feedHistoryApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    getAIActionLogs: builder.query<
      AIActionEndpointType['GET']['response'],
      {
        page?: number
      }
    >({
      query: ({ page = 1 }) => {
        return {
          url: `${AI_ACTION_LOGS_PATH}?page=${page}`,
          method: 'GET',
        }
      },
      providesTags: (response) =>
        response
          ? [
              ...response.results.map(({ id }) => ({ type: 'HistoryItem' as const, id })),
              'HistoryItem',
            ]
          : ['HistoryItem'],
    }),
    revertAIAction: builder.mutation<
      AIActionRevertEndpointType['POST']['response'],
      {
        id: number
      }
    >({
      query: ({ id }) => {
        return {
          url: `${AI_ACTION_LOGS_PATH}${id}/revert/`,
          method: 'POST',
        }
      },
      invalidatesTags: (_, __, arg) => [{ type: 'HistoryItem', id: arg.id }],
    }),
  }),
})

export const { useGetAIActionLogsQuery, useLazyGetAIActionLogsQuery, useRevertAIActionMutation } =
  feedHistoryApi
