import React, { FC, useRef, useEffect } from 'react'
import { Flex, Box, Grid, GridItem } from '@chakra-ui/react'
import { css, cx } from '@emotion/css'
import ProjectsSidebar from './projectsSidebar/ProjectsSidebar'
import SubNavButtons from './SubNavButtons'
import PaymentFlowModal from './payment/PaymentFlowModal'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import CustomDragLayer from './CustomDragLayer'
import SearchBar from './search/SearchBar'
import PinningTutorialOverlay from './onboarding/PinningTutorialOverlay'
import ProjectArchiveDashboard from './projectArchive/ProjectArchiveDashboard'
import { useReduxSelector } from '../../redux/baseStore'
import PutasideTabList, { PUTASIDE_TABLIST_VIEW_ENUM } from './PutasideTabList/PutasideTabList'
import {
  ONBOARDING_TUTORIAL_STEPS,
  VALID_ONBOARDING_TUTORIAL_STEPS,
} from './onboarding/onboarding.types'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../models/browserStorage.types'
import MagicMomentTablist from './onboarding/MagicMomentTablist'
import PinningTablist from './onboarding/PinningTablist'
import OpenTablist from './onboarding/OpenTablist'
import ProjectDetailDashboard from './projectDetail/ProjectDetailDashboard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CompletedActionsDashboard from './completedActions/CompletedActionsDashboard'
import { useLocalStorage } from '../../utils/genericHooks'
import OnboardingFlowController from './onboarding/OnboardingFlowController'
import SettingsDashboard from './settings/SettingsDashboard'
import GuidedAutoSaveModal from './guidedAutoSave/GuidedAutoSaveModal'
import FixAutoSaveTimingBugWidget from './settings/FixAutoSaveTimingBugWidget'

export type CONTENT_TYPE =
  | 'tablist'
  | 'archive'
  | 'project'
  | 'saved'
  | 'actions'
  | 'settings'
  | 'sessions'

const disableScrollClassName = css`
  overflow: hidden;
  max-width: 100vw;
`

const disablePointerEventsClassName = css`
  * {
    pointer-events: none;
  }
`
const enablePointerEventsClassName = css`
  * {
    pointer-events: auto;
  }
`

interface Props {
  contentType: CONTENT_TYPE
}

const MainDashboard: FC<Props> = (props) => {
  const { contentType } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId } = useParams<{ projectId: string }>()
  const { hash } = useLocation()
  const isGuidedAutoSaveModalOpen = hash === '#guided_autosave'
  const authHeader = useReduxSelector((state) => state.user.authHeader)
  const isAuthLoading = useReduxSelector((state) => state.user.isAuthLoading)

  const tablistScrollRef = useRef<HTMLDivElement | null>(null)
  const { featureFlags } = useFeatureFlagContext()
  const isPaywallEnabled = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]

  const [currentStep, setCurrentStep] = useLocalStorage<ONBOARDING_TUTORIAL_STEPS>(
    WEBAPP_LOCAL_STORAGE_KEYS.CurrentOnboardingTutorialStepV2,
    null,
  )
  const isOnboardingTutorialEnabled =
    currentStep !== null &&
    currentStep !== ONBOARDING_TUTORIAL_STEPS.DONE &&
    VALID_ONBOARDING_TUTORIAL_STEPS[currentStep]

  useEffect(() => {
    if (isOnboardingTutorialEnabled && !['/', '/saved', '/sessions'].includes(location.pathname)) {
      navigate('/')
    }
  }, [isOnboardingTutorialEnabled, location.pathname, navigate])

  const shouldBlurTablist =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.TabGroups

  const shouldBlurFullScreen =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.Preface

  const shouldDisableScroll = shouldBlurFullScreen

  const isProjectsSidebarBlurred =
    isOnboardingTutorialEnabled && currentStep !== ONBOARDING_TUTORIAL_STEPS.TabGroups
  const projectSidebarWidth = isProjectsSidebarBlurred ? '240px' : '320px'

  const isTablistEnabled =
    !isOnboardingTutorialEnabled || currentStep !== ONBOARDING_TUTORIAL_STEPS.Preface

  const isMagicMomentTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig

  const isPinningTablistEnabled =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.PinTabs

  const isOpenTablistEnabled =
    isOnboardingTutorialEnabled &&
    (currentStep === ONBOARDING_TUTORIAL_STEPS.Stats ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.Intro ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.Intro2 ||
      currentStep === ONBOARDING_TUTORIAL_STEPS.TabGroups)

  const isPutasideTablistEnabled =
    !isMagicMomentTablistEnabled && !isPinningTablistEnabled && !isOpenTablistEnabled

  const isSmartSessionOnboardingActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.DraftProjects

  const isSavedTabsOnboardingStepActive =
    isOnboardingTutorialEnabled && currentStep === ONBOARDING_TUTORIAL_STEPS.TabList

  const shouldDisableTablistScroll = false

  useEffect(() => {
    //This boolean exists so this useEffect runs every time the tablist changes
    const isAnyTablistEnabled =
      isTablistEnabled ||
      isMagicMomentTablistEnabled ||
      isPinningTablistEnabled ||
      isOpenTablistEnabled ||
      isPutasideTablistEnabled
    if (tablistScrollRef.current && isAnyTablistEnabled) {
      tablistScrollRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [
    isMagicMomentTablistEnabled,
    isOpenTablistEnabled,
    isPinningTablistEnabled,
    isPutasideTablistEnabled,
    isTablistEnabled,
  ])

  if (isAuthLoading || !authHeader) {
    return null
  }

  return (
    <Flex
      className={shouldDisableScroll ? disableScrollClassName : ''}
      direction="row"
      h="100%"
      w="100%"
    >
      <FixAutoSaveTimingBugWidget />
      {isGuidedAutoSaveModalOpen && <GuidedAutoSaveModal />}
      <PinningTutorialOverlay />
      <CustomDragLayer />
      {isPaywallEnabled && <PaymentFlowModal />}
      <OnboardingFlowController
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
      />
      <Box
        className={isOnboardingTutorialEnabled ? disablePointerEventsClassName : ''}
        minWidth={projectSidebarWidth}
        w={projectSidebarWidth}
        maxW={projectSidebarWidth}
        h="100%"
        overflow="hidden"
      >
        <ProjectsSidebar
          isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
          isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
          isProjectsSidebarBlurred={isProjectsSidebarBlurred}
          detailProjectId={projectId}
        />
      </Box>
      <Grid
        className={isOnboardingTutorialEnabled ? disablePointerEventsClassName : ''}
        templateColumns="repeat(12, 1fr)"
        templateRows="68px auto"
        flex="1 1 auto"
        p="24px 24px 0 24px"
      >
        <GridItem
          colSpan={8}
          gridRow={1}
          minWidth="552px"
          maxWidth="1000px"
          opacity={isOnboardingTutorialEnabled ? 0.4 : 1}
        >
          <SearchBar />
        </GridItem>

        <GridItem
          colSpan={4}
          gridColumnStart={9}
          gridRow={1}
          minWidth="154px"
          opacity={isOnboardingTutorialEnabled ? 0.1 : 1}
        >
          <SubNavButtons />
        </GridItem>

        {(contentType === 'tablist' || contentType === 'saved' || contentType === 'sessions') && (
          <GridItem
            ref={tablistScrollRef}
            id="tablist-scroll-container"
            className={cx({
              scrollbars: true,
              [enablePointerEventsClassName]: !isPutasideTablistEnabled,
            })}
            pointerEvents="auto"
            colSpan={12}
            gridRow={2}
            overflow={shouldDisableTablistScroll ? 'hidden' : 'auto'}
            borderRadius="16px"
          >
            <Grid
              templateColumns="repeat(12, 1fr)"
              width="100%"
              flex="1 1 auto"
              minHeight="100%"
              maxWidth={'1600px'}
            >
              <GridItem
                colSpan={8}
                minWidth="552px"
                maxWidth={isOnboardingTutorialEnabled ? '650px' : '1000px'}
                borderRadius="16px"
                backgroundColor={!isTablistEnabled ? undefined : '#f6f6f6'}
                opacity={shouldBlurTablist ? 0.3 : 1}
                overflow="hidden"
              >
                {isTablistEnabled && (
                  <>
                    {isMagicMomentTablistEnabled && <MagicMomentTablist />}
                    {isPinningTablistEnabled && <PinningTablist />}
                    {isOpenTablistEnabled && <OpenTablist />}
                    {isPutasideTablistEnabled && (
                      <PutasideTabList
                        defaultView={
                          contentType === 'saved'
                            ? PUTASIDE_TABLIST_VIEW_ENUM.SAVED_TABS
                            : PUTASIDE_TABLIST_VIEW_ENUM.SESSIONS
                        }
                        isOnboardingTutorialEnabled={isOnboardingTutorialEnabled}
                        isSmartSessionOnboardingActive={isSmartSessionOnboardingActive}
                        isSavedTabsOnboardingStepActive={isSavedTabsOnboardingStepActive}
                      />
                    )}
                  </>
                )}
              </GridItem>
            </Grid>
          </GridItem>
        )}

        {(contentType === 'actions' ||
          contentType === 'archive' ||
          contentType === 'project' ||
          contentType === 'settings') && (
          <GridItem
            colSpan={8}
            rowStart={2}
            rowSpan={2}
            height="100%"
            minWidth="552px"
            maxWidth="1000px"
            borderRadius="16px"
            backgroundColor="#f6f6f6"
            className="scrollbars"
            overflow="auto"
          >
            {contentType === 'settings' && <SettingsDashboard />}
            {contentType === 'actions' && <CompletedActionsDashboard />}
            {contentType === 'archive' && <ProjectArchiveDashboard authHeader={authHeader} />}
            {contentType === 'project' && !!projectId && (
              <ProjectDetailDashboard projectId={projectId} />
            )}
          </GridItem>
        )}
      </Grid>
    </Flex>
  )
}

export default MainDashboard
