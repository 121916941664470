import React, { FC } from 'react'
import { Box, Heading, Image, Text } from '@chakra-ui/react'
import { useUserContext } from '../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import { APP_LOGO_WORDMARK_URL, APP_NAME, LANDING_PAGE_URL } from '../constants'
import { isMobileOrTabletDevice } from '../utils/deviceUtils'

interface Props {
  heading: string
  headingLineTwo?: string
  content: string[]
  headingMaxWidth?: string
  widget?: React.ReactNode
  subtext?: string
  linkText?: string
  linkHref?: string
}

const AnnouncementsPage: FC<Props> = (props) => {
  const { heading, headingLineTwo, content, headingMaxWidth, widget, subtext, linkText, linkHref } =
    props
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const onClickLogo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    captureAnalytics('announcements_page:logo_click', {
      url: window.location.href,
      heading: heading,
      isMobileOrTabletDevice,
    })
    if (isMobileOrTabletDevice) {
      window.location.href = LANDING_PAGE_URL
    } else if (!window.location.href.endsWith('/extension/install')) {
      // If on '/extension/install', don't redirect to the same page
      navigate('/')
    }
  }

  return (
    <Box
      width="100%"
      height="100%"
      px={{ base: '16px', md: '160px' }}
      py={{ base: '32px', md: '40px' }}
    >
      <Box mb={{ base: '56px', md: '160px' }}>
        <a href={isMobileOrTabletDevice ? LANDING_PAGE_URL : '/'} onClick={onClickLogo}>
          <Image
            src={APP_LOGO_WORDMARK_URL}
            alt={`${APP_NAME} logo`}
            maxHeight={{ base: '24px', md: '35px' }}
          />
        </a>
      </Box>
      <Heading
        size="3xl"
        maxWidth={headingMaxWidth ?? '360px'}
        fontWeight="500"
        mb="40px"
        style={{ wordWrap: 'normal' }}
      >
        {heading}
        {headingLineTwo && (
          <>
            <br />
            {headingLineTwo}
          </>
        )}
      </Heading>
      <Box mb="80px">
        {content.map((paragraph, i) => (
          <Text key={i} fontSize="lg" mb="20px" maxWidth="620px">
            {paragraph}
          </Text>
        ))}
      </Box>
      {widget && <Box>{widget}</Box>}
      {(subtext || (linkText && linkHref)) && (
        <Box mt="20px">
          {subtext && <Text fontSize="sm">{subtext}</Text>}
          {linkText && linkHref && (
            <Text as="u" fontSize="sm">
              <a href={linkHref} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
            </Text>
          )}
        </Box>
      )}
    </Box>
  )
}

export default AnnouncementsPage
