import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import styled from 'styled-components'
import { isMac } from '../../../utils/utils'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../../../models/browserStorage.types'

const cmdOrCtrl = isMac() ? 'Cmd' : 'Ctrl'

const KeyIcon = styled.span`
  font-size: 12px;
  font-weight: 600;
  background-color: rgb(78, 78, 78);
  color: white;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px 6px;
`

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingTablistDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  const isGuidedAutoSaveSkipped =
    window.localStorage.getItem(WEBAPP_LOCAL_STORAGE_KEYS.OnboardingGuidedAutoSaveSkipped) ===
    'true'

  useEffect(() => {
    // Prevents bug where recently closed tabs are briefly included in the open count
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isLoading])

  const savedHeading =
    openTabPages && openTabPages.length === 0
      ? `Nice! Down to just the Skipper tab.`
      : `Nice! Down to just Skipper and ${openTabPages?.length ?? 'a few'} open ${
          (openTabPages?.length ?? 2) === 1 ? 'tab' : 'tabs'
        }.`

  const skippedHeading =
    openTabPages && openTabPages.length === 0
      ? `Okay! Down to just the Skipper tab. We'll suggest tabs to save and close once a day.`
      : `Okay! You have Skipper and ${openTabPages?.length ?? 'a few'} ${
          (openTabPages?.length ?? 2) === 1 ? 'tab' : 'tabs'
        } still open. We'll suggest tabs to save and close once a day.`

  const heading = isGuidedAutoSaveSkipped ? skippedHeading : savedHeading

  const description = (
    <>
      {`You can find the full list of your saved tabs in 'All saved tabs'. Come back to the Skipper app anytime by pressing`}
      <KeyIcon style={{ marginLeft: '6px' }}>{cmdOrCtrl}</KeyIcon>
      <KeyIcon>1</KeyIcon>
    </>
  )

  const onButtonClick = () => {
    onNext()
  }

  return (
    <OnboardingStepTemplate
      isCardLoading={isLoading}
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={heading}
      description={description}
      buttonText={`Okay`}
      onButtonClick={onButtonClick}
    />
  )
}
export default OnboardingTablistDialog
