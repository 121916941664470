import { gzip } from 'pako'
import { API_SERVER_URL } from '../../constants'
import { TabRecordType } from '../../extension/models/tabRecord.types'
import {
  USER_INFO_ENDPOINT_PATH,
  UserInfoEndpointType,
  USER_EVENT_UPDATE_ENDPOINT_PATH,
  UserEventUpdateEndpointType,
  EXCHANGE_GOOGLE_AUTH_CODE_ENDPOINT_PATH,
  ExchangeGoogleAuthTokenEndpointType,
  ANALYTICS_ENDPOINT_PATH,
  AnalyticsEndpointType,
  MagicMomentEndpointType,
  MAGIC_MOMENT_ENDPOINT_PATH,
  ProjectsEndpointType,
  PROJECTS_ENDPOINT_PATH,
  ProjectPagesEndpointType,
  PROJECT_PAGES_ENDPOINT_PATH,
  UpcomingPagesEndpointType,
  UPCOMING_PAGE_ENDPOINT_PATH,
  TablistPagesEndpointType,
  TABLIST_PAGES_ENDPOINT_PATH,
  TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH,
  TablistPagesRecentlyUsedEndpointType,
  TABLIST_PAGES_MOST_VISITED_ENDPOINT_PATH,
  TablistPagesMostVisitedEndpointType,
  DiagnosticLoggingEndpointType,
  DIAGNOSTIC_LOGGING_ENDPOINT_PATH,
  ACTION_QUOTA_ENDPOINT_PATH,
  ActionQuotaEndpointType,
  SmartSessionsEndpointType,
  SMART_SESSIONS_ENDPOINT_PATH,
  BOOTSTRAP_ENDPOINT_PATH,
  BootstrapEndpointType,
  ProjectEndpointType,
} from '../../models/endpoints.types'

export async function fetchSmartSessions(
  headers: Record<string, string>,
  openTabs: TabRecordType[],
): Promise<SmartSessionsEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${SMART_SESSIONS_ENDPOINT_PATH}`
  const payload = {
    open_tabs: openTabs.map((tab) => ({
      tab_id: tab.id,
      url: tab.url,
      title: tab.title,
      window_id: tab.windowId,
      favicon_url: tab.favIconUrl,
      last_access_time_ms: tab.lastVisitTime,
    })),
    open_tab_only: true,
  }
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as SmartSessionsEndpointType['POST']['response']

  return json
}

export async function fetchActionQuota(
  headers: Record<string, string>,
): Promise<ActionQuotaEndpointType['GET']['response']> {
  const endpoint = `${API_SERVER_URL}${ACTION_QUOTA_ENDPOINT_PATH}`
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      ...headers,
    },
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ActionQuotaEndpointType['GET']['response']

  return json
}

export async function postExtensionUninstallation(headers: Record<string, string>) {
  const endpoint = `${API_SERVER_URL}${USER_INFO_ENDPOINT_PATH}`
  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      ...headers,
    },
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  return null
}

export async function postDiagnositcLogging(
  events: DiagnosticLoggingEndpointType['POST']['request']['events'],
): Promise<{ status: number }> {
  const endpoint = `${API_SERVER_URL}${DIAGNOSTIC_LOGGING_ENDPOINT_PATH}`
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ events }),
  })

  return { status: response.status }
}

export async function fetchUpcomingPages(
  headers: Record<string, string>,
  url: string,
  title: string,
  pinned_urls: string[],
): Promise<UpcomingPagesEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${UPCOMING_PAGE_ENDPOINT_PATH}`

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      url,
      title,
      pinned_urls,
    }),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as UpcomingPagesEndpointType['POST']['response']

  return json
}

export async function postMagicMoment(
  headers: Record<string, string>,
  body: MagicMomentEndpointType['POST']['request'],
) {
  const endpoint = `${API_SERVER_URL}${MAGIC_MOMENT_ENDPOINT_PATH}`

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }
}

export async function postUserEventUpdate(
  headers: Record<string, string>,
  body: UserEventUpdateEndpointType['POST']['request'],
): Promise<UserEventUpdateEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${USER_EVENT_UPDATE_ENDPOINT_PATH}`
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as UserEventUpdateEndpointType['POST']['response']

  return json
}

export async function getUserInfo(
  headers: Record<string, string>,
  extensionVersion?: number,
  timezone?: string,
): Promise<UserInfoEndpointType['PUT']['response']> {
  const endpoint = `${API_SERVER_URL}${USER_INFO_ENDPOINT_PATH}`
  const data: UserInfoEndpointType['PUT']['request'] = {}
  if (timezone) {
    data.iana_timezone = timezone
  }
  if (extensionVersion) {
    data.extension_version = extensionVersion
  }
  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as UserInfoEndpointType['PUT']['response']

  return json
}

export async function exchangeAuthCode(
  code: string,
): Promise<ExchangeGoogleAuthTokenEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${EXCHANGE_GOOGLE_AUTH_CODE_ENDPOINT_PATH}`
  const formData = new FormData()
  const isSkipper = true
  formData.append('code', code)
  formData.append('isSkipper', isSkipper.toString())

  const response = await fetch(endpoint, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ExchangeGoogleAuthTokenEndpointType['POST']['response']

  return json
}

export async function postAnalytics(
  headers: Record<string, string>,
  body: AnalyticsEndpointType['POST']['request'],
) {
  const endpoint = `${API_SERVER_URL}${ANALYTICS_ENDPOINT_PATH}`
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  return null
}

export async function getProjects(
  headers: Record<string, string>,
): Promise<ProjectsEndpointType['GET']['response']> {
  const endpoint = `${API_SERVER_URL}${PROJECTS_ENDPOINT_PATH}`

  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ProjectsEndpointType['GET']['response']

  return json
}

export async function postProject(
  headers: Record<string, string>,
  body: ProjectsEndpointType['POST']['request'],
): Promise<ProjectsEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${PROJECTS_ENDPOINT_PATH}`
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ProjectsEndpointType['POST']['response']

  return json
}

export async function patchProject(
  headers: Record<string, string>,
  id: string,
  body: ProjectEndpointType['PATCH']['request'],
): Promise<ProjectEndpointType['PATCH']['response']> {
  const endpoint = `${API_SERVER_URL}${PROJECTS_ENDPOINT_PATH}${id}/`
  const response = await fetch(endpoint, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ProjectEndpointType['PATCH']['response']

  return json
}

export async function postProjectPage(
  headers: Record<string, string>,
  project_pk: string,
  body: ProjectPagesEndpointType['POST']['request'],
): Promise<ProjectPagesEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${PROJECT_PAGES_ENDPOINT_PATH(project_pk)}`
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ProjectPagesEndpointType['POST']['response']

  return json
}

export async function deleteProjectPage(
  headers: Record<string, string>,
  project_pk: string,
  id: string,
): Promise<ProjectPagesEndpointType['DELETE']['response']> {
  const endpoint = `${API_SERVER_URL}${PROJECT_PAGES_ENDPOINT_PATH(project_pk)}${id}/`
  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ProjectPagesEndpointType['DELETE']['response']

  return json
}

export async function updateProjectPage(
  headers: Record<string, string>,
  project_pk: string,
  id: string,
  body: ProjectPagesEndpointType['PUT']['request'],
): Promise<ProjectPagesEndpointType['PUT']['response']> {
  const endpoint = `${API_SERVER_URL}${PROJECT_PAGES_ENDPOINT_PATH(project_pk)}${id}/`
  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as ProjectPagesEndpointType['PUT']['response']

  return json
}

export async function getTablistPages(
  headers: Record<string, string>,
): Promise<TablistPagesEndpointType['GET']['response']> {
  const endpoint = `${API_SERVER_URL}${TABLIST_PAGES_ENDPOINT_PATH}`

  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as TablistPagesEndpointType['GET']['response']

  return json
}

export async function postTablistPageRecentlyUsed(
  headers: Record<string, string>,
  tablist: TabRecordType[],
  isManual: boolean,
  extensionVersion: number,
): Promise<TablistPagesRecentlyUsedEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH}`
  const body = {
    tablist: tablist.map((tab) => ({
      tab_id: tab.id,
      url: tab.url,
      title: tab.title,
      favicon_url: tab.favIconUrl,
      last_access_time_ms: tab.lastVisitTime,
      window_id: tab.windowId,
    })),
    is_manual: isManual,
    extension_version: extensionVersion,
  }
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  const json = (await response.json()) as TablistPagesRecentlyUsedEndpointType['POST']['response']

  return json
}

export async function deleteTablistPageRecentlyUsed(
  headers: Record<string, string>,
  id: string,
): Promise<TablistPagesRecentlyUsedEndpointType['DELETE']['response']> {
  const endpoint = `${API_SERVER_URL}${TABLIST_PAGES_RECENTLY_USED_ENDPOINT_PATH}${id}/`
  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  return null
}

export async function deleteTablistPageMostVisited(
  headers: Record<string, string>,
  id: string,
): Promise<TablistPagesMostVisitedEndpointType['DELETE']['response']> {
  const endpoint = `${API_SERVER_URL}${TABLIST_PAGES_MOST_VISITED_ENDPOINT_PATH}${id}/`
  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  return null
}

export async function postBootsrapData(
  headers: Record<string, string>,
  historyVisits: unknown,
): Promise<BootstrapEndpointType['POST']['response']> {
  const endpoint = `${API_SERVER_URL}${BOOTSTRAP_ENDPOINT_PATH}`

  const compressedBody = gzip(JSON.stringify(historyVisits))
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Content-Encoding': 'gzip',
      ...headers,
    },
    body: compressedBody,
  })

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`)
  }

  return (await response.json()) as BootstrapEndpointType['POST']['response']
}
