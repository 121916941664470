import React, { FC } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'
import { DND_ITEM_ENUM, DnDItemPayload } from '../../models/dnd.types'
import ProjectDragPreview from './projectsSidebar/ProjectDragPreview'
import Portal from '../../widgets/Portal'
import styled from 'styled-components'
import SmartSessionDragPreview from './smartSessions/SmartSessionDragPreview'

const LayerContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

interface DragPreviewItemProps {
  item: DnDItemPayload
}

const DragPreviewItem: FC<DragPreviewItemProps> = (props) => {
  const { item } = props

  if (item.type === DND_ITEM_ENUM.PROJECT) {
    return <ProjectDragPreview item={item} />
  } else if (item.type === DND_ITEM_ENUM.SMART_SESSION) {
    return <SmartSessionDragPreview item={item} />
  }

  return null
}

const CustomDragLayer: FC = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging) {
    return null
  }

  return (
    <Portal>
      <LayerContainer>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          <DragPreviewItem item={item} />
        </div>
      </LayerContainer>
    </Portal>
  )
}

export default CustomDragLayer
