import { createSlice } from '@reduxjs/toolkit'

export interface ProjectsSliceStateType {
  newlyCreatedProjectId?: string
}

const initialState: ProjectsSliceStateType = {
  newlyCreatedProjectId: undefined,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setNewlyCreatedProjectId(state, action: { payload: string | undefined }) {
      state.newlyCreatedProjectId = action.payload
    },
  },
})

export const { setNewlyCreatedProjectId } = projectsSlice.actions

export default projectsSlice.reducer
