import React, { FC, useMemo, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useCreateProjectMutation } from '../../../redux/services/skeema/projects.endpoints'
import { TablistPageType } from '../../../models/tablist_pages.types'
import { useGetOpenTabsQuery, useGetTabGroupsQuery } from '../../../webapp/redux/extension'
import {
  adjustProjectTitleForDuplicates,
  getPlaceholderProjectTitle,
} from '../../../utils/projectUtils'
import { sendMessageToBackground } from '../../../extension/pages/contentScript/messagingUtils'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { APP_NAME } from '../../../constants'

const TAB_GROUP_EMOJI_MAP: {
  [key in chrome.tabGroups.Color]: string
} = {
  grey: '⚫️ ',
  blue: '🔵️',
  red: '🔴',
  yellow: '🟡',
  green: '🟢',
  pink: '🟣',
  purple: '🟤',
  cyan: '⚪',
  orange: '🟠',
}

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}

const OnboardingIntro2Dialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const { projectConfig, isDefault: areFeatureFlagsLoading } = useFeatureFlagContext()
  const maxNumProjects = projectConfig.maxNumProjects
  const maxNumProjectPages = projectConfig.maxNumProjectsPages

  const [isCreationLoading, setIsCreationLoading] = useState(false)

  const { data: openTabPages } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })
  const { data: openTabGroups } = useGetTabGroupsQuery(undefined, { pollingInterval: 1000 })
  const [createProjectMutation] = useCreateProjectMutation()

  const groups = useMemo(() => {
    if (!openTabPages) {
      return openTabPages
    }
    const groups: TablistPageType[][] = []

    openTabPages.reduce(
      (acc, tab) => {
        const groupId = tab.group_id ?? -1
        if (groupId === -1 || openTabGroups?.find((g) => g.id === groupId) === undefined) {
          return acc
        }
        if (!acc[groupId]) {
          acc[groupId] = []
          groups.push(acc[groupId])
        }
        acc[groupId].push(tab)
        return acc
      },
      {} as Record<number, TablistPageType[]>,
    )

    return groups
  }, [openTabGroups, openTabPages])

  const isButtonLoading =
    !openTabGroups || !groups || !openTabPages || isCreationLoading || areFeatureFlagsLoading

  const handleSubmitAndNext = async () => {
    if (isButtonLoading) {
      return
    }

    const numTabGroups = groups?.length ?? 0
    const maxNumTabsInTabGroup = groups.map((g) => g.length).reduce((acc, v) => Math.max(acc, v), 0)

    if (
      numTabGroups !== 0 &&
      numTabGroups <= maxNumProjects - 1 &&
      maxNumTabsInTabGroup <= maxNumProjectPages
    ) {
      await createTabGroupProjects()
    }

    onNext()
  }

  const createTabGroupProjects = async () => {
    if (!groups || !openTabGroups) {
      return
    }

    setIsCreationLoading(true)

    const results: boolean[] = []
    const titles: string[] = []
    for (const pages of groups) {
      const groupId = pages[0].group_id
      if (groupId === undefined) {
        return
      }

      const group = openTabGroups.find((g) => g.id === groupId) ?? {
        color: 'cyan',
        title: undefined,
      }
      const title = group.title
        ? `${TAB_GROUP_EMOJI_MAP[group.color]} ${group.title}`
        : adjustProjectTitleForDuplicates({
            titles,
            baseTitle: getPlaceholderProjectTitle({
              customEmoji: TAB_GROUP_EMOJI_MAP[group.color],
              titlePrefix: 'Folder',
            }),
          })

      titles.push(title)

      const result = await createProjectMutation({ title, tablistPages: pages, order: -1 })
        .unwrap()
        .then((result) => {
          console.log('Created project:', result)
          return true
        })
        .catch((e) => {
          console.error(e)
          return false
        })

      results.push(result)
    }

    if (results.filter((r) => r).length === groups.length) {
      await sendMessageToBackground(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UNGROUP_TAB_GROUPS)
    } else {
      console.log('ERROR: Creating tab groups as projects failed')
    }

    setIsCreationLoading(false)
  }

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={`${APP_NAME} helps you by auto-closing tabs and saving them in a more organized way.`}
      headingTwo={'We call this Automatic Save.'}
      buttonText={`Next`}
      onButtonClick={handleSubmitAndNext}
      isButtonLoading={isButtonLoading}
      delayButtonActivation={false}
    />
  )
}
export default OnboardingIntro2Dialog
