import React, { FC } from 'react'
import { Flex, Box, Grid, GridItem, Spinner, Img, Link } from '@chakra-ui/react'
import { APP_LOGO_WORDMARK_URL, APP_NAME } from '../constants'

const LoadingPage: FC = () => {
  return (
    <Flex direction="row" alignItems="center" h="100%" w="100%">
      <Grid
        height="100%"
        width="100%"
        templateColumns="repeat(12, 1fr)"
        templateRows="68px auto"
        flex="1 1 auto"
      >
        <GridItem colSpan={12} gridRow={1} rowSpan={1} display="flex">
          <Box display={'flex'} m="32px 0 0 24px">
            <Link href="/">
              <Img src={APP_LOGO_WORDMARK_URL} alt={`${APP_NAME} logo`} height={'22px'} />
            </Link>
          </Box>
        </GridItem>

        <GridItem
          className="scrollbars"
          colStart={4}
          gridRow={2}
          colSpan={6}
          height="100%"
          overflow="auto"
          minWidth="552px"
          maxWidth="1000px"
        >
          <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
            <Spinner color="blue.500" size="lg" speed="1s" />
          </div>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default LoadingPage
