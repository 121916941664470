import React, { FC, useCallback, useMemo } from 'react'

import { Box, Flex } from '@chakra-ui/react'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { styled } from 'styled-components'
import { AIActionLogType, AutomaticSaveMetadataTabType } from '../../../models/ai_action_logs.types'
import { getRelativeTimeString } from '../../../utils/utils'
import { useUserContext } from '../../../contexts/UserContext'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import HistoryItemTitle from './HistoryItemTitle'
import { useRevertAIActionMutation } from '../../../redux/services/skeema/feed_history.endpoints'
import DragIcon from '../../../components/DragIcon'

const Container = styled(Box)`
  padding: 10px 4px 6px 4px;
  transition: all 0.2s ease-in-out;

  border: 1px solid #d5d5d5;
  border-radius: 8px;

  &:hover {
    background-color: #fff;
    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    h2 {
      color: #000;
    }

    .hover-show-title-icons {
      opacity: 1;
    }
  }

  .hover-show-title-icons {
    transition: opacity 0.2s;
    opacity: 0;
  }

  .drag-icon {
    width: 16px;
  }
`

export function convertAutomaticSavePagesToTablistPages(pages: AutomaticSaveMetadataTabType[]) {
  return pages.map((page) => ({
    ...page,
    id: page.url,
    entity_type: TABLIST_PAGE_ENUM.RECENTLY_USED,
    last_access_datetime_utc_iso: '',
    last_access_timestamp_ms: 0,
  }))
}

interface Props {
  action: AIActionLogType
}

const HistoryItemCard: FC<Props> = (props) => {
  const { action } = props
  const { captureAnalytics } = useUserContext()
  const relativeTimeString = useMemo(
    () => getRelativeTimeString(action.timestamp),
    [action.timestamp],
  )
  const numTabs = useMemo(() => action.metadata.tabs.length, [action.metadata.tabs])

  const [revertActionMutation] = useRevertAIActionMutation()

  const handleClickUndo = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      const urls = action.metadata.tabs.map((page) => page.url)

      captureAnalytics('feed_history:undo_icon_click', {
        ...action,
      })

      revertActionMutation({ id: action.id })
      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
        urls,
      })
    },
    [action, captureAnalytics, revertActionMutation],
  )

  return (
    <Container>
      <Flex>
        <Box visibility="hidden" className="hover-show-title-icons drag-icon">
          <DragIcon />
        </Box>
        <HistoryItemTitle
          title={`${numTabs} tab${numTabs > 1 ? 's' : ''} automatically saved`}
          timestamp={relativeTimeString}
          handleUndoClick={handleClickUndo}
        />
      </Flex>
      <Box mt="6px">
        {convertAutomaticSavePagesToTablistPages(action.metadata.tabs).map((page, idx) => {
          return (
            <PutasideTabController
              key={idx}
              id={page.url}
              page={page}
              queryValue=""
              index={idx}
              numTotalResults={numTabs}
              areaName={TABLIST_AREA_NAME_ENUM.FeedHistory}
              showTimeString={false}
              showActionIcons={false}
              isTitleClickDisabled={false}
            />
          )
        })}
      </Box>
    </Container>
  )
}

export default HistoryItemCard
