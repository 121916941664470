import React, { FC } from 'react'
import LoadingPage from './LoadingPage'
import { useAuthContext } from '../contexts/AuthContext'
import { useUserContext } from '../contexts/UserContext'

interface Props {
  children: React.ReactNode
  shouldWaitForAuth: boolean
  shouldWaitForUserInfo: boolean
}

const AppLoadingWrapper: FC<Props> = (props) => {
  const { children, shouldWaitForAuth, shouldWaitForUserInfo } = props
  const { isAuthLoading } = useAuthContext()
  const { isUserInfoLoading } = useUserContext()

  const isLoading =
    (shouldWaitForAuth && isAuthLoading) || (shouldWaitForUserInfo && isUserInfoLoading)

  return isLoading ? <LoadingPage /> : children
}

export default AppLoadingWrapper
