import { TablistPageType } from '../models/tablist_pages.types'
import { AppFilterOptionType } from '../redux/searchSlice'

export const getAppFilterOptions = (allPages: TablistPageType[]): AppFilterOptionType[] => {
  const appMap = allPages.reduce((acc: { [title: string]: AppFilterOptionType }, page) => {
    const title = page.app_name
    const favicon_url = page.favicon_url

    if (!title) {
      return acc
    }

    acc[title] ||= {
      title,
      count: 0,
      favIconUrlCounter: {},
      maxFavIconCount: 0,
    }
    acc[title].count += 1

    if (favicon_url) {
      acc[title].favIconUrlCounter[favicon_url] =
        (acc[title].favIconUrlCounter[favicon_url] || 0) + 1

      if (acc[title].favIconUrlCounter[favicon_url] > acc[title].maxFavIconCount) {
        acc[title].maxFavIconCount = acc[title].favIconUrlCounter[favicon_url]
        acc[title].favIconUrl = favicon_url
      }
    }

    return acc
  }, {})

  const sorted = Object.values(appMap).sort((a, b) => b.count - a.count)

  return sorted
}
