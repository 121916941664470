import React, { FC, useCallback } from 'react'
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useUserContext } from '../../../contexts/UserContext'
import MoveTablistItemMenu from './MoveTablistItemMenu'
import { MoveItemPageInfoType } from './moveItemMenu.types'
import MoveProjectItemMenu from './MoveProjectItemMenu'

interface Props {
  pageInfo?: MoveItemPageInfoType
  setPageInfo: React.Dispatch<React.SetStateAction<MoveItemPageInfoType | undefined>>
}

const MoveItemModal: FC<Props> = (props) => {
  const { pageInfo, setPageInfo } = props
  const { captureAnalytics } = useUserContext()

  const onCancel = useCallback(() => {
    captureAnalytics('move_item_menu:dismiss_click', {
      ...(pageInfo ?? {}),
    })
    setPageInfo(undefined)
  }, [captureAnalytics, pageInfo, setPageInfo])

  const onSuccess = useCallback(() => {
    captureAnalytics('move_item_menu:success_auto_dismiss', {
      ...(pageInfo ?? {}),
    })
    setPageInfo(undefined)
  }, [captureAnalytics, pageInfo, setPageInfo])

  return (
    <Modal isCentered isOpen={!!pageInfo} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'16px'}
        minHeight="300px"
        maxHeight="60vh"
        minWidth="276px"
        maxWidth="460px"
        overflow="hidden"
      >
        {pageInfo?.type === 'TABLIST_PAGE' && (
          <MoveTablistItemMenu pageInfo={pageInfo} onSuccess={onSuccess} />
        )}
        {pageInfo?.type === 'PROJECT_PAGE' && (
          <MoveProjectItemMenu pageInfo={pageInfo} onSuccess={onSuccess} />
        )}
      </ModalContent>
    </Modal>
  )
}

export default MoveItemModal
