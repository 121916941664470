import { createSlice } from '@reduxjs/toolkit'

export interface DndSliceStateType {
  isTablistPageDragging: boolean
  isProjectPageDragging: boolean
  isProjectDragging: boolean
  isSmartSessionDragging: boolean
}

const initialState: DndSliceStateType = {
  isTablistPageDragging: false,
  isProjectPageDragging: false,
  isProjectDragging: false,
  isSmartSessionDragging: false,
}

const dndSlice = createSlice({
  name: 'dnd',
  initialState,
  reducers: {
    setIsTablistPageDragging(state, action: { payload: boolean }) {
      state.isTablistPageDragging = action.payload
    },
    setIsProjectPageDragging(state, action: { payload: boolean }) {
      state.isProjectPageDragging = action.payload
    },
    setIsProjectDragging(state, action: { payload: boolean }) {
      state.isProjectDragging = action.payload
    },
    setIsSmartSessionDragging(state, action: { payload: boolean }) {
      state.isSmartSessionDragging = action.payload
    },
  },
})

export const {
  setIsTablistPageDragging,
  setIsProjectPageDragging,
  setIsProjectDragging,
  setIsSmartSessionDragging,
} = dndSlice.actions

export default dndSlice.reducer
