import React, { FC, useMemo } from 'react'
import { STRIPE_PUBLISHABLE_KEY } from '../../../constants'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure' //Using pure version to remove side effects of import (https://github.com/stripe/stripe-js/issues/43)
import { useCreateCheckoutSessionQuery } from '../../../redux/services/skeema/payment.endpoints.ts'
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Heading,
  Spinner,
} from '@chakra-ui/react'
import { modalCloseButtonClass } from './PaymentFlowModal'

interface Props {
  isAnnualPlan: boolean
}

const CheckoutModalContent: FC<Props> = (props) => {
  const { isAnnualPlan } = props
  const { data: checkoutSession } = useCreateCheckoutSessionQuery(
    { is_annual: isAnnualPlan },
    { refetchOnFocus: false },
  )
  const clientSecret = checkoutSession?.client_secret

  //Docs recommend this is loaded outside the component,
  //but we want it here so we don't unnecessarily mount stripe's script
  const stripePromise = useMemo(() => {
    return loadStripe(STRIPE_PUBLISHABLE_KEY)
  }, [])

  return (
    <ModalContent p="32px" borderRadius="16px" width="475px">
      <ModalHeader p="0" mb="16px">
        <Heading fontSize="20px" fontWeight={600} color="#000" lineHeight="28px">
          Upgrade to Pro
        </Heading>
      </ModalHeader>
      <ModalCloseButton className={modalCloseButtonClass} />

      <ModalBody p="0" overflow="hidden">
        <Text fontSize="16px" mb="32px" color="#000" lineHeight="24px">
          For professionals seeking effective and effortless digital organization
        </Text>
        {!clientSecret && (
          <div className="flex-center" style={{ width: '100%' }}>
            <Spinner color="blue.500" size="lg" />
          </div>
        )}
        {clientSecret && (
          <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        )}
      </ModalBody>
    </ModalContent>
  )
}

export default CheckoutModalContent
