import { skeemaApi } from '../skeema'
import {
  PAYMENT_CHECKOUT_SESSION_ENDPOINT_PATH,
  PAYMENT_PORTAL_ENDPOINT_PATH,
  PaymentCheckoutSessionEndpointType,
  PaymentPortalEndpointType,
} from '../../../models/endpoints.types'

export const paymentApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.query<
      PaymentCheckoutSessionEndpointType['POST']['response'],
      PaymentCheckoutSessionEndpointType['POST']['queryParams']
    >({
      query: ({ is_annual }) => ({
        url: `${PAYMENT_CHECKOUT_SESSION_ENDPOINT_PATH}?is_annual=${is_annual}`,
        method: 'POST',
      }),
    }),
    getCheckoutSession: builder.query<
      PaymentCheckoutSessionEndpointType['GET']['response'],
      { sessionId: string }
    >({
      query: ({ sessionId }) => `/${PAYMENT_CHECKOUT_SESSION_ENDPOINT_PATH}${sessionId}`,
    }),
    createCustomerPortal: builder.query<PaymentPortalEndpointType['POST']['response'], undefined>({
      query: () => ({
        url: `${PAYMENT_PORTAL_ENDPOINT_PATH}`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useCreateCheckoutSessionQuery,
  useGetCheckoutSessionQuery,
  useLazyCreateCustomerPortalQuery,
} = paymentApi
