import React, { FC, ReactElement } from 'react'
import { Flex, Text, Button, Box, useBreakpointValue } from '@chakra-ui/react'
import Portal from '../widgets/Portal'

interface Props {
  text: string
  buttonText: string
  buttonOnClick: () => void
  buttonIcon?: ReactElement
}

const StaticBanner: FC<Props> = (props) => {
  const { text, buttonText, buttonIcon, buttonOnClick } = props
  const isLargerScreenSize = useBreakpointValue({ base: false, md: true })

  const bannerContent = (
    <Flex
      bg="#CCE3F9"
      color="#0071E3"
      borderRadius="40px"
      borderColor="#CCE3F9"
      border="1px solid #A3CCF5"
      boxShadow="0px 0px 4px 0px #0000000A, 0px 8px 16px 0px #00000014"
      gap={{ base: '8px', md: '40px' }}
      p="10px 12px 10px 22px"
      alignItems="center"
    >
      <Text
        className={isLargerScreenSize ? 'is-ellipsis-2' : ''}
        fontSize={{ base: 'sm', md: 'md' }}
        fontWeight={500}
      >
        {text}
      </Text>
      <Button
        bg="#0071E3"
        color="white"
        fontSize={{ base: 'sm', md: 'md' }}
        fontWeight={500}
        w={{ base: '75px', md: '124px' }}
        h={{ base: '36px', md: '40px' }}
        borderRadius="100px"
        flexShrink={0}
        _hover={{ bg: '#0071E3' }}
        onClick={buttonOnClick}
        rightIcon={
          isLargerScreenSize && buttonIcon
            ? React.cloneElement(buttonIcon, { style: { flexShrink: 0 } })
            : undefined
        }
      >
        {buttonText}
      </Button>
    </Flex>
  )

  if (isLargerScreenSize) {
    return (
      <Portal>
        <Box position="fixed" top="24px" left="50%" transform="translate(-50%, 0)">
          {bannerContent}
        </Box>
      </Portal>
    )
  } else {
    return (
      <Portal>
        <Box position="fixed" bg="white" bottom="0" left="0" right="0" mx="16px" pb="24px">
          <Box
            position="absolute"
            top="-24px"
            left="0"
            right="0"
            height="24px"
            bgGradient="linear(to-b, rgba(255, 255, 255, 0), #fff)"
            pointerEvents="none"
          />
          {bannerContent}
        </Box>
      </Portal>
    )
  }
}

export default StaticBanner
