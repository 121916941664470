import React, { FC } from 'react'
import GuidedAutoSaveOnboardingModal from '../guidedAutoSave/GuidedAutoSaveOnboardingModal'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingGuidedAutoSaveDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props

  return <GuidedAutoSaveOnboardingModal phaseNum={phaseNum} numPhases={numPhases} onNext={onNext} />
}
export default OnboardingGuidedAutoSaveDialog
