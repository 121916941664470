import React, { useContext, createContext, FC, useMemo } from 'react'

interface FirstLoadContextType {
  path: string
}

const FirstLoadContext = createContext<FirstLoadContextType>({
  path: window.location.pathname,
})

export const useFirstLoadContext = (): FirstLoadContextType => {
  return useContext(FirstLoadContext)
}

export const FirstLoadContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = useMemo(
    () => ({
      path: window.location.pathname,
    }),
    [],
  )

  return <FirstLoadContext.Provider value={value}>{children}</FirstLoadContext.Provider>
}
