export function setWindowStorage(
  key: string,
  value: string | null,
  storageArea: 'localStorage' | 'sessionStorage' = 'localStorage',
  messageCurrentWindow = false,
) {
  if (value === null) {
    window[storageArea].removeItem(key)
  } else {
    window[storageArea].setItem(key, value)
  }

  if (messageCurrentWindow) {
    window.dispatchEvent(new Event('storage')) // https://stackoverflow.com/a/65348883/16400535
  }
}

export function getRelativeTimeString(datetime: string) {
  if (datetime.trim() === '') {
    return 'N/A'
  }

  const now = new Date()
  const past = new Date(datetime)
  const diffMs = now.getTime() - past.getTime()
  const diffSecs = Math.floor(diffMs / 1000)
  const diffMins = Math.floor(diffSecs / 60)
  const diffHrs = Math.floor(diffMins / 60)
  const diffCalendarDays = dateDiffInDays(past, now)

  // Return relative time string
  if (diffMins < 5) {
    return 'Now'
  } else if (diffMins < 45) {
    return `${diffMins}m ago`
  } else if (diffHrs < 18) {
    return diffHrs > 1 ? `${diffHrs}h ago` : '1h ago'
  } else {
    return diffCalendarDays > 1 ? `${diffCalendarDays}d ago` : '1d ago'
  }
}

export function getLocalDateTimeString(datetime: string) {
  if (datetime.trim() === '') {
    return 'Unknown Date'
  }
  return new Date(datetime).toLocaleString()
}

export function dateDiffInDays(dt1: Date, dt2: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())
  const utc2 = Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export function trimUrl(url: string): string {
  const result = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')

  if (result.endsWith('/')) {
    return result.slice(0, result.length - 1)
  } else {
    return result
  }
}

export enum GLOBAL_WINDOW_MESSAGE_ENUM {
  CHANGE_AUTOSAVE_SETTINGS = 'CHANGE_AUTOSAVE_SETTINGS',
  SUBMIT_PIN_TABS = 'SUBMIT_PIN_TABS',
  PIN_TABS_SUCCESS = 'PIN_TABS_SUCCESS',
  SWITCH_TO_SAVED_TABS_VIEW = 'SWITCH_TO_SAVED_TABS_VIEW',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendGlobalWindowMessage = (type: GLOBAL_WINDOW_MESSAGE_ENUM, payload?: any) => {
  window.postMessage({ type, payload }, '*')
}

export const onGlobalWindowMessage = (
  type: GLOBAL_WINDOW_MESSAGE_ENUM,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (payload?: any) => void,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listenerCallback = (event: MessageEvent<any>) => {
    if (event.data.type === type) {
      callback(event.data.payload)
    }
  }

  window.addEventListener('message', listenerCallback)
  return () => window.removeEventListener('message', listenerCallback)
}

export const oneOrAtLeastOne = <T>(arr: T[]): T | [T, ...T[]] => {
  if (arr.length === 0) {
    throw new Error('Array is empty')
  } else if (arr.length === 1) {
    return arr[0]
  } else {
    return [arr[0], ...arr.slice(1)]
  }
}

export const isMac = () => {
  return navigator.userAgent.toLowerCase().indexOf('mac') >= 0
}

export const getS = (num?: number): 's' | '' => {
  return (num ?? 0) === 1 ? '' : 's'
}

export const shouldOpenInNewTab = (event: { metaKey: boolean; ctrlKey: boolean }) => {
  const isMacOS = isMac()
  return (isMacOS && event.metaKey) || (!isMacOS && event.ctrlKey)
}

export async function retryFunction<T>(
  func: () => Promise<T>,
  retries: number = 3,
  delay: number = 1000,
): Promise<{
  success: boolean
  attempt: number
  result?: T
}> {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const result = await func()
      return { success: true, attempt, result }
    } catch (e) {
      if (attempt < retries) {
        console.log(`Operation failed, retrying in ${delay}ms...`, e)
        await new Promise((resolve) => setTimeout(resolve, delay))
      } else {
        console.log('Operation failed after maximum retries', e)
        return { success: false, attempt }
      }
    }
  }

  return { success: false, attempt: retries }
}
