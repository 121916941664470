import React, { FC } from 'react'
import { MdOutlineDragIndicator } from 'react-icons/md'

const DragIcon: FC = () => {
  return (
    <MdOutlineDragIndicator
      style={{ flexShrink: 0, width: '16px', height: '16px', color: '#A7A7A7' }}
    />
  )
}

export default DragIcon
