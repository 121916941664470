import React, { FC, useEffect, useMemo } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { useReduxDispatch } from '../../../redux/baseStore'
import {
  projectsApi,
  useGetActiveProjectsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { ListItem, Spinner, UnorderedList, Text } from '@chakra-ui/react'

const FOLDER_EXPLAINATION = {
  '🧠 Skipper Resources': 'handy links to get to know Skipper',
  '📚 To Read': 'readings you may want to get back to later',
  '🔖 Favorites': 'quick access to the sites you visit most often',
}

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
  wouldCreateProjectsFromTabgroups: boolean
}

const OnboardingTabGroupsDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases, wouldCreateProjectsFromTabgroups } = props
  const dispatch = useReduxDispatch()

  const { data: activeProjects, isLoading } = useGetActiveProjectsQuery(undefined)

  useEffect(() => {
    dispatch(projectsApi.util.invalidateTags(['ActiveProjects']))
  }, [dispatch])

  const textWithSavedTabgroups = (
    <>
      Folders keep related tabs together and close by. All of your tab groups have been saved as
      folders.
    </>
  )

  const textWithoutSavedTabgroups = <>Folders keep related tabs together and close by.</>

  const description = useMemo(() => {
    if (isLoading) {
      return <Spinner />
    }
    return (
      <>
        <Text>
          Checkout the first folder{activeProjects?.length === 1 ? '' : 's'} we set up for you,
          create your own later!
        </Text>
        <UnorderedList mt="2" styleType="' '">
          {activeProjects
            ?.filter((p) => Object.keys(FOLDER_EXPLAINATION).includes(p.title))
            .map((p) => (
              <ListItem key={p.id}>
                <b>{p.title}</b> -{' '}
                {FOLDER_EXPLAINATION[p.title as keyof typeof FOLDER_EXPLAINATION]}
              </ListItem>
            ))}
        </UnorderedList>
      </>
    )
  }, [isLoading, activeProjects])

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={
        wouldCreateProjectsFromTabgroups ? textWithSavedTabgroups : textWithoutSavedTabgroups
      }
      description={description}
      descriptionTwo={`Share your folders of useful links with others, even if they don’t have a Skipper account
          yet!`}
      buttonText={`Next`}
      onButtonClick={onNext}
    />
  )
}
export default OnboardingTabGroupsDialog
