import { skeemaApi } from '../skeema'
import { ACTION_QUOTA_ENDPOINT_PATH } from '../../../models/endpoints.types'
import { ActionQuotaType } from '../../../models/user.types'

export const tablistPagesApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    getActionQuota: builder.query<ActionQuotaType, undefined>({
      query: () => `${ACTION_QUOTA_ENDPOINT_PATH}`,
    }),
  }),
})

export const { useGetActionQuotaQuery } = tablistPagesApi
