import React, { FC } from 'react'
import SVG from './SVG'

const PlansSVG: FC<React.SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <SVG {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" strokeWidth="0px">
      <g id="my_plan">
        <mask id="mask0_8424_79496" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_8424_79496)">
          <path
            id="toll"
            d="M10.0013 13.3346C8.51241 13.3346 7.2513 12.818 6.21797 11.7846C5.18464 10.7513 4.66797 9.49019 4.66797 8.0013C4.66797 6.51241 5.18464 5.2513 6.21797 4.21797C7.2513 3.18464 8.51241 2.66797 10.0013 2.66797C11.4902 2.66797 12.7513 3.18464 13.7846 4.21797C14.818 5.2513 15.3346 6.51241 15.3346 8.0013C15.3346 9.49019 14.818 10.7513 13.7846 11.7846C12.7513 12.818 11.4902 13.3346 10.0013 13.3346ZM4.66797 13.168C3.49019 12.8569 2.52908 12.2235 1.78464 11.268C1.04019 10.3124 0.667969 9.22352 0.667969 8.0013C0.667969 6.77908 1.04019 5.69019 1.78464 4.73464C2.52908 3.77908 3.49019 3.14575 4.66797 2.83464V4.23464C3.86797 4.51241 3.22352 4.99575 2.73464 5.68464C2.24575 6.37352 2.0013 7.14575 2.0013 8.0013C2.0013 8.85686 2.24575 9.62908 2.73464 10.318C3.22352 11.0069 3.86797 11.4902 4.66797 11.768V13.168ZM10.0013 12.0013C11.1124 12.0013 12.0569 11.6124 12.8346 10.8346C13.6124 10.0569 14.0013 9.11241 14.0013 8.0013C14.0013 6.89019 13.6124 5.94575 12.8346 5.16797C12.0569 4.39019 11.1124 4.0013 10.0013 4.0013C8.89019 4.0013 7.94575 4.39019 7.16797 5.16797C6.39019 5.94575 6.0013 6.89019 6.0013 8.0013C6.0013 9.11241 6.39019 10.0569 7.16797 10.8346C7.94575 11.6124 8.89019 12.0013 10.0013 12.0013Z"
            fill="#585858"
          />
        </g>
      </g>
    </SVG>
  )
}

export default PlansSVG
