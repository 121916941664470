import React, { FC, useEffect, useState } from 'react'
import { Card, Heading, Text, Button, HStack, Box, Tooltip, Spinner } from '@chakra-ui/react'
import { PiCaretRightBold } from 'react-icons/pi'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  &&& h2 {
    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      font-size: 24px;
      line-height: 36px;
    }
  }
`

const StyledButton = styled(Button)`
  &&& {
    font-size: 16px;
    font-weight: 500;
    background-color: black;
    color: white;
    border-radius: 100px;

    &:hover {
      background-color: #585858;
    }
    &:disabled {
      background-color: #d5d5d5;
    }

    @media (max-width: 1200px) {
      /* Screens narrower than 1200px */
      height: 36px;
      padding: 0px 20px;
    }

    @media (min-width: 1200px) {
      /* Screens wider than 1200px */
      height: 48px;
      padding: 0px 32px;
    }
  }
`

interface Props {
  phaseNum?: number
  numPhases?: number
  heading?: string | React.JSX.Element
  headingTwo?: string
  description?: string | React.JSX.Element
  descriptionTwo?: string | React.JSX.Element
  bodyContent?: React.ReactNode
  descriptionAfterBody?: string | React.JSX.Element
  height?: string
  maxWidth?: string
  delayButtonActivation?: boolean
  buttonText?: string
  onButtonClick?: () => void
  isButtonLoading?: boolean
  isCardLoading?: boolean
}
const OnboardingStepTemplate: FC<Props> = (props) => {
  const {
    heading,
    headingTwo,
    phaseNum,
    numPhases,
    description,
    descriptionTwo,
    bodyContent,
    descriptionAfterBody,
    height,
    maxWidth,
    buttonText,
    delayButtonActivation = true,
    isButtonLoading = false,
    isCardLoading = false,
    onButtonClick,
  } = props
  const [isButtonDisabled, setIsButtonDisabled] = useState(delayButtonActivation)

  useEffect(() => {
    if (delayButtonActivation) {
      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 3000)
    }
  }, [delayButtonActivation])

  return (
    <StyledCard
      height={height ?? (isCardLoading ? '300px' : undefined)}
      maxHeight={'85vh'}
      width={`calc(((100vw - 240px) / 12) * 8)`}
      maxWidth={maxWidth ?? '680px'}
      minWidth={'480px'}
      padding="32px"
      borderRadius="16px"
      variant="outline"
      border="1px solid  #D5D5D5"
      boxShadow="0px 8px 24px 8px rgba(0, 0, 0, 0.10)"
      overflow={'auto'}
    >
      {isCardLoading && (
        <div className="flex-center" style={{ width: '100%', height: '100%', padding: '32px' }}>
          <Spinner color="blue.500" size="lg" speed="1s" />
        </div>
      )}
      {!isCardLoading && (
        <>
          {numPhases !== undefined && phaseNum !== undefined && (
            <HStack marginBottom={'24px'}>
              <Box
                width="200px"
                height="12px"
                borderRadius={'24px'}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={phaseNum === 1 ? '#CCE3F9' : '#0071E3'}
                color="white"
                whiteSpace="nowrap"
                flexShrink={0}
              >
                <Box
                  h="100%"
                  w={`${((phaseNum === 1 ? 0 : phaseNum) / numPhases) * 100}%`}
                  borderRadius={phaseNum === numPhases ? '24px' : '24px 0 0 24px'}
                  bg="#0071E3"
                />
              </Box>
            </HStack>
          )}
          {heading && (
            <Heading
              mb="4"
              fontSize={'28px'}
              fontWeight={500}
              lineHeight={'40px'}
              color="#000"
              letterSpacing={'-1px'}
            >
              {heading}
            </Heading>
          )}

          {headingTwo && (
            <>
              <Heading
                size="md"
                mb="4"
                fontSize={'28px'}
                fontWeight={500}
                lineHeight={'40px'}
                color="#000"
                letterSpacing={'-1px'}
              >
                {headingTwo}
              </Heading>
            </>
          )}
          {description && (
            <Text mb="8" fontSize="16px" lineHeight={'24px'} color={'#585858'}>
              {description}
            </Text>
          )}
          {descriptionTwo && (
            <Text fontSize="16px" lineHeight={'24px'} color={'#585858'}>
              {descriptionTwo}
            </Text>
          )}
          {bodyContent ?? <></>}

          {descriptionAfterBody && (
            <Text mt="24px" fontSize="16px" lineHeight={'24px'} color={'#585858'}>
              {descriptionAfterBody}
            </Text>
          )}
          {buttonText && onButtonClick && (
            <Box marginTop="24px" display="flex" justifyContent="flex-end" alignItems="center">
              <Tooltip
                isDisabled={!isButtonDisabled}
                label={`Please read the dialog before continuing`}
                placement="top"
              >
                <StyledButton
                  onClick={onButtonClick}
                  isDisabled={isButtonDisabled}
                  isLoading={isButtonLoading}
                  rightIcon={
                    <PiCaretRightBold
                      style={{
                        width: '12px',
                        height: '12px',
                        strokeWidth: '14px',
                        marginBottom: '1px',
                      }}
                    />
                  }
                >
                  {buttonText}
                </StyledButton>
              </Tooltip>
            </Box>
          )}
        </>
      )}
    </StyledCard>
  )
}

export default OnboardingStepTemplate
