import React, { useContext, FC, useState, useEffect, useMemo } from 'react'
import { WEBAPP_LOCAL_STORAGE_KEYS } from '../models/browserStorage.types'
import {
  DEFAULT_FEATURE_FLAG_VALUES,
  FEATURE_FLAG_KEYS_ENUM,
  FeatureFlagValuesType,
} from '../models/feature_flags.types'
import { useUserContext } from './UserContext'
import { sendMessageToExtension } from '../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../extension/models/messaging.types'

abstract class FeatureFlagContextBackend {
  abstract setFeatureFlagValues(featureFlags: FeatureFlagValuesType): void
  abstract getFeatureFlagValues(): FeatureFlagValuesType | undefined
  abstract clearFeatureFlagValues(): void
  abstract syncWithExtension(featureFlags: FeatureFlagValuesType): void
}

export class LocalStorageFeatureFlagContextBackend extends FeatureFlagContextBackend {
  setFeatureFlagValues(featureFlags: FeatureFlagValuesType): void {
    window.localStorage.setItem(
      WEBAPP_LOCAL_STORAGE_KEYS.FeatureFlagValues,
      JSON.stringify(featureFlags),
    )
  }
  getFeatureFlagValues(): FeatureFlagValuesType | undefined {
    const cached = window.localStorage.getItem(WEBAPP_LOCAL_STORAGE_KEYS.FeatureFlagValues)

    if (!cached) {
      return undefined
    }

    const featureFlagValues: FeatureFlagValuesType = JSON.parse(cached)
    return featureFlagValues
  }

  clearFeatureFlagValues(): void {
    window.localStorage.removeItem(WEBAPP_LOCAL_STORAGE_KEYS.FeatureFlagValues)
  }

  syncWithExtension(featureFlags: FeatureFlagValuesType): void {
    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UPDATE_FEATURE_FLAGS, {
      featureFlags,
    }).catch((e) => {
      console.error('Error syncing feature flags with extension', e)
    })
  }
}

type ProjectsConfig = {
  maxNumProjects: number
  maxNumProjectsPages: number
}

const DEFAULT_PROJECT_CONFIG: ProjectsConfig = {
  maxNumProjects: 10,
  maxNumProjectsPages: 10,
}

type FeatureFlagContextType = {
  featureFlags: FeatureFlagValuesType
  projectConfig: ProjectsConfig
  isDefault: boolean
}

export const FeatureFlagContext = React.createContext<FeatureFlagContextType>({
  featureFlags: DEFAULT_FEATURE_FLAG_VALUES,
  projectConfig: DEFAULT_PROJECT_CONFIG,
  isDefault: true,
})

export const useFeatureFlagContext = (): FeatureFlagContextType => {
  return useContext(FeatureFlagContext)
}

export const FeatureFlagContextProvider: FC<{
  backend: FeatureFlagContextBackend
  children: React.ReactNode
}> = ({ backend, children }): React.JSX.Element => {
  const { userInfo, isUserInfoLoading, isExtensionRequired } = useUserContext()
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagValuesType | undefined>(
    backend.getFeatureFlagValues(),
  )

  const projectConfig = useMemo(() => {
    if (
      userInfo?.is_premium ||
      (featureFlags !== undefined &&
        featureFlags[FEATURE_FLAG_KEYS_ENUM.ShouldHaveExpandedProjects])
    ) {
      return {
        maxNumProjects: 100,
        maxNumProjectsPages: 100,
      }
    }
    return DEFAULT_PROJECT_CONFIG
  }, [featureFlags, userInfo?.is_premium])

  useEffect(() => {
    // User not initialized yet
    if (isUserInfoLoading) {
      return
    }

    // User is logged out, clear feature flags
    if (!userInfo) {
      backend.clearFeatureFlagValues()
      setFeatureFlags(undefined)
      return
    }

    //User logged in, load feature flags
    const userInfoFlags = userInfo.feature_flags

    if (isExtensionRequired) {
      backend.syncWithExtension(userInfoFlags)
    }

    // Cache feature flags for next load
    backend.setFeatureFlagValues(userInfoFlags)

    // Set feature flags if not already set
    // (don't want to change the feature flags after page has loaded unless they're missing)
    setFeatureFlags((prev) => (!prev ? userInfoFlags : prev))
  }, [backend, isExtensionRequired, isUserInfoLoading, userInfo])

  const value = useMemo(() => {
    return {
      featureFlags: featureFlags ?? DEFAULT_FEATURE_FLAG_VALUES,
      isDefault: featureFlags === undefined,
      projectConfig,
    }
  }, [featureFlags, projectConfig])

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>
}
