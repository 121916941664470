import React, { FC, useCallback, useMemo, useState } from 'react'
import ProjectDetailStaticTitle from './ProjectDetailStaticTitle'
import {
  useDeleteArchivedProjectMutation,
  useArchiveProjectMutation,
  useRenameProjectMutation,
  useUnarchiveProjectMutation,
  useSetProjectPermissionsMutation,
  useGetProjectPermissionsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import {
  PROJECT_PERMISSION_ENUM,
  ProjectPermissionsType,
  ProjectType,
} from '../../../models/saved_sessions.types'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../contexts/UserContext'
import ProjectDeletionModal from '../projectArchive/ProjectDeletionModal'
import ProjectDetailEditableTitle from './ProjectDetailEditableTitle'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import ProjectShareModal from './ProjectShareModal'
import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react'
import NotificationToast from '../../../components/NotificationToast'
import ProjectDetailSharedTitle from './ProjectDetailSharedTitle'

const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  project: ProjectType
  urls?: string[]
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  isViewOnly?: boolean
  isExtensionInstalled: boolean
}

const ProjectDetailTitle: FC<Props> = (props) => {
  const { project, urls, setIsMenuOpen, isExtensionInstalled, isViewOnly = false } = props
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()
  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = React.useRef<ToastId | undefined>(undefined)

  const [isProjectDeletionModalOpen, setIsProjectDeletionModalOpen] = useState<boolean>(false)
  const [isProjectShareModalOpen, setIsProjectShareModalOpen] = useState<boolean>(false)
  const [isRenameActive, setIsRenameActive] = useState<boolean>(false)

  const { data: projectPermissions, isLoading: isProjectPermissionsLoading } =
    useGetProjectPermissionsQuery({
      projectId: project.id,
    })
  const [setProjectPermissions] = useSetProjectPermissionsMutation()
  const isShared = useMemo(() => {
    return (
      projectPermissions?.some(
        (permission) => permission.permission !== PROJECT_PERMISSION_ENUM.NOT_SHARED,
      ) || false
    )
  }, [projectPermissions])

  const [deleteArchivedProjectMutation] = useDeleteArchivedProjectMutation()
  const [archiveProjectMutation] = useArchiveProjectMutation()
  const [renameProjectMutation] = useRenameProjectMutation()
  const [unarchiveProjectMutation] = useUnarchiveProjectMutation()

  const handleArchiveProject = useCallback(async () => {
    captureAnalytics('project_detail_dashboard:project_archive', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    await archiveProjectMutation({ project })
  }, [project, captureAnalytics, urls, archiveProjectMutation])

  const handleUnarchiveProject = useCallback(async () => {
    captureAnalytics('project_detail_dashboard:project_unarchive', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    await unarchiveProjectMutation({ project })
  }, [project, captureAnalytics, urls, unarchiveProjectMutation])

  const handleClickRename = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()

      captureAnalytics('project_detail_dashboard:project_title_rename_click', {
        numTabs: urls?.length,
        urls,
        ...project,
      })

      setIsRenameActive(true)
    },
    [captureAnalytics, project, urls],
  )

  const handleCancelRename = useCallback(
    (newTitle: string) => {
      captureAnalytics('project_detail_dashboard:project_title_rename_cancel', {
        numTabs: urls?.length,
        urls,
        newTitle,
        oldTitle: project.title,
        ...project,
      })

      setIsRenameActive(false)
    },
    [captureAnalytics, project, urls],
  )

  const handleSubmitRename = useCallback(
    async (newTitle: string) => {
      if (newTitle === '' || project.title === newTitle) {
        handleCancelRename(newTitle)
        return
      }

      captureAnalytics('project_detail_dashboard:project_title_rename_submit', {
        numTabs: urls?.length,
        urls,
        newTitle,
        oldTitle: project.title,
        ...project,
      })

      await renameProjectMutation({ id: project.id, title: newTitle })
      setIsRenameActive(false)
    },
    [project, captureAnalytics, urls, renameProjectMutation, handleCancelRename],
  )

  const handleOpenProjectUrls = useCallback(
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()
      if (!isExtensionInstalled) {
        return
      }

      captureAnalytics('project_detail_dashboard:project_urls_open', {
        urls,
        numTabs: urls?.length,
        ...project,
      })

      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
        urls,
      })
    },
    [captureAnalytics, isExtensionInstalled, project, urls],
  )

  const handleClickShareIcon = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_share_icon_click', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setIsProjectShareModalOpen(true)
  }, [captureAnalytics, project, urls])

  const handleChangeProjectPermission = useCallback(
    (permission: ProjectPermissionsType) => {
      setProjectPermissions({ projectId: project.id, permission })
        .then(() => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current)
          }
          toastIdRef.current = toast({
            duration: 3000,
            containerStyle: { minWidth: 'auto' },
            render: () => (
              <NotificationToast
                message={
                  permission.permission === PROJECT_PERMISSION_ENUM.VIEW
                    ? 'Folder is now shareable'
                    : 'Folder is now private'
                }
              />
            ),
          })
        })
        .catch(() => {
          toast({
            duration: 3000,
            containerStyle: { minWidth: 'auto' },
            render: () => (
              <NotificationToast message="Failed to update project permissions.  Contact support if this persists." />
            ),
          })
        })
    },
    [project, setProjectPermissions, toast],
  )

  const handleCopyProjectLink = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_share_copy_link_click', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    const projectLink = `${window.location.origin}/folders/${project.id}`
    navigator.clipboard.writeText(projectLink)
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
    toastIdRef.current = toast({
      duration: 3000,
      containerStyle: { minWidth: 'auto' },
      render: () => <NotificationToast message="Folder link is copied to clipboard" />,
    })
    setIsProjectShareModalOpen(false)
  }, [captureAnalytics, project, toast, urls])

  const onMenuOpen = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_title_menu_open', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setIsMenuOpen && setIsMenuOpen(true)
  }, [captureAnalytics, project, setIsMenuOpen, urls])

  const onMenuClose = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_title_menu_close', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setIsMenuOpen && setIsMenuOpen(false)
  }, [captureAnalytics, project, setIsMenuOpen, urls])

  const handleDeleteProject = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_delete', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    deleteArchivedProjectMutation({ id: project?.id })

    setIsProjectDeletionModalOpen(false)
    navigate('/')
  }, [captureAnalytics, deleteArchivedProjectMutation, navigate, project, urls])

  const handleOpenProjectDeletionModal = useCallback(() => {
    if (!project) {
      return
    }

    captureAnalytics('project_detail_dashboard:project_deletion_modal_open', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setIsProjectDeletionModalOpen(true)
  }, [captureAnalytics, project, urls])

  const handleCancelProjectDeletion = useCallback(() => {
    captureAnalytics('project_detail_dashboard:project_delete_dialog_cancel', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setIsProjectDeletionModalOpen(false)
  }, [captureAnalytics, project, urls])

  if (isViewOnly) {
    return (
      <ProjectDetailSharedTitle
        title={project.title}
        isArchived={project.is_archived}
        isViewOnly={isViewOnly}
        handleClickOpenIcon={isExtensionInstalled ? handleOpenProjectUrls : undefined}
      />
    )
  }

  return (
    <>
      {isRenameActive ? (
        <ProjectDetailEditableTitle
          title={project.title}
          handleSubmitRename={handleSubmitRename}
          handleCancelRename={handleCancelRename}
        />
      ) : (
        <ProjectDetailStaticTitle
          title={project.title}
          isArchived={project.is_archived}
          isShared={isShared}
          isOpenDisabled={project.pages.length === 0}
          handleClickArchiveIcon={handleArchiveProject}
          handleClickUnarchiveIcon={handleUnarchiveProject}
          handleClickDeleteIcon={handleOpenProjectDeletionModal}
          handleClickOpenIcon={handleOpenProjectUrls}
          handleClickRenameIcon={handleClickRename}
          handleClickShareIcon={handleClickShareIcon}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
      )}

      <ProjectShareModal
        title={project.title}
        isShared={isShared}
        isLoading={isProjectPermissionsLoading}
        isOpen={isProjectShareModalOpen}
        onCancel={() => setIsProjectShareModalOpen(false)}
        onCopyLink={handleCopyProjectLink}
        handleChangeProjectPermission={handleChangeProjectPermission}
      />

      <ProjectDeletionModal
        isOpen={isProjectDeletionModalOpen}
        onCancel={handleCancelProjectDeletion}
        onDelete={handleDeleteProject}
      />
    </>
  )
}

export default ProjectDetailTitle
