import { extendTheme } from '@chakra-ui/react'
import { tooltipTheme } from './chakraComponentThemes/Tooltip'

const chakraTheme = extendTheme({
  colors: {
    blue: '#0071E3',
    'blue.500': '#0071E3',
    black: {
      500: '#000',
    },
  },
  components: {
    Tooltip: tooltipTheme,
  },
  fonts: {
    heading: `Inter, Roboto, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    body: `Inter, Roboto, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  },
})

export default chakraTheme
