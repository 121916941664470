import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

type AuthContextType = {
  authHeader: string | undefined
  isAuthLoading: boolean
  isAuthenticated: boolean
  signIn: (token: string) => Promise<boolean>
  signOut: () => Promise<void>
}

export const AuthContext = createContext<AuthContextType>({
  authHeader: undefined,
  isAuthLoading: true,
  isAuthenticated: false,
  signIn: () => Promise.resolve(false),
  signOut: () => Promise.resolve(),
})

export const useAuthContext = (): AuthContextType => {
  return useContext(AuthContext)
}

abstract class AuthContextBackend {
  abstract setAuthHeader(header: string): Promise<void>
  abstract clearAuthHeader(): Promise<void>
  abstract getAuthHeader(): Promise<string | undefined>
}

export class LocalStorageAuthContextBackend extends AuthContextBackend {
  constructor(private readonly key: string) {
    super()
  }

  setAuthHeader(header: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(this.key, header)
      resolve()
    })
  }

  clearAuthHeader(): Promise<void> {
    return new Promise((resolve) => {
      localStorage.removeItem(this.key)
      resolve()
    })
  }

  getAuthHeader(): Promise<string | undefined> {
    return new Promise((resolve) => {
      const item = localStorage.getItem(this.key)
      if (item) {
        resolve(item)
        return
      }

      // Try if there are keys from react-auth-kit, which is saved with a key "undefined"
      const reactAuthKitItem = localStorage.getItem('undefined')
      if (reactAuthKitItem) {
        const authHeader = `Bearer ${reactAuthKitItem}`
        this.setAuthHeader(authHeader)
        resolve(authHeader)
      }

      resolve(undefined)
    })
  }
}

export const AuthContextProvider: FC<{
  backend: AuthContextBackend
  children: React.ReactNode
}> = ({ backend, children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true)
  const [authHeader, setAuthHeader] = useState<string | undefined>(undefined)

  useEffect(() => {
    void backend
      .getAuthHeader()
      .then((header) => {
        setAuthHeader(header)
      })
      .catch((e) => {
        console.error('Error initializing auth header', e)
      })
      .finally(() => {
        setIsAuthLoading(false)
      })
  }, [backend])

  const signIn = useCallback(
    async (token: string): Promise<boolean> => {
      const authHeader = `Bearer ${token}`
      setIsAuthLoading(true)
      await backend.setAuthHeader(authHeader)
      setIsAuthLoading(false)
      setAuthHeader(authHeader)
      return true
    },
    [backend],
  )

  const signOut = useCallback(async () => {
    setAuthHeader(undefined)
    await backend.clearAuthHeader()
  }, [backend])

  const value = useMemo(() => {
    return {
      authHeader,
      isAuthLoading,
      isAuthenticated: !!authHeader,
      signIn,
      signOut,
    }
  }, [authHeader, isAuthLoading, signIn, signOut])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
