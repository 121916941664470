import React, { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDisclosure, Modal, ModalOverlay } from '@chakra-ui/react'
import PricingPlansModalContent from './PricingPlansModalContent'
import CheckoutModalContent from './CheckoutModalContent'
import { useUserContext } from '../../../contexts/UserContext'
import CheckoutCompleteModalContent from './CheckoutCompleteModalContent'
import CheckoutErrorModalContent from './CheckoutErrorModalContent'
import { css } from '@emotion/css'
import { useLazyCreateCustomerPortalQuery } from '../../../redux/services/skeema/payment.endpoints.ts'
import { useAuthContext } from '../../../contexts/AuthContext'

export const modalCloseButtonClass = css`
  &&& {
    overflow: visible;
    top: 28px;
    right: 28px;

    svg {
      width: 14px;
      height: 14px;
      color: #000;
    }
  }
`

enum PAYMENT_FLOW_STATE_ENUM {
  PLANS = 'PLANS',
  CHECKOUT = 'CHECKOUT',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

const PaymentFlowModal: FC = () => {
  const navigate = useNavigate()
  const { hash } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id') ?? undefined

  const { authHeader } = useAuthContext()
  const { userInfo, captureAnalytics } = useUserContext()
  const isPremium = !!userInfo?.is_premium
  const isUserOnAnnualPlan = !!userInfo?.is_annual
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [paymentFlowState, setPaymentFlowState] = useState<PAYMENT_FLOW_STATE_ENUM>(
    PAYMENT_FLOW_STATE_ENUM.PLANS,
  )
  const [isAnnualPlanToggled, setIsAnnualPlanToggled] = useState<boolean>(true)

  const [triggerCreateCustomerPortalQuery, customerPortalQueryResult] =
    useLazyCreateCustomerPortalQuery({ refetchOnFocus: false })
  const customerPortalData = customerPortalQueryResult.data
  const isPlansButtonLoading = customerPortalQueryResult.isLoading || !!customerPortalData

  useEffect(() => {
    if (customerPortalData) {
      window.location.href = customerPortalData.url
    }
  }, [customerPortalData])

  useEffect(() => {
    if (!userInfo) {
      return
    }

    if (hash === '#pricing') {
      onOpen()
      captureAnalytics('payment_flow:pricing_modal_display')
    }
  }, [captureAnalytics, hash, navigate, onOpen, userInfo])

  useEffect(() => {
    if (sessionId) {
      const state =
        paymentFlowState !== PAYMENT_FLOW_STATE_ENUM.ERROR
          ? PAYMENT_FLOW_STATE_ENUM.COMPLETE
          : paymentFlowState
      setPaymentFlowState(state)
      if (state === PAYMENT_FLOW_STATE_ENUM.COMPLETE) {
        captureAnalytics('payment_flow:complete_modal_display')
      }
    }
  }, [captureAnalytics, paymentFlowState, sessionId])

  const onClickUpgrade = () => {
    setPaymentFlowState(PAYMENT_FLOW_STATE_ENUM.CHECKOUT)
    captureAnalytics('payment_flow:pricing_modal_upgrade_click')
  }

  const onClickManagePlan = () => {
    triggerCreateCustomerPortalQuery(undefined, true)
    captureAnalytics('payment_flow:pricing_modal_manage_plan_click')
  }

  const onCloseModal = (shouldLogClose = true) => {
    onClose()
    shouldLogClose && captureAnalytics('payment_flow:pricing_modal_close', { paymentFlowState })
    setPaymentFlowState(PAYMENT_FLOW_STATE_ENUM.PLANS)
    navigate('.')
  }

  const handleCheckoutCompleteClickDone = () => {
    onCloseModal(false)
    captureAnalytics('payment_flow:complete_modal_done_click')
  }

  const handleCheckoutStatusError = (data: unknown) => {
    setPaymentFlowState(PAYMENT_FLOW_STATE_ENUM.ERROR)
    captureAnalytics('payment_flow:checkout_error_display', { data })
  }

  const handleCheckoutErrorRetry = () => {
    setSearchParams({})
    setPaymentFlowState(PAYMENT_FLOW_STATE_ENUM.CHECKOUT)
    captureAnalytics('payment_flow:checkout_error_retry_click')
  }

  if (!authHeader) {
    return null
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size="2xl" closeOnOverlayClick={false}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.2)" sx={{ backdropFilter: 'blur(5px)' }} />
      {paymentFlowState === PAYMENT_FLOW_STATE_ENUM.PLANS && (
        <PricingPlansModalContent
          onClickUpgrade={onClickUpgrade}
          onClickManagePlan={onClickManagePlan}
          isActionButtonLoading={isPlansButtonLoading}
          isPremium={isPremium}
          isUserOnAnnualPlan={isUserOnAnnualPlan}
          isAnnualPlanToggled={isAnnualPlanToggled}
          setIsAnnualPlanToggled={setIsAnnualPlanToggled}
        />
      )}
      {paymentFlowState === PAYMENT_FLOW_STATE_ENUM.CHECKOUT && (
        <CheckoutModalContent isAnnualPlan={isAnnualPlanToggled} />
      )}
      {paymentFlowState === PAYMENT_FLOW_STATE_ENUM.COMPLETE && sessionId && (
        <CheckoutCompleteModalContent
          onClickDone={handleCheckoutCompleteClickDone}
          onCheckoutStatusError={handleCheckoutStatusError}
          sessionId={sessionId}
        />
      )}
      {paymentFlowState === PAYMENT_FLOW_STATE_ENUM.ERROR && (
        <CheckoutErrorModalContent onClickRetry={handleCheckoutErrorRetry} />
      )}
    </Modal>
  )
}

export default PaymentFlowModal
