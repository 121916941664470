import React, { FC } from 'react'
import GenericModal from '../../../widgets/GenericModal'
import { APP_NAME } from '../../../constants'
interface Props {
  isOpen: boolean
  onCancel: () => void
  onSubmit: () => void
}

const SavedPagesLimitModal: FC<Props> = (props) => {
  const { isOpen, onCancel, onSubmit } = props

  return (
    <GenericModal
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      headingText={`Get more organized with ${APP_NAME} Pro`}
      bodyText={`For saved tabs going back even farther than 30 days, upgrade to ${APP_NAME} Pro!`}
      cancelButtonText="Dismiss"
      submitButtonText="Upgrade"
    />
  )
}

export default SavedPagesLimitModal
