import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Text, Flex, Box, Spinner, Img, Link, Grid, GridItem } from '@chakra-ui/react'
import { useGetProjectPagesQuery } from '../../redux/services/skeema/projects.endpoints'
import ProjectDetailSharedPageController from './projectDetail/ProjectDetailSharedPageController'
import ProjectDetailTitle from './projectDetail/ProjectDetailTitle'
import { ProjectType } from '../../models/saved_sessions.types'
import { useExtensionHealthContext } from '../../contexts/ExtensionHealthContext'
import { sendMessageToExtension } from '../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../extension/models/messaging.types'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../contexts/UserContext'
import StaticBanner from '../../components/StaticBanner'
import { useAppendTablistPagesMutation } from '../../redux/services/skeema/tablist_pages.endpoints'
import { TABLIST_PAGE_ENUM, TablistPageType } from '../../models/tablist_pages.types'
import { WEBAPP_BASE_URL, APP_NAME, APP_LOGO_WORDMARK_URL, LANDING_PAGE_URL } from '../../constants'
import { isMobileOrTabletDevice } from '../../utils/deviceUtils'

interface Props {
  project: ProjectType
}

const SharedProjectPage: FC<Props> = (props) => {
  const { project } = props
  const navigate = useNavigate()
  const { captureAnalytics, userInfo } = useUserContext()
  const isExtensionInstalled = !!useExtensionHealthContext().extensionHealth
  const { data: pages, isLoading: isPagesLoading } = useGetProjectPagesQuery({
    projectId: String(project.id),
  })
  const urls = useMemo(() => pages?.map((page) => page.url), [pages])

  const [appendTablistPages] = useAppendTablistPagesMutation()

  useEffect(() => {
    captureAnalytics('shared_project_page:view', {
      ...project,
    })
  }, [captureAnalytics, project])

  useEffect(() => {
    if (isExtensionInstalled) {
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.REPLACE_PINNED_TAB)
    }
  }, [isExtensionInstalled])

  useEffect(() => {
    window.document.title = `${project.title.trim() || 'Untitled Folder'} - ${APP_NAME}`
  }, [project.title])

  const onClickSkipperLogo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    captureAnalytics('shared_project_page:skipper_logo_click', {
      url: window.location.href,
      isMobileOrTabletDevice,
    })
    if (isMobileOrTabletDevice) {
      window.location.href = LANDING_PAGE_URL
    } else {
      navigate('/')
    }
  }

  const onBannerSignUpClick = useCallback(() => {
    window.open(
      `https://www.skeema.com/focus?utm_source=skeema_app&utm_medium=shared_project&utm_campaign=shared_project`,
      '_blank',
    )
    captureAnalytics('shared_project_page:banner_sign_up_click', {
      ...project,
    })
  }, [captureAnalytics, project])

  const onBannerSaveClick = useCallback(async () => {
    const page: TablistPageType = {
      id: '0',
      title: window.document.title,
      url: window.document.location.href,
      favicon_url: `${WEBAPP_BASE_URL}/favicon.ico`,
      entity_type: TABLIST_PAGE_ENUM.OPEN,
      last_access_timestamp_ms: Date.now(),
      last_access_datetime_utc_iso: new Date().toISOString(),
    }

    await appendTablistPages({ pages: [page], isManual: true })
      .unwrap()
      .then(() => {
        navigate('/')
      })
      .catch((error) => {
        console.error(error)
      })

    captureAnalytics('shared_project_page:banner_save_click', {
      ...project,
      url: page.url,
      page,
    })
  }, [appendTablistPages, captureAnalytics, navigate, project])

  return (
    <>
      {userInfo ? (
        <StaticBanner
          text={`Need this tab later? Save it to ${APP_NAME}`}
          buttonText="Save"
          buttonOnClick={onBannerSaveClick}
        />
      ) : (
        <StaticBanner
          text={`Want to save this folder or make one of your own? Sign up for ${APP_NAME}!`}
          buttonText="Sign up"
          buttonIcon={<FiChevronRight />}
          buttonOnClick={onBannerSignUpClick}
        />
      )}
      <Flex direction="column" h="100%" w="100%" p={{ base: '32px 16px', md: '32px' }}>
        <Flex w="100%" ml="16px" mb={{ base: '32px', md: '48px' }} flex="0 0 auto">
          <Link href={isMobileOrTabletDevice ? LANDING_PAGE_URL : '/'} onClick={onClickSkipperLogo}>
            <Img src={APP_LOGO_WORDMARK_URL} alt={`${APP_NAME} logo`} height={'22px'} />
          </Link>
        </Flex>

        <Grid width="100%" templateColumns={{ base: '1fr', md: 'repeat(12, 1fr)' }} flex="1 1 auto">
          <GridItem
            className="scrollbars"
            w="100%"
            overflow="auto"
            colStart={{ base: 1, md: 4 }}
            colSpan={{ base: 1, md: 6 }}
            minWidth={{ base: '100%', md: '552px' }}
            maxWidth="1000px"
            mb={{ base: '134px', md: '0' }}
            flexGrow={1}
          >
            <Flex
              width="100%"
              flexDirection="column"
              background="#f6f6f6"
              borderRadius="16px"
              padding={{ base: '16px', md: '24px 24px 24px 10px' }}
              transition="all 0.2s ease-in-out"
              overflow="hidden"
            >
              <Box mb="16px" ml={{ base: '8px', md: '16px' }}>
                <Box mb="8px">
                  <ProjectDetailTitle
                    project={project}
                    urls={urls}
                    isViewOnly={true}
                    isExtensionInstalled={isExtensionInstalled}
                  />
                </Box>
                <Text fontSize="12px" fontWeight={500} lineHeight="16px" color="#A7A7A7">
                  {`by ${project.owner_email}`}
                </Text>
              </Box>
              {isPagesLoading && (
                <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                  <Spinner color="blue.500" size="md" speed="1s" />
                </div>
              )}

              {!isPagesLoading && !pages && (
                <div className="flex-center" style={{ width: '100%' }}>
                  <Text textAlign="center" margin={4} fontSize="14px" color="#a7a7a7">
                    {`Pages for this folder could not be loaded.`}
                  </Text>
                </div>
              )}

              {pages && (
                <Box className="scrollbars">
                  {pages.length === 0 && (
                    <Box
                      width="100%"
                      textAlign="center"
                      alignItems="center"
                      overflow="hidden"
                      {...props}
                    >
                      <Text
                        color="#a7a7a7"
                        marginLeft="16px"
                        marginTop="8px"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="16px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {`This folder is empty.`}
                      </Text>
                    </Box>
                  )}
                  {pages.length > 0 && (
                    <Flex width="100%" flexDirection="column" paddingTop="6px" paddingBottom="1px">
                      {pages.map((p) => {
                        return (
                          <ProjectDetailSharedPageController
                            key={p.id}
                            projectId={project.id}
                            page={p}
                            isExtensionInstalled={isExtensionInstalled}
                          />
                        )
                      })}
                    </Flex>
                  )}
                </Box>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </>
  )
}

export default SharedProjectPage
