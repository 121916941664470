import React, { FC } from 'react'
import { styled } from 'styled-components'
import { BiExpand } from 'react-icons/bi'

const Container = styled.div`
  display: none;

  @media (max-width: 1020px), (max-height: 700px) {
    /* Screens narrower than 1020px or shorter than 490px */
    display: inline-flex;
  }

  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0);

  padding: 16px 24px;
  align-items: center;
  justify-content: center;

  z-index: 2;
  border-radius: 40px;
  border: 1px solid #a3ccf5;
  background: #cce3f9;
  box-shadow:
    0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.08);

  p {
    color: #0071e3;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    white-space: nowrap;
  }

  svg {
    color: #0071e3;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
`

const ExpandWindowMessage: FC = () => {
  return (
    <Container>
      <BiExpand />
      <p>Expand your window for optimal experience</p>
    </Container>
  )
}

export default ExpandWindowMessage
