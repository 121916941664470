import React, { FC, useEffect } from 'react'
import { Box, Flex, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useUserContext } from '../../../contexts/UserContext'
import { IoMdArrowBack } from 'react-icons/io'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { css } from '@emotion/css'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import SettingsForm from './SettingsForm'

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 16px 0px;
  margin-left: 6px;
  gap: 12px;
  cursor: pointer;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
  }
`

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 24px;
`

const backIconButtonClassName = css`
  & > svg {
    width: 20px;
    height: 20px;
  }
`

const SettingsDashboard: FC = () => {
  const { isDefault, featureFlags } = useFeatureFlagContext()
  const isConfigurable = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsConfigurable]
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  useEffect(() => {
    captureAnalytics('settings_page:page_view')
  }, [captureAnalytics])

  useEffect(() => {
    if (!isDefault && !isConfigurable) {
      navigate('/not-found')
    }
  }, [isConfigurable, isDefault, navigate])

  const handleClickMenuBack = () => {
    captureAnalytics('settings_dashboard:back_click')
    navigate('/')
  }

  return (
    <>
      <Flex direction="column" h="100%" w="100%" p="24px 16px" overflow="auto">
        <SearchResultsReplacementWrapper>
          <TableHeaderContainer>
            <IconButton
              className={backIconButtonClassName}
              variant="outline"
              border="none"
              aria-label="Go to home page"
              icon={<IoMdArrowBack />}
              onClick={handleClickMenuBack}
              height="24px"
              width="24px"
              minWidth="24px"
              flexShrink={0}
              _hover={{
                bg: '#EBEBEB',
              }}
            />
            <h2>Settings</h2>
          </TableHeaderContainer>
          <BodyContainer className="scrollbars">
            <Box
              width="100%"
              height="100%"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              bg="#F6F6F6"
              borderRadius="16px"
              p="12px 12px 0px 12px"
            >
              <SettingsForm />
            </Box>
          </BodyContainer>
        </SearchResultsReplacementWrapper>
      </Flex>
    </>
  )
}

export default SettingsDashboard
