import React, { FC, useEffect, useRef } from 'react'
import { Button, Flex } from '@chakra-ui/react'
import { useUserContext } from '../../../contexts/UserContext'
import AnnouncementsPage from '../../../components/AnnouncementsPage'
import { IoMdArrowBack } from 'react-icons/io'
import { APP_NAME } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { isMobileOrTabletDevice } from '../../../utils/deviceUtils'

const ProjectNotFoundPage: FC = () => {
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()
  const didLogRef = useRef(false)

  useEffect(() => {
    if (didLogRef.current) {
      return
    }

    captureAnalytics('project_not_found_page:view', { url: window.location.href })
    didLogRef.current = true
  }, [captureAnalytics])

  const onClickGoToSkipper = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.location.href = 'https://skipper.co'
    captureAnalytics('project_not_found_page:click_go_to_skipper')
  }

  const onClickGoHome = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    navigate('/')
    captureAnalytics('project_not_found_page:click_go_home')
  }

  const heading = 'Oops! This folder is not available.'
  const content = [
    "It either no longer exists or you don't have access to it.",
    'Ready to stay organized with your tabs? Sign up for Skeema and start creating your own folders today!',
  ]

  const widget = (
    <Flex mb="4">
      <Button
        as="a"
        backgroundColor="black"
        borderRadius="20px"
        borderColor="#D5D5D5"
        color="white"
        fontWeight="normal"
        padding="0 30px"
        title={`Return to ${APP_NAME} Home`}
        href={`/`}
        rel="noreferrer"
        onClick={isMobileOrTabletDevice ? onClickGoToSkipper : onClickGoHome}
        leftIcon={isMobileOrTabletDevice ? undefined : <IoMdArrowBack />}
      >
        {isMobileOrTabletDevice ? 'Go to Skipper' : 'Return Home'}
      </Button>
    </Flex>
  )

  return <AnnouncementsPage heading={heading} content={content} widget={widget} />
}

export default ProjectNotFoundPage
