import React, { FC } from 'react'
import { Tooltip, IconButton, Heading, Flex, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { LuUndo2 } from 'react-icons/lu'

const TitleContainer = styled.div`
  position: relative;
  width: 100%;

  h2 {
    color: #585858;
    transition: color 0.2s;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const HoverButtonsContainer = styled.div`
  height: 22px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 0px 6px;
`

interface Props {
  title: string
  timestamp: string
  handleUndoClick: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const HistoryItemTitle: FC<Props> = (props) => {
  const { title, timestamp, handleUndoClick } = props

  return (
    <TitleContainer>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Heading as="h2">{title}</Heading>

          <Text color={'#A7A7A7'} fontSize={'12px'} fontWeight={500} ml="8px" lineHeight={'22px'}>
            {timestamp}
          </Text>
        </Flex>
        <HoverButtonsContainer>
          <Tooltip label={`Undo`} placement="top">
            <span className="flex-center" style={{ height: '100%', cursor: 'pointer' }}>
              <IconButton
                variant="outline"
                border="none"
                aria-label={`Open all pages`}
                as="a"
                icon={<LuUndo2 color="#585858" />}
                height="24px"
                width="24px"
                minWidth="24px"
                tabIndex={-1}
                _hover={{ background: 'inherit' }}
                background={'transparent'}
                onClick={handleUndoClick}
              />
            </span>
          </Tooltip>
        </HoverButtonsContainer>
      </Flex>

      <Text color={'#A7A7A7'} fontSize={'12px'} fontWeight={500} lineHeight="16px" mt="4px">
        Time limit of inactivity reached
      </Text>
    </TitleContainer>
  )
}

export default HistoryItemTitle
