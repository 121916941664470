import { createSlice } from '@reduxjs/toolkit'

export interface UserSliceStateType {
  isAuthLoading: boolean
  authHeader: string | null
  instantSaveQuota: number | undefined
}

const initialState: UserSliceStateType = {
  isAuthLoading: true,
  authHeader: null,
  instantSaveQuota: undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthHeader: (state, action) => {
      state.isAuthLoading = false
      state.authHeader = action.payload
    },
    clearAuthHeader: (state) => {
      state.authHeader = null
    },
    setInstantSaveQuota: (state, action) => {
      state.instantSaveQuota = action.payload
    },
    decInstantSaveQuota: (state, _action) => {
      if (state.instantSaveQuota) {
        state.instantSaveQuota -= 1
      }
    },
    incInstantSaveQuota: (state, _action) => {
      if (state.instantSaveQuota) {
        state.instantSaveQuota += 1
      }
    },
  },
})

export const {
  setAuthHeader,
  clearAuthHeader,
  setInstantSaveQuota,
  decInstantSaveQuota,
  incInstantSaveQuota,
} = userSlice.actions

export default userSlice.reducer
