import React, { FC } from 'react'
import { Heading, Select, Box } from '@chakra-ui/react'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'

export const getOptionsForStaleThresholdAfterOverwhelm = ({
  isInternal,
}: {
  isInternal: boolean
}) => {
  const options = [
    { key: 3600000, value: 3600000, label: '1 hour' },
    { key: 14400000, value: 14400000, label: '4 hours' },
    { key: 28800000, value: 28800000, label: '8 hours' },
    { key: 86400000, value: 86400000, label: '24 hours' },
  ]

  if (isInternal) {
    options.push({ key: 10000, value: 10000, label: '10s (internal only)' })
    options.push({ key: 60000, value: 60000, label: '1 min (internal only)' })
    options.push({ key: 1800000, value: 1800000, label: '30 min (internal only)' })
  }

  return options.map((option) => (
    <option key={option.key} value={option.value}>
      {option.label}
    </option>
  ))
}

const getOptionsForOverwhelmThreshold = ({ isInternal }: { isInternal: boolean }) => {
  const options = [
    { key: 0, value: 0, label: '0' },
    { key: 6, value: 6, label: '6' },
    { key: 12, value: 12, label: '12' },
    { key: 18, value: 18, label: '18' },
  ]

  if (isInternal) {
    options.push({ key: 8, value: 8, label: '8 (internal only)' })
  }

  return options.map((option) => (
    <option key={option.key} value={option.value}>
      {option.label}
    </option>
  ))
}

interface Props {
  isGuidedAutoSaveEnabled?: boolean
  staleThresholdAfterOverwhelm?: number
  overwhelmThreshold?: number
  onChangeStaleThresholdAfterOverwhelm: (value: number) => void
  onChangeOverwhelmThreshold: (value: number) => void
}

const AutoSaveSettingsFormContent: FC<Props> = (props) => {
  const {
    isGuidedAutoSaveEnabled,
    staleThresholdAfterOverwhelm,
    overwhelmThreshold,
    onChangeStaleThresholdAfterOverwhelm,
    onChangeOverwhelmThreshold,
  } = props
  const { featureFlags } = useFeatureFlagContext()
  const isInternal = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]

  return (
    <Box>
      <Box>
        <Heading as="h2" fontSize="14px" fontWeight={500} color="#585858" lineHeight="22px">
          Time limit
        </Heading>
        <Box mt="2px" fontSize="12px" lineHeight="12px" color="#585858">
          {`${
            isGuidedAutoSaveEnabled ? 'Guide me to save and close' : 'Save and close'
          } tabs that have been inactive for more than`}
          <Select
            name="staleThresholdAfterOverwhelm"
            value={staleThresholdAfterOverwhelm}
            onChange={(e) => onChangeStaleThresholdAfterOverwhelm(Number(e.target.value))}
            display="inline-block"
            w="94px"
            h="28px"
            mt={1}
            mx={1}
            size="xs"
            fontSize="12px"
            borderColor="black"
            borderRadius="8px"
            _hover={{ bg: '#E3E3E3' }}
          >
            {getOptionsForStaleThresholdAfterOverwhelm({ isInternal })}
          </Select>
        </Box>
      </Box>

      <Box mt="16px">
        <Heading as="h2" fontSize="14px" fontWeight={500} color="#585858" lineHeight="22px">
          Tab limit
        </Heading>
        <Box mt="2px" fontSize="12px" lineHeight="12px" color="#585858">
          {`${
            isGuidedAutoSaveEnabled ? 'Only suggest closing' : 'Only close'
          } tabs when I have more than`}
          <Select
            value={overwhelmThreshold}
            onChange={(e) => onChangeOverwhelmThreshold(Number(e.target.value))}
            display="inline-block"
            w="60px"
            h="28px"
            mx={1}
            size="xs"
            name="overwhelmThreshold"
            fontSize="12px"
            borderColor="black"
            borderRadius="8px"
            _hover={{ bg: '#E3E3E3' }}
          >
            {getOptionsForOverwhelmThreshold({ isInternal })}
          </Select>
          {`unpinned tabs in a window`}
        </Box>
      </Box>
    </Box>
  )
}

export default AutoSaveSettingsFormContent
