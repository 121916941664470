import { EXTENSION_ID } from '../../constants'
import {
  BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  MESSAGE_AGENTS,
} from '../../extension/models/messaging.types'

export async function sendMessageToExtension(
  action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  payload?: unknown,
): Promise<unknown> {
  // Do not use the browser polyfill here, as importing the webextension-polyfill would break mobile rendering
  // Unless it's dynamically imported here
  return chrome?.runtime?.sendMessage(EXTENSION_ID, {
    to: MESSAGE_AGENTS.BACKGROUND,
    from: MESSAGE_AGENTS.MAIN_PAGE,
    action,
    payload,
  })
}
