import { createSlice } from '@reduxjs/toolkit'
import { TablistPageType } from '../models/tablist_pages.types'
import { ReduxStateType } from './baseStore'

export interface TablistSliceStateType {
  savedPages: TablistPageType[] | undefined
  highlightedPageIds: string[] | undefined
}

const initialState: TablistSliceStateType = {
  savedPages: undefined,
  highlightedPageIds: undefined,
}

export const tablistSlice = createSlice({
  name: 'tablist',
  initialState,
  reducers: {
    loadOlderSavedPages: (
      state,
      action: {
        payload: TablistPageType[]
      },
    ) => {
      state.savedPages = state.savedPages ? state.savedPages.concat(action.payload) : action.payload
    },
    updateMostRecentSavedPages: (
      state,
      action: {
        payload: TablistPageType[]
      },
    ) => {
      if (!state.savedPages) {
        state.savedPages = action.payload
        return
      }

      const updatedPages = action.payload

      if (updatedPages.length > 0) {
        const oldestUpdatedPageTimestamp =
          updatedPages[updatedPages.length - 1].last_access_timestamp_ms

        let numElementsToReplace = 0
        let i = 0
        while (
          i < state.savedPages.length &&
          state.savedPages[i].last_access_timestamp_ms >= oldestUpdatedPageTimestamp
        ) {
          numElementsToReplace = i + 1
          i++
        }

        state.savedPages.splice(0, numElementsToReplace, ...updatedPages)
      }
    },
    setHighlightedPageIds: (
      state,
      action: {
        payload: string[] | undefined
      },
    ) => {
      state.highlightedPageIds = action.payload
    },
    savePages: (
      state,
      action: {
        payload: {
          pages: TablistPageType[]
          index?: number
        }
      },
    ) => {
      if (!state.savedPages) {
        return
      }
      const { pages, index = 0 } = action.payload
      state.savedPages.splice(index, 0, ...pages)
    },
    deletePages: (
      state,
      action: {
        payload: string[]
      },
    ) => {
      if (!state.savedPages) {
        return
      }
      const ids = action.payload
      for (const id of ids) {
        const idx = state.savedPages.findIndex((p) => p.id === id)
        if (idx !== -1) {
          state.savedPages.splice(idx, 1)
        }
      }
    },
    replacePages: (
      state,
      action: {
        payload: {
          ids: string[]
          newPages: TablistPageType[]
        }
      },
    ) => {
      if (!state.savedPages) {
        return
      }
      const { ids, newPages } = action.payload
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i]
        const newPage = newPages[i]
        const idx = state.savedPages.findIndex((p) => p.id === id)
        if (idx !== -1) {
          state.savedPages.splice(idx, 1, newPage)
        }
      }
    },
  },
})

export const selectTablistSavedPages = (state: ReduxStateType) => state.tablist.savedPages
export const selectHighlightedPageIds = (state: ReduxStateType) => state.tablist.highlightedPageIds

export const {
  loadOlderSavedPages,
  updateMostRecentSavedPages,
  savePages,
  deletePages,
  replacePages,
  setHighlightedPageIds,
} = tablistSlice.actions

export default tablistSlice.reducer
