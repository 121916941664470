import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_SERVER_URL } from '../../constants'
import { ReduxStateType } from '../baseStore'

export const skeemaApi = createApi({
  reducerPath: 'skeemaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_SERVER_URL}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Content-Type', 'application/json')

      const state = getState() as ReduxStateType
      const authHeader = state.user.authHeader

      if (authHeader) {
        headers.set('Authorization', authHeader)
      }
      return headers
    },
  }),
  refetchOnFocus: true,
  endpoints: () => ({}),
  tagTypes: [
    'HistoryItem',
    'ProjectPermissions',
    'SmartSessions',
    'ProjectDetailPages',
    'ActiveProjects',
  ],
})
