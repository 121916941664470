import { Tooltip, IconButton } from '@chakra-ui/react'
import React, { FC, useRef, useState } from 'react'
import { AiOutlineChrome } from 'react-icons/ai'
import { MdClose, MdCheck } from 'react-icons/md'
import { styled } from 'styled-components'
import DragIcon from '../../../components/DragIcon'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 0px;

  border-radius: 6px;
  border: 1px solid #d5d5d5;
  opacity: 1;
  background: #ebebeb;

  .DragIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    margin-right: -4px;
    flex-shrink: 0;
    overflow: hidden;
  }

  .TabBodyContainerIcon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 1;

    & > span {
      position: relative; //Need for children showOnHover/hideOnHover to be properly centered
      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }
  }
`

const EditableTitle = styled.input`
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 18px;
  flex-grow: 1;

  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  cursor: text;
  min-width: 0;

  &::selection {
    background: #cce3f9;
    color: #585858;
  }
`

interface Props {
  title: string
  favIconUrl?: string
  handleSaveRename: (newTitle: string) => void
  handleCancelRename: (newTitle: string) => void
}

const ProjectDetailPageEditableTitle: FC<Props> = (props) => {
  const { title, favIconUrl, handleCancelRename, handleSaveRename } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false)

  return (
    <Container>
      <span className="DragIcon">
        <DragIcon />
      </span>
      <span className="TabBodyContainerIcon">
        {favIconUrl ? (
          <img src={favIconUrl} width="16" height="16" />
        ) : (
          <AiOutlineChrome style={{ width: '16px', height: '16px', color: 'black' }} />
        )}
      </span>
      <EditableTitle
        type="text"
        ref={inputRef}
        maxLength={50}
        placeholder={'Tab Title'}
        defaultValue={title}
        onChange={(e) => setIsSaveDisabled(e.currentTarget.value.trim() === '')}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            handleSaveRename(e.currentTarget.value)
          } else if (e.key === 'Escape') {
            handleCancelRename(e.currentTarget.value)
          }
        }}
        onFocus={(e) => e.currentTarget.select()}
        autoFocus
      />
      <div className="flex-center" style={{ marginLeft: '4px', height: '100%', cursor: 'pointer' }}>
        <Tooltip placement="top" label="Cancel">
          <IconButton
            variant="outline"
            border="none"
            aria-label="Cancel Rename"
            as="a"
            icon={<MdClose />}
            onClick={() => handleCancelRename(inputRef.current?.value ?? title)}
            height="18px"
            width="18px"
            minWidth="18px"
            borderRadius="4px"
            color="#585858"
            _hover={{
              color: '#585858',
              bg: '#EBEBEB',
            }}
          />
        </Tooltip>
        <Tooltip placement="top" label="Save">
          <IconButton
            variant="outline"
            border="none"
            borderRadius="4px"
            aria-label="Save"
            as="a"
            icon={<MdCheck />}
            onClick={() => handleSaveRename(inputRef.current?.value ?? title)}
            height="18px"
            width="18px"
            minWidth="18px"
            ml="8px"
            bg="#000000"
            color="#ffffff"
            isDisabled={isSaveDisabled}
            _hover={{
              bg: '#585858',
              color: 'white',
            }}
          />
        </Tooltip>
      </div>
    </Container>
  )
}

export default ProjectDetailPageEditableTitle
