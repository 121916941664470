import { ProjectPageType, ProjectType } from './saved_sessions.types'
import { SmartSessionType } from './smart_sessions.types'
import { TablistPageType } from './tablist_pages.types'

export enum DND_ITEM_ENUM {
  TABLIST_PAGE = 'TABLIST_PAGE',
  PROJECT_PAGE = 'PROJECT_PAGE',
  PROJECT = 'PROJECT',
  SMART_SESSION = 'SMART_SESSION',
}

interface DnDItemGenericPayload {
  type: DND_ITEM_ENUM
}

export interface DnDItemTablistPagePayload extends DnDItemGenericPayload {
  type: DND_ITEM_ENUM.TABLIST_PAGE
  page: TablistPageType
  index: number
}

export interface DnDItemProjectPagePayload extends DnDItemGenericPayload {
  type: DND_ITEM_ENUM.PROJECT_PAGE
  page: ProjectPageType
  projectId: string
  index: number
}

export interface DnDItemProjectPayload extends DnDItemGenericPayload {
  type: DND_ITEM_ENUM.PROJECT
  project: ProjectType
}

export interface DnDItemSmartSessionPayload extends DnDItemGenericPayload {
  type: DND_ITEM_ENUM.SMART_SESSION
  session: SmartSessionType
}

export type DnDItemPayload =
  | DnDItemTablistPagePayload
  | DnDItemProjectPagePayload
  | DnDItemProjectPayload
  | DnDItemSmartSessionPayload
