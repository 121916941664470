import React, { FC, useCallback, useState } from 'react'
import { Flex, Text, Spinner, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  selectNumActiveProjects,
  useDeleteArchivedProjectMutation,
  useGetArchivedProjectsQuery,
  useUnarchiveProjectMutation,
} from '../../../redux/services/skeema/projects.endpoints'
import { styled } from 'styled-components'
import ArchivedProjectCard from './ArchivedProjectCard'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useReduxSelector } from '../../../redux/baseStore'
import ProjectDeletionModal from './ProjectDeletionModal'
import ProjectLimitModal from '../projectsSidebar/ProjectLimitModal'
import { IoMdArrowBack } from 'react-icons/io'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { css } from '@emotion/css'

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 16px 0px;
  margin-left: 6px;
  gap: 12px;
  cursor: pointer;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
  }
`

const CardListContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 12px;
  padding-bottom: 24px;
`

const backIconButtonClassName = css`
  & > svg {
    width: 20px;
    height: 20px;
  }
`

interface Props {
  authHeader: string
}

const ProjectArchiveDashboard: FC<Props> = (props) => {
  const { authHeader } = props
  const { captureAnalytics } = useUserContext()
  const navigate = useNavigate()

  const { projectConfig } = useFeatureFlagContext()
  const numProjects = useReduxSelector(selectNumActiveProjects)
  const maxNumProjects = projectConfig.maxNumProjects
  const isProjectLimitReached = numProjects === undefined || numProjects >= maxNumProjects

  const [projectDeletionId, setProjectDeletionId] = useState<string | null>(null)
  const [isProjectLimitModalOpen, setIsProjectLimitModalOpen] = useState<boolean>(false)

  const { data: projects } = useGetArchivedProjectsQuery(undefined, { skip: !authHeader })
  const [unarchiveProjectMutation] = useUnarchiveProjectMutation()
  const [deleteArchivedProjectMutation] = useDeleteArchivedProjectMutation()

  const handleUnarchiveProject = useCallback(
    (id: string) => {
      const project = projects?.find((project) => project.id === id)
      if (!project) {
        return
      }

      const urls = project?.pages?.map((page) => page.url)

      captureAnalytics('archived_projects_list:project_unarchive', {
        numTabs: urls?.length,
        urls,
        ...project,
      })

      unarchiveProjectMutation({ project })
    },
    [captureAnalytics, unarchiveProjectMutation, projects],
  )

  const handleDeleteProject = useCallback(() => {
    if (!projectDeletionId) {
      return
    }

    const project = projects?.find((project) => project.id === projectDeletionId)
    const urls = project?.pages?.map((page) => page.url)

    captureAnalytics('archived_projects_list:project_delete', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    deleteArchivedProjectMutation({ id: projectDeletionId })

    setProjectDeletionId(null)
  }, [captureAnalytics, deleteArchivedProjectMutation, projectDeletionId, projects])

  const handleOpenProjectDeletionModal = useCallback(
    (projectId: string) => {
      const project = projects?.find((project) => project.id === projectId)
      if (!project) {
        return
      }

      setProjectDeletionId(projectId)
    },
    [projects],
  )

  const handleCancelProjectDeletion = useCallback(() => {
    if (!projectDeletionId) {
      return
    }

    const project = projects?.find((p) => p.id === projectDeletionId)
    const urls = project?.pages?.map((page) => page.url)

    captureAnalytics('archived_projects_list:project_delete_dialog_cancel_click', {
      numTabs: urls?.length,
      urls,
      ...project,
    })

    setProjectDeletionId(null)
  }, [captureAnalytics, projectDeletionId, projects])

  const handleProjectLimitError = useCallback(() => {
    captureAnalytics('archived_projects_list:project_limit_modal_show')
    setIsProjectLimitModalOpen(true)
  }, [captureAnalytics])

  const handleDismissProjectLimitModal = () => {
    captureAnalytics('archived_projects_list:project_limit_modal_dismiss_click')
    setIsProjectLimitModalOpen(false)
  }

  const handleSubmitProjectLimitModal = () => {
    captureAnalytics('archived_projects_list:project_limit_modal_upgrade_click')
    setIsProjectLimitModalOpen(false)
    navigate('#pricing')
  }

  const handleClickMenuBack = () => {
    captureAnalytics('archived_projects_list:back_click')
    navigate('/')
  }

  return (
    <>
      <Flex direction="column" h="100%" w="100%" p="24px 16px" overflow="auto">
        <SearchResultsReplacementWrapper>
          <TableHeaderContainer>
            <IconButton
              className={backIconButtonClassName}
              variant="outline"
              border="none"
              aria-label="Go to home page"
              icon={<IoMdArrowBack />}
              onClick={handleClickMenuBack}
              height="24px"
              width="24px"
              minWidth="24px"
              flexShrink={0}
              _hover={{
                bg: '#EBEBEB',
              }}
            />
            <h2>Archive</h2>
          </TableHeaderContainer>
          <CardListContainer className="scrollbars">
            {!projects && (
              <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                <Spinner color="blue.500" size="lg" speed="1s" />
              </div>
            )}
            {projects && projects.length === 0 && (
              <div className="flex-center" style={{ width: '100%' }}>
                <Text textAlign="center" margin={4} fontSize="14px" color="#a7a7a7">
                  {`Archived items will appear here and can be either restored or deleted.`}
                </Text>
              </div>
            )}
            {projects &&
              projects.length > 0 &&
              projects.map((project, idx) => (
                <ArchivedProjectCard
                  key={`${project.title}${idx}`}
                  project={project}
                  isProjectLimitReached={isProjectLimitReached}
                  handleDeleteProject={handleOpenProjectDeletionModal}
                  handleUnarchiveProject={handleUnarchiveProject}
                  handleProjectLimitError={handleProjectLimitError}
                />
              ))}
          </CardListContainer>
        </SearchResultsReplacementWrapper>
      </Flex>

      <ProjectDeletionModal
        isOpen={projectDeletionId !== null}
        onCancel={handleCancelProjectDeletion}
        onDelete={handleDeleteProject}
      />

      <ProjectLimitModal
        isOpen={isProjectLimitModalOpen}
        onCancel={handleDismissProjectLimitModal}
        onSubmit={handleSubmitProjectLimitModal}
      />
    </>
  )
}

export default ProjectArchiveDashboard
