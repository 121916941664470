export enum FEATURE_FLAG_KEYS_ENUM {
  IsDeveloper = 'is_developer',
  IsDemoAccount = 'is_demo_account',
  IsAlphaUser = 'is_alpha_user',
  IsBetaUser = 'is_beta_user', // inclusive of alpha users
  IsAllActivity = 'is_all_activity',
  IsInterview = 'is_interview',
  ShouldIncludeOpenTabs = 'should_include_open_tabs',
  ShouldPinSkeemaTab = 'should_pin_skeema_tab',
  ShouldHaveExpandedProjects = 'should_have_expanded_projects',
  ShouldHaveExperimentalPutaway = 'should_have_experimental_putaway',
  ShouldHaveExperimentalPutawayV2 = 'should_have_experimental_putaway_v2',
  ShouldKeepRelatedTabsOpen = 'should_keep_related_tabs_open',
  IsPaywallEnabled = 'is_paywall_enabled',
  IsConfigurable = 'is_configurable',
  ShouldHaveUnlimitedInstantSave = 'should_have_unlimited_instant_save',
  ShouldPutawayOnUnfocusedWindows = 'should_putaway_on_unfocused_windows',
  ShouldRemoveProjectSidebarExpansion = 'should_remove_project_sidebar_expansion',
  IsDefaultGuidedAutoSave = 'is_default_guided_auto_save',
}

export type FeatureFlagValuesType = {
  [FEATURE_FLAG_KEYS_ENUM.IsDeveloper]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsDemoAccount]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsBetaUser]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsAllActivity]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsInterview]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldIncludeOpenTabs]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldPinSkeemaTab]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveExpandedProjects]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveExperimentalPutaway]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveExperimentalPutawayV2]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldKeepRelatedTabsOpen]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsConfigurable]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveUnlimitedInstantSave]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldPutawayOnUnfocusedWindows]: boolean
  [FEATURE_FLAG_KEYS_ENUM.ShouldRemoveProjectSidebarExpansion]: boolean
  [FEATURE_FLAG_KEYS_ENUM.IsDefaultGuidedAutoSave]: boolean
}

export const DEFAULT_FEATURE_FLAG_VALUES: FeatureFlagValuesType = {
  [FEATURE_FLAG_KEYS_ENUM.IsDeveloper]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsDemoAccount]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsBetaUser]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsAllActivity]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsInterview]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldIncludeOpenTabs]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldPinSkeemaTab]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveExpandedProjects]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveExperimentalPutaway]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveExperimentalPutawayV2]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldKeepRelatedTabsOpen]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsPaywallEnabled]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsConfigurable]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldHaveUnlimitedInstantSave]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldPutawayOnUnfocusedWindows]: false,
  [FEATURE_FLAG_KEYS_ENUM.ShouldRemoveProjectSidebarExpansion]: false,
  [FEATURE_FLAG_KEYS_ENUM.IsDefaultGuidedAutoSave]: false,
}
