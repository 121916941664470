/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  MESSAGE_AGENTS,
  ShowOnPageNotificationPayload,
} from '../../models/messaging.types'
import { EXTENSION_ID } from '../../../constants'
import { NOTIFICATION_FUNCTIONS } from './utils/notificationUtils'

export function onMessageActionGetPageHTML(
  _msg: unknown,
  _sender: chrome.runtime.MessageSender,
): unknown {
  const html = window.document.documentElement.innerHTML
  return { html }
}

export function onMessageShowOnPageNotification(
  msg: any,
  _sender: chrome.runtime.MessageSender,
): boolean {
  const payload = msg.payload as ShowOnPageNotificationPayload | undefined
  if (!payload) {
    return false
  }

  NOTIFICATION_FUNCTIONS.showNotification(payload)
  return true
}

export async function sendMessageToBackground(
  action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  payload?: unknown,
): Promise<unknown> {
  return chrome.runtime.sendMessage(EXTENSION_ID, {
    to: MESSAGE_AGENTS.BACKGROUND,
    from: MESSAGE_AGENTS.CONTENT,
    action,
    payload,
  })
}

export async function sendMessageToBackgroundWithTimeout(
  action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  payload?: unknown,
  timeout: number = 5000,
): Promise<unknown> {
  const messagePromise = chrome.runtime.sendMessage(EXTENSION_ID, {
    to: MESSAGE_AGENTS.BACKGROUND,
    from: MESSAGE_AGENTS.CONTENT,
    action,
    payload,
  })

  const timeoutPromise = new Promise((_resolve, reject) => {
    setTimeout(
      () =>
        reject(
          new Error(`ERROR: Message ${action} with payload ${JSON.stringify(payload)} timed out`),
        ),
      timeout,
    )
  })

  return Promise.race([messagePromise, timeoutPromise])
}
