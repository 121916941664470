import { FC, useEffect } from 'react'
import { useUserContext } from '../../../contexts/UserContext'
import {
  EXTENSION_SETTINGS_KEYS,
  ExtensionSettingsType,
} from '../../../extension/models/extensionStorage.types'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { retryFunction } from '../../../utils/utils'

const FixAutoSaveTimingBugWidget: FC = () => {
  const { captureAnalytics } = useUserContext()

  useEffect(() => {
    const detectAndFixTimingBug = async () => {
      const result = await sendMessageToExtension(
        BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS,
      ).then(async (response) => {
        const settings = response as ExtensionSettingsType
        const isTimingBugPresent =
          settings[EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM] === 60000

        let wasFixed = false
        if (isTimingBugPresent) {
          const HOUR_IN_MS = 3600000
          const result = await sendMessageToExtension(
            BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS,
            {
              settings: {
                [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]: HOUR_IN_MS,
              },
            },
          )
          wasFixed = result as boolean
        }

        return {
          staleThresholdAfterOverwhelm:
            settings[EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM],
          isTimingBugPresent,
          wasFixed,
        }
      })

      return result
    }

    void retryFunction(detectAndFixTimingBug, 3, 1000).then((val) => {
      const { success, attempt, result } = val
      if (result?.isTimingBugPresent) {
        captureAnalytics('fix_auto_save_timing_bug_widget:execution', {
          success,
          attempt,
          ...(result ?? {}),
        })
      }
    })
  }, [captureAnalytics])

  return null
}

export default FixAutoSaveTimingBugWidget
