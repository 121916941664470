import React, { FC } from 'react'
import { styled } from 'styled-components'
import { TablistPageType } from '../../../models/tablist_pages.types'
import PutasideTabController from './PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from './PutasideTabList'
import { Box, Flex } from '@chakra-ui/react'

const TabGroupHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;

  & > h3 {
    color: #585858;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
`

const TabGroupIcon = styled.div<{ $color: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ $color }) => $color};
  border-radius: 4px;
  margin-right: 4px;
  margin-left: 16px;
`

const TAB_GROUP_COLOR_MAP: {
  [key in chrome.tabGroups.Color]: string
} = {
  grey: 'rgb(85,88,93)',
  blue: 'rgb(43,99,224)',
  red: 'rgb(199,57,39)',
  yellow: 'rgb(239,166,39)',
  green: 'rgb(44,115,53)',
  pink: 'rgb(190,41,120)',
  purple: 'rgb(143,56,237)',
  cyan: 'rgb(37,110,119)',
  orange: 'rgb(239,139,64)',
}

interface PropTypes {
  pages: TablistPageType[]
  title: string
  color: chrome.tabGroups.Color
}

const PutasideTabGroup: FC<PropTypes> = (props) => {
  const { pages, title, color } = props

  return (
    <Box w="100%" mt="8px" mb="4px">
      <TabGroupHeaderContainer>
        <TabGroupIcon $color={TAB_GROUP_COLOR_MAP[color]} />
        <h3>{title}</h3>
      </TabGroupHeaderContainer>
      <Flex>
        <Box position="relative" ml="23px" mr="-2px" mb="12px">
          <Box
            position="absolute"
            bottom="3px"
            left="0"
            h="calc(100% - 11px)"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="8px 8px 0px 4px"
            borderColor={TAB_GROUP_COLOR_MAP[color]}
          />
          <Box
            position="absolute"
            bottom="0"
            left="3px"
            w="5px"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="0px 8px 8px 4px"
            borderColor={TAB_GROUP_COLOR_MAP[color]}
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="4px"
            h="4px"
            borderStyle="solid"
            borderWidth="0 0 2px 2px"
            borderRadius="0px 0px 0px 8px"
            borderColor={TAB_GROUP_COLOR_MAP[color]}
          />
        </Box>

        <Box mt="8px" w="calc(100% - 21px)">
          {pages.map((p, idx) => {
            return (
              <PutasideTabController
                key={p.id}
                id={p.id}
                page={p}
                showTimeString={true}
                queryValue={''}
                index={idx}
                numTotalResults={pages.length}
                areaName={TABLIST_AREA_NAME_ENUM.Open}
                isTitleClickDisabled={true}
                isDraggingDisabled={true}
                isHoverDisabled={true}
              />
            )
          })}
        </Box>
      </Flex>
    </Box>
  )
}

export default PutasideTabGroup
