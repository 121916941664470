import React, { FC, useEffect, useRef, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { useUserContext } from '../../../contexts/UserContext'
import {
  EXTENSION_SETTINGS_KEYS,
  ExtensionSettingsType,
} from '../../../extension/models/extensionStorage.types'
import { GLOBAL_WINDOW_MESSAGE_ENUM, sendGlobalWindowMessage } from '../../../utils/utils'
import { getOptionsForStaleThresholdAfterOverwhelm } from '../settings/AutoSaveSettingsFormContent'
import { Heading, Select } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { FEATURE_FLAG_KEYS_ENUM } from '../../../models/feature_flags.types'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'

interface Props {
  phaseNum: number
  numPhases: number
  onNext: () => void
}
const OnboardingAutoSaveConfigDialog: FC<Props> = (props) => {
  const { phaseNum, onNext, numPhases } = props
  const { captureAnalytics } = useUserContext()
  const { featureFlags } = useFeatureFlagContext()
  const isInternal = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]
  const [settings, setSettings] = useState<ExtensionSettingsType | undefined>(undefined)
  const { staleThresholdAfterOverwhelm } = settings || {}

  const didLoadSettingsRef = useRef<boolean>(false)

  const handleSubmit = async () => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings,
    })

    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.PUTAWAY_TABS, {
      isMagicMoment: true,
    }).catch(() => {
      console.error('ERROR: Magic moment failed at button click')
    })

    captureAnalytics('onboarding_tutorial:automatic_save_start', settings)

    onNext()
  }

  const handleChangeStaleThresholdAfterOverwhelm = async (value: number) => {
    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SAVE_EXTENSION_SETTINGS, {
      settings: {
        [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]: value,
      },
    })

    setSettings((prev) =>
      prev
        ? {
            ...prev,
            [EXTENSION_SETTINGS_KEYS.STALE_THRESHOLD_AFTER_OVERWHELM]: value,
          }
        : prev,
    )
    captureAnalytics('guided_autosave_onboarding_modal:stale_threshold_after_overwhelm_change', {
      staleThresholdAfterOverwhelm: value,
    })
  }

  useEffect(() => {
    if (didLoadSettingsRef.current) {
      return
    }

    didLoadSettingsRef.current = true

    sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_EXTENSION_SETTINGS)
      .then((response) => {
        const settings = response as ExtensionSettingsType
        setSettings(settings)
      })
      .catch((e) => {
        console.error('Error fetching settings', e)
        onNext()
      })
  }, [onNext])

  useEffect(() => {
    sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.CHANGE_AUTOSAVE_SETTINGS, settings)
  }, [settings])

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={'How do you want Automatic Save to work?'}
      bodyContent={
        <Box p="16px" borderRadius="10px" bg="#F6F6F6">
          <Heading as="h3" fontSize="16px" fontWeight={600} color="#585858" lineHeight="24px">
            Time limit for inactivity{' '}
            <Box as="span" fontWeight={500}>
              (can be changed later)
            </Box>
          </Heading>
          <Box fontSize="14px" fontWeight={500} lineHeight="22px" color="#585858">
            {`Save and close tabs that have been inactive for more than`}
            <Select
              name="staleThresholdAfterOverwhelm"
              value={staleThresholdAfterOverwhelm}
              onChange={(e) => handleChangeStaleThresholdAfterOverwhelm(Number(e.target.value))}
              display="inline-block"
              w="104px"
              h="28px"
              mt={1}
              mx={1}
              size="xs"
              fontSize="14px"
              fontWeight={500}
              lineHeight="22px"
              borderColor="black"
              borderRadius="8px"
              _hover={{ bg: '#E3E3E3' }}
            >
              {getOptionsForStaleThresholdAfterOverwhelm({ isInternal })}
            </Select>
          </Box>
        </Box>
      }
      descriptionAfterBody={`You can change these options anytime in My Account > Settings.`}
      buttonText={`Activate Automatic Save`}
      onButtonClick={handleSubmit}
    />
  )
}
export default OnboardingAutoSaveConfigDialog
