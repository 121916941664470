import React, { FC, ReactElement } from 'react'
import ReactDOM from 'react-dom'

const portalRoot = document.getElementById('portal-container')

const Portal: FC<{ children: React.ReactNode }> = ({ children }): ReactElement => {
  return portalRoot ? ReactDOM.createPortal(children, portalRoot) : <></>
}

export default Portal
