import React, { FC } from 'react'
import { Flex, Text, Image } from '@chakra-ui/react'
import { FaCircle } from 'react-icons/fa6'

const styles = {
  sm: {
    h: '30px',
    p: '4px 10px',
    fontSize: '14px',
    fontWeight: '500',
    iconWidth: '12px',
    iconHeight: '12px',
  },
  lg: {
    h: '48px',
    p: '12px 16px',
    fontSize: '16px',
    fontWeight: '600',
    iconWidth: '24px',
    iconHeight: '24px',
  },
}

interface Props {
  plan: 'basic' | 'pro'
  size?: 'sm' | 'lg'
}

const PaymentPlanBadge: FC<Props> = (props) => {
  const { plan, size = 'sm' } = props

  if (plan === 'basic') {
    return (
      <Flex
        w="fit-content"
        h={styles[size].h}
        p={styles[size].p}
        gap="8px"
        alignItems="center"
        border="1px solid rgba(0, 0, 0, 0.30)"
        borderRadius="8px"
      >
        <FaCircle style={{ color: '#585858', width: '10px', height: '10px' }} />
        <Text fontSize={styles[size].fontSize} color="#585858" fontWeight={styles[size].fontWeight}>
          Basic
        </Text>
      </Flex>
    )
  }

  if (plan === 'pro') {
    return (
      <Flex
        w="fit-content"
        h={styles[size].h}
        p={styles[size].p}
        gap="8px"
        alignItems="center"
        border="1px solid rgba(23, 44, 235, 0.20)"
        borderRadius="8px"
        boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
      >
        <Image
          src={'/logo.svg'}
          alt="Logo icon"
          width={styles[size].iconWidth}
          height={styles[size].iconHeight}
        />
        <Text fontSize={styles[size].fontSize} color="#172CEB" fontWeight={styles[size].fontWeight}>
          Pro
        </Text>
      </Flex>
    )
  }
}

export default PaymentPlanBadge
