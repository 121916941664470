export enum ONBOARDING_TUTORIAL_STEPS {
  Preface = 'Preface',
  Stats = 'Stats',
  Intro = 'Intro',
  Intro2 = 'Intro2',
  TabGroups = 'TabGroups',
  DraftProjects = 'DraftProjects',
  PinTabs = 'PinTabs',
  GuidedAutoSave = 'GuidedAutoSave',
  AutoSaveConfig = 'AutoSaveConfig',
  TabList = 'TabList',
  InstantSave = 'InstantSave',
  Conclusion = 'Conclusion',
  DONE = 'DONE_V2',
}

export const VALID_ONBOARDING_TUTORIAL_STEPS: {
  //The boolean value is redundant, but we use an object here
  //so that typescript will ensure every enum value is present
  [key in ONBOARDING_TUTORIAL_STEPS]: boolean
} = {
  [ONBOARDING_TUTORIAL_STEPS.Preface]: true,
  [ONBOARDING_TUTORIAL_STEPS.Stats]: true,
  [ONBOARDING_TUTORIAL_STEPS.Intro]: true,
  [ONBOARDING_TUTORIAL_STEPS.Intro2]: true,
  [ONBOARDING_TUTORIAL_STEPS.TabGroups]: true,
  [ONBOARDING_TUTORIAL_STEPS.PinTabs]: true,
  [ONBOARDING_TUTORIAL_STEPS.GuidedAutoSave]: true,
  [ONBOARDING_TUTORIAL_STEPS.TabList]: true,
  [ONBOARDING_TUTORIAL_STEPS.AutoSaveConfig]: true,
  [ONBOARDING_TUTORIAL_STEPS.DraftProjects]: true,
  [ONBOARDING_TUTORIAL_STEPS.InstantSave]: true,
  [ONBOARDING_TUTORIAL_STEPS.Conclusion]: true,
  [ONBOARDING_TUTORIAL_STEPS.DONE]: true,
}
