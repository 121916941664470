import React, { FC } from 'react'
import {
  Text,
  Box,
  Button,
  Link,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Stack,
} from '@chakra-ui/react'
import { styled } from 'styled-components'
import { useUserContext } from '../../contexts/UserContext'
import { MdOutlineThumbsUpDown } from 'react-icons/md'
import { CiSettings } from 'react-icons/ci'
import { FiBookOpen, FiUser } from 'react-icons/fi'
import { GoSignOut } from 'react-icons/go'
import PaymentPlanBadge from './payment/PaymentPlanBadge'
import PlansSVG from '../../utils/svgs/PlansSVG'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthContext'
import { HOW_TO_URL, FEEDBACK_FORM_URL } from '../../constants'

export enum MENU_LEVEL_ENUM {
  MAIN = 'MAIN',
  ACCOUNT = 'ACCOUNT',
}

const ChakraMenuContainer = styled.div`
  display: flex;

  .chakra-menu-button {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .chakra-menu-list {
    position: relative;
    display: flex;
    flex-direction: column;

    border-radius: 16px;
    background: #fff;
    box-shadow:
      0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    width: 240px;
    padding: 8px;
    gap: 4px;
  }

  .chakra-menu-item {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 10px;

    p {
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }

    svg {
      margin-right: 8px;
      color: #585858;
    }

    &:hover {
      background: #ebebeb;
      text-decoration: none;

      svg {
        color: #585858;
      }

      p {
        background: #ebebeb;
      }
    }

    &:focus {
      background: #ebebeb;
      text-decoration: none;
    }
  }
`

const SubNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .hamburger-menu {
    display: flex;
  }
`

const SubNavButtons: FC = () => {
  const navigate = useNavigate()

  const { authHeader } = useAuthContext()
  const { userInfo, captureAnalytics, signOut } = useUserContext()

  const isPremium = !!userInfo?.is_premium

  const handleClickNavbarMenuIcon = () => {
    captureAnalytics('navbar:hamburger_menu_click')
  }

  const onClickGetStarted = () => {
    captureAnalytics('navbar:how_to')
  }

  const onClickShareFeedback = () => {
    captureAnalytics('navbar:share_feedback')
  }

  const onClickMyPlan = () => {
    navigate('/saved#pricing')
    captureAnalytics('navbar:my_plan_click', { isPremium })
  }

  const onClickSettings = () => {
    navigate('/settings')
    captureAnalytics('navbar:settings_click')
  }

  const onClickSignOut = async () => {
    await signOut()
    captureAnalytics('navbar:sign_out')
  }

  if (!authHeader) {
    return null
  }

  return (
    <SubNavContainer>
      {!isPremium && (
        <Button
          as="a"
          href="#pricing"
          mr="12px"
          borderRadius="16px"
          height="28px"
          minWidth="80px"
          background="#0071E3"
          color="white"
          fontWeight="500"
          fontSize="12px"
          _hover={{ bg: '#177CE3', color: 'white' }}
        >
          Upgrade
        </Button>
      )}
      <ChakraMenuContainer className="hamburger-menu">
        <Menu>
          <MenuButton
            className="chakra-menu-button"
            as={IconButton}
            aria-label="Options"
            icon={<FiUser />}
            onClick={handleClickNavbarMenuIcon}
            background="white"
            color="#585858"
            borderRadius="14px"
            _hover={{ bg: '#EBEBEB' }}
            _selected={{ bg: '#EBEBEB' }}
          />
          <MenuList className="chakra-menu-list" zIndex={12}>
            <Box>
              <Stack gap="8px" p="12px">
                <PaymentPlanBadge plan={isPremium ? 'pro' : 'basic'} />
                <Text fontSize="12px" fontWeight="500" color="#A7A7A7">
                  {userInfo?.email}
                </Text>
              </Stack>
              <Stack gap="4px" p="0px">
                <Divider color="#D5D5D5" />
                <MenuItem
                  className="chakra-menu-item"
                  as={Link}
                  href={HOW_TO_URL}
                  onClick={onClickGetStarted}
                  isExternal
                >
                  <FiBookOpen />
                  <p>Get Started</p>
                </MenuItem>
                <MenuItem
                  className="chakra-menu-item"
                  as={Link}
                  href={FEEDBACK_FORM_URL}
                  onClick={onClickShareFeedback}
                  isExternal
                >
                  <MdOutlineThumbsUpDown />
                  <p>Share Feedback</p>
                </MenuItem>
                <MenuItem className="chakra-menu-item" onClick={onClickMyPlan}>
                  <PlansSVG />
                  <p>Account & Billing</p>
                </MenuItem>
                <MenuItem className="chakra-menu-item" onClick={onClickSettings}>
                  <CiSettings strokeWidth="0.3px" />
                  <p>Settings</p>
                </MenuItem>
                <Divider color="#D5D5D5" />
                <MenuItem className="chakra-menu-item" onClick={onClickSignOut}>
                  <GoSignOut strokeWidth="0.3px" />
                  <p>Sign Out</p>
                </MenuItem>
              </Stack>
            </Box>
          </MenuList>
        </Menu>
        <span
          style={{
            height: '100%',
            width: '24px',
            flex: '0 1 auto',
          }}
        />
      </ChakraMenuContainer>
    </SubNavContainer>
  )
}

export default SubNavButtons
