import React, { FC } from 'react'
import styled from 'styled-components'
import { Flex, Text, Box, Spinner, Icon, ModalCloseButton } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { MdOutlineFolder } from 'react-icons/md'
import { ProjectType } from '../../../models/saved_sessions.types'
import { css } from '@emotion/css'

const modalCloseButtonClass = css`
  &&& {
    overflow: visible;
    top: 23px;
    right: 16px;

    svg {
      width: 14px;
      height: 14px;
      color: #585858;
    }
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  gap: 4px;
  border-radius: 16px;
  background: #fff;
  box-shadow:
    0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
`

const MyMenuOption = styled.div`
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  & > div.main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-with-icon {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    h2 {
      color: #585858;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
    }

    p {
      color: #a7a7a7;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      color: #585858;
    }
  }

  &:hover {
    background: #ebebeb;

    & > div.main-container {
      h2,
      svg {
        color: #000;
      }
    }
  }
`

interface Props {
  actionText: string
  itemTitle: string
  locationIcon: IconType
  locationText: string
  actionSubtext: string
  areProjectsLoading: boolean
  projects?: ProjectType[]
  onClickMoveToProject: (params: { project: ProjectType }) => void
  isLoading: boolean
}

const MoveItemMenuView: FC<Props> = (props) => {
  const {
    actionText,
    itemTitle,
    locationIcon,
    locationText,
    actionSubtext,
    areProjectsLoading,
    projects,
    onClickMoveToProject,
    isLoading,
  } = props

  if (isLoading) {
    return (
      <Container>
        <Flex w="100%" h="100%" flexGrow={1} alignItems="center" justifyContent="center">
          <Spinner color="blue.500" size="lg" speed="0.5s" />
        </Flex>
      </Container>
    )
  }

  return (
    <Container>
      <Flex
        alignItems="center"
        w="calc(100% - 32px)"
        p="10px"
        fontSize="16px"
        fontWeight="600"
        color="#000"
      >
        <Text as="h2" className="text-inherit" flexShrink={0}>
          {`${actionText} "`}
        </Text>
        <Text as="h2" className="text-inherit is-ellipsis-1" flexShrink={1}>
          {`${itemTitle}`}
        </Text>
        <Text as="h2" className="text-inherit" flexShrink={0}>
          {`"`}
        </Text>
        <ModalCloseButton
          className={modalCloseButtonClass}
          autoFocus={false}
          flexShrink={0}
          tabIndex={-1}
        />
      </Flex>

      <Flex
        p="8px 10px 12px 10px"
        color="#A7A7A7"
        fontSize="12px"
        fontWeight="500"
        lineHeight="16px"
      >
        <Text className="text-inherit" flexShrink={0} mr="8px">{`Current location: `}</Text>
        <Icon as={locationIcon} flexShrink={0} mr={1} color="#A7A7A7" />
        <Text className="text-inherit is-ellipsis-1" flexShrink={1}>
          {locationText}
        </Text>
      </Flex>

      <Text p="8px 10px" color="#A7A7A7" fontSize="12px" fontWeight="500" lineHeight="16px">
        {actionSubtext}
      </Text>

      <Box
        className="scrollbars-always-visible"
        flexDirection="column"
        width="100%"
        gap="4px"
        overflow="auto"
      >
        {areProjectsLoading && (
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Spinner color="blue.500" size="lg" speed="0.5s" />
          </Flex>
        )}
        {!areProjectsLoading &&
          projects?.map((project) => (
            <MyMenuOption key={project.id} onClick={() => onClickMoveToProject({ project })}>
              <div className="main-container">
                <div className="title-with-icon">
                  <MdOutlineFolder />
                  <h2>{project.title}</h2>
                </div>
              </div>
            </MyMenuOption>
          ))}
      </Box>
    </Container>
  )
}

export default MoveItemMenuView
