import React, { FC, useState } from 'react'
import EditableProjectTitle from './EditableProjectTitle'
import StaticProjectTitle from './StaticProjectTitle'
import { useUserContext } from '../../../../contexts/UserContext'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../../extension/models/messaging.types'
import { sendMessageToExtension } from '../../../../webapp/utils/externalMessaging'

interface Props {
  id: string
  title: string
  urls: string[]
  isFocusedFromCreation: boolean
  renameProject: (params: { id: string; title: string }) => Promise<void>
  isProjectSelected: boolean
}

const ProjectTitle: FC<Props> = (props) => {
  const { id, title, urls, isFocusedFromCreation, renameProject, isProjectSelected } = props
  const { captureAnalytics } = useUserContext()
  const [isRenameActive, setIsRenameActive] = useState<boolean>(isFocusedFromCreation)

  const handleClickOpenIcon = async (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation()

    captureAnalytics('projects_list:project_open_icon_click', {
      id,
      title,
      numTabs: urls.length,
    })

    await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
      urls,
    })
  }

  const handleCancelRename = (newTitle: string) => {
    captureAnalytics('projects_list:project_title_rename_cancel_click', {
      numTabs: urls.length,
      id,
      oldTitle: title,
      newTitle,
    })

    setIsRenameActive(false)
  }

  const handleSaveRename = async (newTitle: string) => {
    if (newTitle === '' || title === newTitle) {
      handleCancelRename(newTitle)
      return
    }

    captureAnalytics('projects_list:project_title_rename_save_click', {
      numTabs: urls.length,
      id,
      oldTitle: title,
      newTitle,
    })

    await renameProject({ id, title: newTitle })

    setIsRenameActive(false)
  }

  return isRenameActive ? (
    <EditableProjectTitle
      title={title}
      handleSaveRename={handleSaveRename}
      handleCancelRename={handleCancelRename}
    />
  ) : (
    <StaticProjectTitle
      title={title}
      handleClickOpenIcon={handleClickOpenIcon}
      isProjectSelected={isProjectSelected}
    />
  )
}

export default ProjectTitle
