import React, { FC, useEffect } from 'react'
import { useGetCheckoutSessionQuery } from '../../../redux/services/skeema/payment.endpoints.ts'
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
  ModalFooter,
  Button,
  Heading,
  Box,
  Link,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { modalCloseButtonClass } from './PaymentFlowModal'
import PaymentPlanBadge from './PaymentPlanBadge'
import { APP_NAME, HOW_TO_URL } from '../../../constants'

interface Props {
  sessionId: string
  onClickDone: () => void
  onCheckoutStatusError: (data: unknown) => void
}

const CheckoutCompleteModalContent: FC<Props> = (props) => {
  const { sessionId, onClickDone, onCheckoutStatusError } = props
  const navigate = useNavigate()

  const { data } = useGetCheckoutSessionQuery({ sessionId })

  useEffect(() => {
    if (!data) {
      return
    }

    const isCheckoutComplete = data.status === 'complete'
    if (!isCheckoutComplete) {
      console.log('CHECKOUT NOT COMPLETE')
      onCheckoutStatusError(data)
    }
  }, [data, navigate, onCheckoutStatusError])

  return (
    <ModalContent p="32px" borderRadius="16px" width="475px">
      <ModalHeader p="0" mb="16px">
        <Heading fontSize="20px" fontWeight={600} color="#000" lineHeight="28px">
          Checkout Complete
        </Heading>
      </ModalHeader>
      <ModalCloseButton className={modalCloseButtonClass} />

      <ModalBody p="0" mb="32px" overflow="hidden">
        <Flex alignItems="center" gap="28px">
          <PaymentPlanBadge plan="pro" size="lg" />
          <Box maxW="280px">
            <Text fontSize="16px" color="#000" lineHeight="24px">
              {`Enjoy ${APP_NAME} Pro!`}
            </Text>
            <Text fontSize="16px" color="#000" lineHeight="24px">
              Visit our{' '}
              <Link
                href={HOW_TO_URL}
                color="#0071E3"
                _hover={{ color: '#2988E7', textDecoration: 'underline' }}
              >
                Get Started Guide
              </Link>{' '}
              to learn how to make the most of your premium features.
            </Text>
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter p="0">
        <Button
          bg="black"
          color="white"
          borderRadius="100px"
          height="36px"
          _hover={{
            bg: '#585858',
          }}
          ml="auto"
          onClick={onClickDone}
        >
          Done
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default CheckoutCompleteModalContent
