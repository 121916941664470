import React, { FC, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { AiOutlineChrome } from 'react-icons/ai'
import { TiPin, TiPinOutline } from 'react-icons/ti'
import { Box, Checkbox, Flex, Icon, Tooltip } from '@chakra-ui/react'
import { MdClose, MdKeyboardReturn, MdOutlineDriveFileMove, MdOutlineFolder } from 'react-icons/md'
import { getLocalDateTimeString, getRelativeTimeString } from '../../../utils/utils'
import { FiDownload, FiEdit2 } from 'react-icons/fi'
import { cx } from '@emotion/css'
import DragIcon from '../../../components/DragIcon'
import { TABLIST_PAGE_ENUM } from '../../../models/tablist_pages.types'
import { MdOutlineInbox } from 'react-icons/md'
import { MdOutlineViewAgenda } from 'react-icons/md'
import { FaCircle } from 'react-icons/fa'

export const HIGHLIGHT_ANIMATION_DURATION_MS = 3800 //keeps in sync with the 3000ms toast duration
const highlightAnimation = keyframes`
  0% {
    background: transparent;
  }
  30% {
    background: rgba(0, 113, 227, 0.2);
  }
  70% {
    background: rgba(0, 113, 227, 0.2);
  }
  100% {
    background: transparent;
  }
`

const TabBodyContainer = styled.div<{
  $isFocused: boolean
  $isDraggingThis: boolean
  $isDraggingDisabled: boolean
  $isTitleClickDisabled: boolean
  $isHoverDisabled: boolean
  $isGrayedOut: boolean
  $isHighlighted: boolean
  $spacingVariant: 'default' | 'saved_for_later'
}>`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;

  border-radius: ${({ $isHighlighted }) => ($isHighlighted ? '0px' : '6px')};
  border: 1px solid transparent;
  opacity: 1;
  z-index: ${({ $isFocused }) => ($isFocused ? 11 : 10)};

  overflow: hidden;

  opacity: ${({ $isDraggingThis, $isGrayedOut }) => ($isDraggingThis || $isGrayedOut ? 0.5 : 1)};

  cursor: ${({ $isDraggingDisabled, $isDraggingThis }) =>
    $isDraggingThis ? 'grabbing' : $isDraggingDisabled ? 'auto' : 'grab'}!important;

  & .hover-show:not(.is-focused) {
    opacity: 0;
    transition:
      width 0.2s,
      opacity 0.2s;
  }

  ${({ $isHoverDisabled, $isFocused, $isDraggingDisabled, $isDraggingThis, $isGrayedOut }) =>
    !$isHoverDisabled &&
    css`
      &:hover,
      &.forceHoverStates {
        ${!$isDraggingThis && $isGrayedOut
          ? css`
              opacity: 1;
            `
          : ''};
        background: ${$isFocused ? 'rgba(0, 113, 227, 0.2)' : '#EBEBEB'};
        border-color: #d5d5d5;

        .hover-show {
          opacity: 1;
        }

        .DragIcon {
          opacity: ${$isDraggingDisabled ? 0 : 1};
        }
      }
    `}

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      outline: 1px solid #0071e3;
      background: rgba(0, 113, 227, 0.2);
    `}

  /* Wrapping elements in spans to create larger click targets */
  & > span {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .TabBodyContainerIcon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 1;

    & > span {
      position: relative; //Need for children showOnHover/hideOnHover to be properly centered
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  .TabBodyContainerTitle {
    flex-grow: 2;
    min-width: 0;
    margin-right: 16px;

    & > a {
      max-width: 90%;
    }

    p.Title {
      cursor: ${({ $isTitleClickDisabled }) => ($isTitleClickDisabled ? 'default' : 'pointer')};
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #585858;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */

      & > mark {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-decoration: inherit;
        padding: 0;
        background-color: #585858;
        color: white;
      }

      &:hover:not(.disabled) {
        text-decoration: underline;
      }
    }
  }

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      animation: ${highlightAnimation} ${HIGHLIGHT_ANIMATION_DURATION_MS}ms
        cubic-bezier(0.4, 0, 0.2, 1) forwards;
      .TabBodyContainerTitle {
        p.Title {
          color: #000;
        }
      }
    `}

  .TabBodyContainerProperty {
    flex-shrink: 0;

    ${({ $spacingVariant }) =>
      $spacingVariant === 'saved_for_later'
        ? css`
            min-width: 108px;
            width: 108px;
          `
        : css`
            min-width: 140px;
            width: 140px;
          `}

    overflow: hidden;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #a7a7a7;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .TabBodyContainerActionIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${({ $spacingVariant }) =>
      $spacingVariant === 'saved_for_later'
        ? css`
            flex-shrink: 0;
          `
        : css`
            min-width: 84px;
          `}
  }
`

const ReturnIconContainer = styled.div`
  width: 24px;
  height: 16px;
  padding: 2px 4px;
  margin-right: 6px;
  border-radius: 4px;
  background: #0071e3;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 100%;
    color: white;
  }
`

const DndIndicatorLine = styled.div<{ $isVisible: boolean; $isTop: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  position: absolute;
  width: calc(100% - 26px);
  height: 2px;
  top: ${({ $isTop }) => ($isTop ? '-1px' : '27px')};
  right: 12px;
  background: #0071e3;
  pointer-events: none;
  z-index: 11;
`

function highlightText(text: string, indexMatches?: [number, number][]): ReactNode[] {
  if (!indexMatches || indexMatches.length === 0) {
    return [<React.Fragment key={1}>{text}</React.Fragment>]
  }

  const indices = [...indexMatches].sort((a, b) => a[0] - b[0])
  let lastEnd = 0
  const result: ReactNode[] = []

  for (let i = 0; i < indices.length; i++) {
    const start = indices[i][0]
    const end = Math.min(indices[i][1] + 1, text.length)

    result.push(text.slice(lastEnd, start))

    const marked = <mark key={i}>{text.slice(start, end)}</mark>
    result.push(marked)

    lastEnd = end
  }

  result.push(text.slice(lastEnd))

  return result
}

const getLocationIcon = (entityType: TABLIST_PAGE_ENUM) => {
  switch (entityType) {
    case TABLIST_PAGE_ENUM.OPEN:
      return <Icon as={FaCircle} boxSize={2} color="green" ml={1} mr={2} />
    case TABLIST_PAGE_ENUM.RECENTLY_USED:
      return <Icon as={MdOutlineInbox} mr={1} color="#A7A7A7" />
    case TABLIST_PAGE_ENUM.PROJECT_PAGE:
      return <Icon as={MdOutlineFolder} mr={1} color="#A7A7A7" />
    case TABLIST_PAGE_ENUM.SMART_SESSION:
      return <Icon as={MdOutlineViewAgenda} mr={1} color="#A7A7A7" />
  }
}

interface PropTypes {
  title: string
  location?: string
  entityType: TABLIST_PAGE_ENUM
  favIconUrl?: string
  lastAccessDateTime: string
  handleClickFavIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickDragIcon: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickDeleteIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickMoveIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickSaveIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickTitle: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickCheckbox?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  handleClickBody: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  handleClickEditIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  tabContainerRef?: React.MutableRefObject<HTMLDivElement | null>
  connectDnD?: (node: HTMLDivElement | null) => void
  titleHighlights?: [number, number][]
  isDraggingThis: boolean
  isDraggingDisabled: boolean
  isChecked: boolean
  isCheckboxShown: boolean
  isSelectionDisabled: boolean
  isHoverDisabled: boolean
  isSelectionDisabledTooltipLabel?: string
  isTitleClickDisabled: boolean
  isTitleEditable?: boolean
  showTimeString: boolean
  showActionIcons: boolean
  isFocused: boolean
  spacingVariant: 'default' | 'saved_for_later'
  isDragIconPlaceholderHidden: boolean
  isHighlighted?: boolean
  showOpenTabIcon?: boolean
  isPinnedTab?: boolean
  titleToolTip?: string | ReactNode
  deleteIconToolTip?: string
  saveIconTooltip?: string
  moveIconTooltip?: string
  shouldShowPinIconForCheckbox?: boolean
  shouldDisplayLocation?: boolean
  dragOverLocation?: 'top' | 'bottom'
  shouldForceHighlightTop?: boolean
  shouldForceHighlightBottom?: boolean
}

const PutasideTabView: FC<PropTypes> = (props) => {
  const {
    title,
    location,
    entityType,
    favIconUrl,
    lastAccessDateTime,
    handleClickFavIcon,
    handleClickDragIcon,
    handleClickDeleteIcon,
    handleClickSaveIcon,
    handleClickTitle,
    handleClickEditIcon,
    handleClickCheckbox,
    handleClickBody,
    tabContainerRef,
    connectDnD,
    isDraggingThis,
    isDraggingDisabled,
    isChecked,
    isCheckboxShown,
    isSelectionDisabled,
    isHoverDisabled,
    isSelectionDisabledTooltipLabel,
    isTitleClickDisabled,
    isTitleEditable,
    showTimeString,
    showActionIcons,
    titleHighlights,
    isFocused,
    spacingVariant,
    showOpenTabIcon,
    isPinnedTab,
    titleToolTip,
    deleteIconToolTip,
    saveIconTooltip,
    shouldShowPinIconForCheckbox,
    shouldDisplayLocation,
    handleClickMoveIcon,
    moveIconTooltip,
    dragOverLocation,
    shouldForceHighlightTop,
    shouldForceHighlightBottom,
    isDragIconPlaceholderHidden,
    isHighlighted,
  } = props
  const shouldHighlightTop = shouldForceHighlightTop || dragOverLocation === 'top'
  const shouldHighlightBottom = shouldForceHighlightBottom || dragOverLocation === 'bottom'

  return (
    <TabBodyContainer
      className="TabBodyContainer"
      ref={(node) => {
        if (tabContainerRef) {
          tabContainerRef.current = node
        }
        connectDnD && connectDnD(node)
      }}
      $isFocused={isFocused}
      $isDraggingThis={isDraggingThis}
      $isDraggingDisabled={isDraggingDisabled}
      $isTitleClickDisabled={isTitleClickDisabled}
      $isHoverDisabled={isHoverDisabled}
      $isGrayedOut={!!shouldShowPinIconForCheckbox && !isChecked}
      $spacingVariant={spacingVariant}
      $isHighlighted={!!isHighlighted}
      onClick={handleClickBody}
    >
      <DndIndicatorLine
        $isVisible={shouldHighlightTop || shouldHighlightBottom}
        $isTop={shouldHighlightTop}
      />
      {!isDragIconPlaceholderHidden && (
        <Flex
          as="span"
          className={cx('DragIcon', 'hover-show', {
            'is-focused': isFocused,
          })}
          alignItems="center"
          justifyContent="center"
          width={{ base: '8px', md: '16px' }}
          marginRight="-4px"
          flexShrink={0}
          overflow="hidden"
          onClick={handleClickDragIcon}
        >
          <DragIcon />
        </Flex>
      )}
      {isCheckboxShown && handleClickCheckbox && (
        <Tooltip
          placement="top"
          isDisabled={!isSelectionDisabled || !isSelectionDisabledTooltipLabel}
          label={isSelectionDisabledTooltipLabel}
          bg="#585858"
        >
          <Box
            className={cx('TabBodyContainerIcon', { clickable: !isSelectionDisabled })}
            mr="4px"
            onClick={handleClickCheckbox}
          >
            {!shouldShowPinIconForCheckbox && (
              <Checkbox
                colorScheme="black"
                size="md"
                isChecked={isChecked}
                isDisabled={isSelectionDisabled}
                isReadOnly
                pointerEvents="none"
              />
            )}
            {shouldShowPinIconForCheckbox && (
              <>
                {isChecked && <TiPin strokeWidth={'2px'} color="#000" />}
                {!isChecked && <TiPinOutline color="#000" />}
              </>
            )}
          </Box>
        </Tooltip>
      )}

      <span className="TabBodyContainerIcon" onClick={handleClickFavIcon}>
        {favIconUrl ? (
          <img src={favIconUrl} width="16" height="16" />
        ) : (
          <AiOutlineChrome style={{ width: '16px', height: '16px', color: 'black' }} />
        )}
      </span>

      <span
        className="TabBodyContainerTitle"
        onClick={isTitleClickDisabled ? undefined : handleClickTitle}
      >
        <Tooltip
          openDelay={300}
          placement="top"
          label={titleToolTip}
          isDisabled={isTitleClickDisabled}
        >
          <p className={cx('Title', { disabled: isTitleClickDisabled })}>
            {highlightText(title, titleHighlights)}
          </p>
        </Tooltip>

        {isTitleEditable && (
          <Tooltip placement="top" label="Rename title">
            <span
              className="TabBodyContainerIcon hover-show clickable"
              onClick={handleClickEditIcon}
            >
              <FiEdit2 color="#585858" size={16} style={{ marginLeft: '5px', flexShrink: 0 }} />
            </span>
          </Tooltip>
        )}

        {isPinnedTab && (
          <TiPin color="#A7A7A7" size={16} style={{ marginLeft: '5px', flexShrink: 0 }} />
        )}
        {!isPinnedTab && showOpenTabIcon && (
          <span
            title="This tab is open"
            style={{
              marginLeft: '8px',
              flexShrink: 0,
              width: '8px',
              height: '8px',
              borderRadius: '2px',
              backgroundColor: '#0c894e',
            }}
          />
        )}
      </span>

      {shouldDisplayLocation && (
        <span className="TabBodyContainerProperty">
          {getLocationIcon(entityType)}
          <p className="Location">{location}</p>
        </span>
      )}

      {showTimeString && lastAccessDateTime.trim() !== '' && (
        <span className="TabBodyContainerProperty">
          <p className="Time" title={getLocalDateTimeString(lastAccessDateTime)}>
            {getRelativeTimeString(lastAccessDateTime)}
          </p>
        </span>
      )}

      {showActionIcons && (
        <span className="TabBodyContainerActionIcons">
          {!handleClickDeleteIcon && (
            <span style={{ width: '32px' }} className="TabBodyContainerIcon" />
          )}

          {handleClickDeleteIcon && (
            <Tooltip placement="top" label={deleteIconToolTip}>
              <span
                style={{
                  width: '24px',
                  marginRight: handleClickSaveIcon || handleClickMoveIcon ? '0' : '4px',
                }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickDeleteIcon}
              >
                <MdClose
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {handleClickSaveIcon && (
            <Tooltip placement="top" label={saveIconTooltip}>
              <span
                style={{ width: '24px', marginRight: handleClickMoveIcon ? '0' : '4px' }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickSaveIcon}
              >
                <FiDownload
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {handleClickMoveIcon && (
            <Tooltip placement="top" label={moveIconTooltip}>
              <span
                style={{ width: '24px', marginRight: '4px' }}
                className="TabBodyContainerIcon hover-show clickable"
                onClick={handleClickMoveIcon}
              >
                <MdOutlineDriveFileMove
                  style={{
                    width: '16px',
                    height: '16px',
                    color: isFocused ? '#0071e3' : '#585858',
                  }}
                />
              </span>
            </Tooltip>
          )}

          {isFocused && (
            <Tooltip placement="top" label={'Press Enter to open'}>
              <ReturnIconContainer>
                <MdKeyboardReturn />
              </ReturnIconContainer>
            </Tooltip>
          )}
        </span>
      )}
    </TabBodyContainer>
  )
}

export default PutasideTabView
