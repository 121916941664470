import { createSlice } from '@reduxjs/toolkit'
import { SmartSessionType } from '../models/smart_sessions.types'
import { ReduxStateType } from './baseStore'
import { TablistPageType } from '../models/tablist_pages.types'

export interface SessionSliceStateType {
  sessions: SmartSessionType[] | undefined
}

const initialState: SessionSliceStateType = {
  sessions: undefined,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessions: (
      state,
      action: {
        payload: SmartSessionType[]
      },
    ) => {
      state.sessions = action.payload
    },
    appendSessions: (
      state,
      action: {
        payload: SmartSessionType[]
      },
    ) => {
      state.sessions = state.sessions ? state.sessions.concat(action.payload) : action.payload
    },
    addSession: (
      state,
      action: {
        payload: SmartSessionType
      },
    ) => {
      if (!state.sessions) {
        state.sessions = [action.payload]
      } else {
        const newSession = action.payload
        const insertIndex = state.sessions.findIndex(
          (session) => session.datetime_end_ts <= newSession.datetime_end_ts,
        )

        if (insertIndex === -1) {
          state.sessions.push(newSession)
        } else {
          state.sessions.splice(insertIndex, 0, newSession)
        }
      }
    },
    deleteSession: (
      state,
      action: {
        payload: SmartSessionType
      },
    ) => {
      if (!state.sessions) {
        return
      }
      const session = action.payload
      const idx = state.sessions.findIndex((s) => s.id === session.id)
      if (idx !== -1) {
        state.sessions.splice(idx, 1)
      }
    },
    deleteSessionPage: (
      state,
      action: {
        payload: { session_id: number; idx: number }
      },
    ) => {
      const { session_id, idx } = action.payload
      if (state.sessions) {
        const sessionIndex = state.sessions.findIndex((s) => s.id === session_id)
        if (sessionIndex !== -1 && state.sessions[sessionIndex].pages) {
          if (idx >= 0 && idx < state.sessions[sessionIndex].pages.length) {
            state.sessions[sessionIndex].pages.splice(idx, 1)
          }
        }
      }
    },
    addSessionPage: (
      state,
      action: {
        payload: { session_id: number; page: TablistPageType; idx: number }
      },
    ) => {
      const { session_id, page, idx } = action.payload
      if (state.sessions) {
        const sessionIndex = state.sessions.findIndex((s) => s.id === session_id)
        if (sessionIndex !== -1 && state.sessions[sessionIndex].pages) {
          if (idx >= 0 && idx <= state.sessions[sessionIndex].pages.length) {
            state.sessions[sessionIndex].pages.splice(idx, 0, page)
          }
        }
      }
    },
  },
})

export const selectSessionSessions = (state: ReduxStateType) => state.session.sessions

export const {
  setSessions,
  appendSessions,
  addSession,
  deleteSession,
  deleteSessionPage,
  addSessionPage,
} = sessionSlice.actions

export default sessionSlice.reducer
