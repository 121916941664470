import React, { FC, useCallback, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useGetOpenTabsQuery } from '../../../webapp/redux/extension'
import { Text, Box, Spinner } from '@chakra-ui/react'
import PutasideTabController from '../PutasideTabList/PutasideTabController'
import { TABLIST_AREA_NAME_ENUM } from '../PutasideTabList/PutasideTabList'
import { TablistPageType } from '../../../models/tablist_pages.types'
import {
  GLOBAL_WINDOW_MESSAGE_ENUM,
  onGlobalWindowMessage,
  sendGlobalWindowMessage,
} from '../../../utils/utils'
import { sendMessageToExtension } from '../../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { useUserContext } from '../../../contexts/UserContext'

export interface SelectedTabsType {
  [id: string]: TablistPageType
}

const SectionContainer = styled.div`
  margin-bottom: 16px;
`

const TableHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 16px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    color: #0071e3;
    line-height: 22px; /* 157.143% */
  }
`

const PinningTablist: FC = () => {
  const { captureAnalytics } = useUserContext()
  const [selectedTabs, setSelectedTabs] = useState<SelectedTabsType | undefined>(undefined)
  const [initPinnedTabs, setInitPinnedTabs] = useState<TablistPageType[]>([])
  const [lastSelectedItem, setLastSelectedItem] = useState<string | undefined>(undefined)

  const { data: tabs, isLoading } = useGetOpenTabsQuery(undefined, { pollingInterval: 1000 })

  useEffect(() => {
    if (!tabs) {
      return
    }

    const initSelectedTabs = tabs.reduce((selectedTabs, t) => {
      if (t.is_pinned) {
        selectedTabs[t.id] = t
      }
      return selectedTabs
    }, {} as SelectedTabsType)

    setInitPinnedTabs((prev) => {
      if (!prev) {
        return Object.values(initSelectedTabs)
      }
      return prev
    })
    //Add already pinned tabs to selected state
    setSelectedTabs((prev) => {
      if (!prev) {
        return initSelectedTabs
      }
      return prev
    })
  }, [tabs])

  useEffect(() => {
    if (!tabs) {
      return
    }

    return onGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SUBMIT_PIN_TABS, () => {
      const tabIdsToPin = Object.keys(selectedTabs ?? {}).map((k) => parseInt(k))
      const tabIdsToUnpin = tabs
        .map((t) => parseInt(t.id))
        .filter((id) => !tabIdsToPin.includes(id))

      const promises: Promise<boolean>[] = []
      promises.push(
        sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.PIN_TABS, {
          tabIds: tabIdsToPin,
        }).then((res) => {
          return (res ?? true) as boolean
        }),
      )

      promises.push(
        new Promise<boolean>((resolve, reject) => {
          //Allows backwards compatibility with extension versions before "UNPIN_TABS" command
          const timeout = setTimeout(() => {
            reject(new Error('Promise timed out'))
          }, 500)

          sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UNPIN_TABS, {
            tabIds: tabIdsToUnpin,
          })
            .then((res) => {
              clearTimeout(timeout)
              resolve((res ?? true) as boolean)
            })
            .catch((e) => {
              clearTimeout(timeout)
              console.log('Error unpinning tabs', e)
              resolve(false)
            })
        })
          .then((res) => {
            return res
          })
          .catch((e) => {
            console.log('Error unpinning tabs', e)
            return false
          }),
      )

      const pinned = tabs?.filter((t) => !t.is_pinned && tabIdsToPin.includes(parseInt(t.id)))
      const unpinned = tabs?.filter((t) => t.is_pinned && tabIdsToUnpin.includes(parseInt(t.id)))
      const initialPinnedTitles = initPinnedTabs.map((t) => t.title)
      const pinnedTitles = pinned.map((t) => t.title)
      const unpinnedTitles = unpinned.map((t) => t.title)
      const didPin = pinned.length > initPinnedTabs.length
      const didUnpin = pinned.length < initPinnedTabs.length
      const numPinned = pinned.length
      const numInitialPinned = initPinnedTabs.length

      void Promise.all(promises).then((res) => {
        const errorPinning = res[0]
        const errorUnpinning = res[1]
        captureAnalytics('onboarding_tutorial:automatic_pin_tabs', {
          initialPinnedTitles,
          pinnedTitles,
          unpinnedTitles,
          didPin,
          didUnpin,
          numPinned,
          numInitialPinned,
          errorPinning,
          errorUnpinning,
        })
        sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.PIN_TABS_SUCCESS, {
          didPin: didPin || didUnpin,
        })
      })
    })
  }, [captureAnalytics, initPinnedTabs, selectedTabs, tabs])

  const handleTabSelection = useCallback(
    (tabId: string, selected: boolean, shiftKey: boolean): void => {
      if (!tabs) {
        return
      }

      if (selected) {
        const tabIdx = tabs.findIndex((t) => t.id === tabId)
        const tab = tabIdx > -1 ? tabs[tabIdx] : undefined
        if (tab === undefined || !tab.id) {
          return
        }

        const addedTabs: SelectedTabsType = {}

        if (shiftKey && lastSelectedItem !== undefined) {
          const lastTabIdx = tabs.findIndex((t) => t.id === lastSelectedItem)
          if (lastTabIdx > -1) {
            const start = Math.min(tabIdx, lastTabIdx)
            const end = Math.max(tabIdx, lastTabIdx)
            for (let i = start; i <= end; i++) {
              const curr = tabs[i]
              if (curr.id) {
                addedTabs[curr.id] = curr
              }
            }
          }
        } else {
          addedTabs[tab.id] = tab
        }

        setSelectedTabs((curr) => {
          const newObject = { ...curr, ...addedTabs }
          return newObject
        })
      } else {
        const tabIdsToDeselect: Set<string> = new Set([tabId])

        if (shiftKey && lastSelectedItem !== undefined) {
          const tabIdx = tabs.findIndex((t) => t.id === tabId)
          const lastTabIdx = tabs.findIndex((t) => t.id === lastSelectedItem)
          if (tabIdx > -1 && lastTabIdx > -1) {
            const start = Math.min(tabIdx, lastTabIdx)
            const end = Math.max(tabIdx, lastTabIdx)
            for (let i = start; i <= end; i++) {
              const curr = tabs[i]
              if (curr.id) {
                tabIdsToDeselect.add(curr.id)
              }
            }
          }
        }

        setSelectedTabs((curr) => {
          const newObject = { ...curr }
          tabIdsToDeselect.forEach((t) => {
            delete newObject[t]
          })
          return newObject
        })
      }

      captureAnalytics('onboarding_tutorial:pin_tab_click', {
        selected,
        tabId,
        title: tabs.find((t) => t.id === tabId)?.title,
      })

      setLastSelectedItem(tabId)
    },
    [captureAnalytics, lastSelectedItem, tabs],
  )

  return (
    <Box
      width="100%"
      height="100%"
      mb="400px" // Allows scroll to prevent the onboarding dialog from blocking the tablist
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box p="24px 8px 24px 16px">
        {tabs && tabs.length === 0 && (
          <Box className="flex-center" mt="6px" w="100%">
            <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
              {`Wow! Your browser is already clean. We'll help you keep it that way.`}
            </Text>
          </Box>
        )}
        {tabs && tabs.length > 0 && (
          <SectionContainer>
            <TableHeaderTitleContainer>
              <h2>Click the tabs below to pin or unpin them</h2>
            </TableHeaderTitleContainer>

            {isLoading && (
              <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
                <Spinner color="blue.500" size="lg" speed="1s" />
              </div>
            )}

            <div>
              {tabs.map((t, idx) => {
                return (
                  <PutasideTabController
                    key={t.id}
                    id={t.id}
                    page={t}
                    showTimeString={false}
                    queryValue={''}
                    index={idx}
                    numTotalResults={tabs.length}
                    areaName={TABLIST_AREA_NAME_ENUM.Open}
                    isDraggingDisabled={true}
                    isChecked={t.id in (selectedTabs ?? {})}
                    onSelected={handleTabSelection}
                    isCheckboxShown={true}
                    shouldShowPinIconForCheckbox={true}
                    isTitleClickDisabled={true}
                  />
                )
              })}
            </div>
          </SectionContainer>
        )}
      </Box>
    </Box>
  )
}

export default PinningTablist
