import { EXTENSION_ID } from '../../../../constants'
import {
  BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  MESSAGE_AGENTS,
} from '../../../models/messaging.types'

export async function sendMessageToExtension(
  action: BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS,
  payload?: unknown,
): Promise<unknown> {
  return chrome?.runtime?.sendMessage(EXTENSION_ID, {
    to: MESSAGE_AGENTS.BACKGROUND,
    from: MESSAGE_AGENTS.POPUP,
    action,
    payload,
  })
}
