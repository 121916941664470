import React, { FC } from 'react'
import { DnDItemProjectPayload } from '../../../models/dnd.types'
import { styled } from 'styled-components'
import DragIcon from '../../../components/DragIcon'

const Container = styled.div`
  position: relative;
  width: 272px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border-radius: 14px;
  cursor: pointer;
  box-sizing: border-box;

  background: #fff;
  opacity: 0.7;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
`

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  & > h2 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
  }
`

interface Props {
  item: DnDItemProjectPayload
}

const ProjectDragPreview: FC<Props> = (props) => {
  const { item } = props

  return (
    <Container>
      <TitleContainer>
        <span style={{ width: '12px', marginRight: '8px' }} className="hover-show">
          <DragIcon />
        </span>
        <h2>{item.project.title}</h2>
      </TitleContainer>
    </Container>
  )
}

export default ProjectDragPreview
