export enum MESSAGE_AGENTS {
  BACKGROUND = 'BACKGROUND',
  CONTENT = 'CONTENT',
  DEVTOOLS = 'DEVTOOLS',
  OPTIONS = 'OPTIONS',
  POPUP = 'POPUP',
  SIDEPANEL = 'SIDEPANEL',
  MAIN_PAGE = 'MAIN_PAGE',
  // NEWTAB = 'NEWTAB' // MAIN_PAGE is used instead
  // WEBAPP = 'WEBAPP' // MAIN_PAGE is used instead
}

export enum BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS {
  POST_USER_EVENT_UPDATE = 'POST_USER_EVENT_UPDATE',
  GET_SENDER_TAB = 'GET_SENDER_TAB',
  OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL',
  REFRESH_TAB_RECORD = 'REFRESH_TAB_RECORD',
  UPDATE_TAB_LAST_ACTIVE = 'UPDATE_TAB_LAST_ACTIVE',
  GET_UPCOMING_PAGES = 'GET_UPCOMING_PAGES',
  GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS',
  POST_ANALYTICS_EVENT = 'POST_ANALYTICS_EVENT',
  NAVIGATE_TO_URL = 'NAVIGATE_TO_URL',
  PUTASIDE_CURRENT_TAB = 'PUTASIDE_CURRENT_TAB',
  OPEN_PRICING_PAGE = 'OPEN_PRICING_PAGE',
  CLOSE_TABS = 'CLOSE_TABS',
  VALIDATE_AND_CLOSE_TABS = 'VALIDATE_AND_CLOSE_TABS',
  GET_ALL_TABS = 'GET_ALL_TABS',
  GET_ALL_TABRECORDS = 'GET_ALL_TABRECORDS',
  UPDATE_AUTH = 'UPDATE_AUTH',
  GET_EXTENSION_HEALTH = 'GET_EXTENSION_HEALTH',
  GET_TAB_HTML = 'GET_TAB_HTML',
  GET_TAB_GROUP = 'GET_TAB_GROUP',
  GET_TAB_GROUPS = 'GET_TAB_GROUPS',
  PUTAWAY_TABS = 'PUTAWAY_TABS',
  CLOSE_SELF = 'CLOSE_SELF',
  OPEN_TABS = 'OPEN_TABS',
  SWITCH_TO_TAB = 'SWITCH_TO_TAB',
  UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS',
  PIN_TABS = 'PIN_TABS',
  UNPIN_TABS = 'UNPIN_TABS',
  GET_EXTENSION_SETTINGS = 'GET_EXTENSION_SETTINGS',
  SAVE_EXTENSION_SETTINGS = 'SAVE_EXTENSION_SETTINGS',
  BOOTSTRAP = 'BOOTSTRAP',
  UNGROUP_TAB_GROUPS = 'UNGROUP_TAB_GROUPS',
  OPEN_WITHIN_PINNED_TAB = 'OPEN_WITHIN_PINNED_TAB',
  REPLACE_PINNED_TAB = 'REPLACE_PINNED_TAB',
  UPDATE_CHROME_STORAGE = 'UPDATE_CHROME_STORAGE',
  UPDATE_CHROME_STORAGE_ITEM = 'UPDATE_CHROME_STORAGE_ITEM',
  GET_CHROME_STORAGE = 'GET_CHROME_STORAGE',
  GET_IS_ONBOARDING_COMPLETED = 'GET_IS_ONBOARDING_COMPLETED',
  GET_AUTH_TOKEN = 'GET_AUTH_TOKEN',
  SHOW_ON_PAGE_NOTIFICATION = 'SHOW_ON_PAGE_NOTIFICATION',
}

export enum CONTENT_ON_MESSAGE_LISTENER_ACTIONS {
  GET_PAGE_HTML = 'GET_PAGE_HTML',
  SHOW_ON_PAGE_NOTIFICATION = 'SHOW_ON_PAGE_NOTIFICATION',
}

export type ErrorNotificationPayload = {
  type: 'ERROR'
  message: string
  targetTabId?: number
}

export type SavedNotificationPayload = {
  type: 'SAVED'
  message: string
  pageId: string
  projectId?: string
  isSavedToRecentlyUsed: boolean
  targetTabId?: number
}

export type ShowOnPageNotificationPayload = ErrorNotificationPayload | SavedNotificationPayload
