import { skeemaApi } from '../skeema'
import { USER_RESET_ENDPOINT_PATH, UserResetEndpointType } from '../../../models/endpoints.types'

export const paymentApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    resetUserData: builder.mutation<UserResetEndpointType['POST']['response'], { id: number }>({
      query: ({ id }) => ({
        url: `${USER_RESET_ENDPOINT_PATH(String(id))}`,
        method: 'POST',
      }),
    }),
  }),
})

export const { useResetUserDataMutation } = paymentApi
