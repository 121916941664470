import React, { FC, useCallback, useRef, useState } from 'react'
import { UseToastOptions, ToastId, useToast } from '@chakra-ui/react'
import { MdOutlineInbox } from 'react-icons/md'
import {
  useAddProjectPagesMutation,
  useGetActiveProjectsQuery,
} from '../../../redux/services/skeema/projects.endpoints'
import { ProjectPageType, ProjectType } from '../../../models/saved_sessions.types'
import { useUserContext } from '../../../contexts/UserContext'
import { useFeatureFlagContext } from '../../../contexts/FeatureFlagContext'
import { useDeleteTablistPageMutation } from '../../../redux/services/skeema/tablist_pages.endpoints'
import NotificationToast from '../../../components/NotificationToast'
import MoveItemMenuView from './MoveItemMenuView'
import { MoveTablistPageInfoType } from './moveItemMenu.types'

const USE_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  containerStyle: {
    maxWidth: 'none',
    margin: 0,
  },
}

interface Props {
  pageInfo?: MoveTablistPageInfoType
  onSuccess: () => void
}

const MoveTablistItemMenu: FC<Props> = (props) => {
  const { pageInfo, onSuccess } = props
  const { captureAnalytics } = useUserContext()
  const { projectConfig } = useFeatureFlagContext()
  const maxNumProjectPages = projectConfig.maxNumProjectsPages

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const toast = useToast(USE_TOAST_OPTIONS)
  const toastIdRef = useRef<ToastId | undefined>(undefined)

  const { data: projects, isLoading: isProjectsQueryLoading } = useGetActiveProjectsQuery(undefined)
  const [addProjectPagesMutation] = useAddProjectPagesMutation()
  const [deleteTablistPageMutation] = useDeleteTablistPageMutation()

  const handleSaveToProject = useCallback(
    async (params: { projectId: string }): Promise<ProjectPageType[] | undefined> => {
      const { projectId } = params

      if (!pageInfo) {
        console.error('Page info not set')
        return undefined
      }
      const { page } = pageInfo

      const project = projects?.find((project) => project.id === projectId)

      if (!project) {
        console.error('Project not found')
        return undefined
      }

      const tablistPages = [page]

      const pages = await addProjectPagesMutation({
        project,
        tablistPages,
        maxNumProjectPages,
      })
        .then((result) => {
          if ('error' in result) {
            console.error(result.error)
            return undefined
          }
          return result.data
        })
        .catch((e) => {
          console.error(e)
          return undefined
        })

      // Logging analytics below
      const urls = project?.pages?.map((page) => page.url)
      const numNewPages = pages?.length
      const numCurrentPages = urls?.length
      const totalNumPages =
        numNewPages !== undefined && numCurrentPages !== undefined
          ? numCurrentPages + numNewPages
          : undefined
      const pageEntityType = tablistPages.length > 0 ? tablistPages[0].entity_type : null

      captureAnalytics('move_item_menu:save_to_project_exec', {
        newPages: pages,
        numNewPages,
        numCurrentPages,
        totalNumPages,
        urls,
        maxNumProjectPages,
        pageEntityType,
        isError: !pages,
        ...project,
      })

      return pages
    },
    [addProjectPagesMutation, captureAnalytics, maxNumProjectPages, pageInfo, projects],
  )

  const onClickMoveToProject = useCallback(
    async (params: { project: ProjectType }) => {
      const { project } = params
      if (!pageInfo) {
        console.error('Page info not set')
        return
      }
      const { page, index } = pageInfo

      setIsLoading(true)

      const saveResult = await handleSaveToProject({ projectId: project.id })
      let deleteResult: boolean = false

      if (saveResult) {
        const res = await deleteTablistPageMutation({ page, index })
        deleteResult = 'data' in res ? true : false
      }

      setIsLoading(false)

      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
      }
      toastIdRef.current = toast({
        duration: 3000,
        containerStyle: { minWidth: 'auto' },
        render: () => <NotificationToast message={`Tab has been moved to "${project.title}"`} />,
      })

      captureAnalytics('move_item_menu:move_to_project_click', {
        newProjectId: project.id,
        newProjectTitle: project.title,
        saveResult,
        deleteResult,
        page,
      })

      onSuccess()
    },
    [pageInfo, handleSaveToProject, toast, captureAnalytics, onSuccess, deleteTablistPageMutation],
  )

  return (
    <MoveItemMenuView
      actionText={'Move'}
      actionSubtext={'Move to...'}
      itemTitle={pageInfo?.page.title ?? ''}
      locationIcon={MdOutlineInbox}
      locationText={'All saved tabs'}
      areProjectsLoading={isProjectsQueryLoading}
      projects={projects}
      onClickMoveToProject={onClickMoveToProject}
      isLoading={isLoading}
    />
  )
}

export default MoveTablistItemMenu
