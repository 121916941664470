import React, { FC, useEffect, useState } from 'react'
import OnboardingStepTemplate from './OnboardingStepTemplate'
import {
  GLOBAL_WINDOW_MESSAGE_ENUM,
  onGlobalWindowMessage,
  sendGlobalWindowMessage,
} from '../../../utils/utils'
import { APP_NAME } from '../../../constants'

interface Props {
  phaseNum: number
  numPhases: number
  didDoTabGroups: boolean
  onNext: () => void
}
const OnboardingPinTabsDialog: FC<Props> = (props) => {
  const { phaseNum, didDoTabGroups, onNext, numPhases } = props
  const [dialogState, setDialogState] = useState<'INFO' | 'SUCCESS'>('INFO')

  useEffect(() => {
    if (dialogState !== 'SUCCESS') {
      return
    }

    const timeout = setTimeout(() => {
      onNext()
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [dialogState, onNext])

  useEffect(() => {
    return onGlobalWindowMessage(
      GLOBAL_WINDOW_MESSAGE_ENUM.PIN_TABS_SUCCESS,
      ({ didPin }: { didPin: boolean }) => {
        if (didPin) {
          setDialogState('SUCCESS')
        } else {
          onNext()
        }
      },
    )
  }, [onNext])

  const onButtonClick = () => {
    sendGlobalWindowMessage(GLOBAL_WINDOW_MESSAGE_ENUM.SUBMIT_PIN_TABS)
  }

  if (dialogState === 'SUCCESS') {
    return (
      <OnboardingStepTemplate
        phaseNum={phaseNum}
        numPhases={numPhases}
        heading={`Tabs successfully pinned!`}
        height="300px"
      />
    )
  }

  return (
    <OnboardingStepTemplate
      phaseNum={phaseNum}
      numPhases={numPhases}
      heading={
        <>
          {`${didDoTabGroups ? `Now, let's` : `Before we activate Automatic Save,`} pin tabs you `}
          <u
            style={{
              fontSize: 'inherit',
              fontWeight: 500,
              lineHeight: '40px',
              color: '#000',
              letterSpacing: '-1px',
            }}
          >
            always
          </u>{' '}
          want open.
        </>
      }
      description={`Pinned tabs don't get auto-closed by ${APP_NAME}. You can always right-click a tab in the tab bar to Pin or Unpin it.`}
      buttonText={`I've pinned what I want`}
      onButtonClick={onButtonClick}
    />
  )
}
export default OnboardingPinTabsDialog
