import React, { FC, useCallback, useEffect, useState } from 'react'
import { Flex, Text, Button, Spinner, IconButton, Box } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { AIActionLogType } from '../../../models/ai_action_logs.types'
import {
  useGetAIActionLogsQuery,
  useLazyGetAIActionLogsQuery,
} from '../../../redux/services/skeema/feed_history.endpoints'
import HistoryItemCard from './HistoryItemCard'
import SearchResultsReplacementWrapper from '../search/SearchResultsReplacementWrapper'
import { useUserContext } from '../../../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowBack } from 'react-icons/io'
import { css } from '@emotion/css'

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 16px 0px;
  margin-left: 6px;
  gap: 12px;
  cursor: pointer;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
  }
`

const backIconButtonClassName = css`
  & > svg {
    width: 20px;
    height: 20px;
  }
`

const CompletedActionsDashboard: FC = () => {
  const navigate = useNavigate()
  const { captureAnalytics } = useUserContext()
  const [items, setItems] = useState<AIActionLogType[]>([])
  const [nextPage, setNextPage] = useState<number | undefined>(undefined)

  const {
    data: firstPageData,
    isLoading: isFirstPageLoading,
    isSuccess: isFirstPageSuccess,
  } = useGetAIActionLogsQuery({})

  useEffect(() => {
    if (isFirstPageSuccess) {
      setItems(firstPageData?.results || [])
      if (firstPageData?.next) {
        setNextPage(parseInt(firstPageData.next.split('?page=')[1]))
      } else {
        setNextPage(-1)
      }
    }
  }, [isFirstPageSuccess, firstPageData])

  const [loadMoreItems, loadMoreItemsResult] = useLazyGetAIActionLogsQuery({
    refetchOnFocus: false,
  })

  const onClickShowOlder = useCallback(() => {
    loadMoreItems({ page: nextPage })
  }, [loadMoreItems, nextPage])

  useEffect(() => {
    if (loadMoreItemsResult.isSuccess) {
      const newItems = loadMoreItemsResult.data?.results || []
      setItems((prevItems) => {
        return [...prevItems, ...newItems]
      })
      if (loadMoreItemsResult.data?.next) {
        setNextPage(parseInt(loadMoreItemsResult.data.next.split('?page=')[1]))
      } else {
        setNextPage(-1)
      }
    }
  }, [loadMoreItemsResult])

  const handleClickMenuBack = () => {
    captureAnalytics('actions_dashboard:back_click')
    navigate('/')
  }

  return (
    <Flex direction="column" h="100%" w="100%" p="24px 16px" overflow="auto">
      <SearchResultsReplacementWrapper>
        <TableHeaderContainer>
          <IconButton
            className={backIconButtonClassName}
            variant="outline"
            border="none"
            aria-label="Go to home page"
            icon={<IoMdArrowBack />}
            onClick={handleClickMenuBack}
            height="24px"
            width="24px"
            minWidth="24px"
            size="sm"
            flexShrink={0}
            _hover={{
              bg: '#EBEBEB',
            }}
          />
          <h2>Completed actions</h2>
        </TableHeaderContainer>

        {isFirstPageLoading && (
          <div className="flex-center" style={{ width: '100%', margin: '32px' }}>
            <Spinner color="blue.500" size="lg" speed="1s" />
          </div>
        )}
        {isFirstPageSuccess && items.length === 0 && (
          <div className="flex-center" style={{ width: '100%' }}>
            <Text textAlign={'center'} margin={4} fontSize="14px" color="#a7a7a7">
              {`No history to show at the moment.  Check back later!`}
            </Text>
          </div>
        )}
        <Flex flexDirection="column" gap="12px">
          {items.map((action, idx) => (
            <HistoryItemCard key={`${idx}`} action={action} />
          ))}
        </Flex>
        {nextPage !== undefined && (
          <Box mt="8px" w="100%">
            {nextPage > 0 && (
              <Button
                key="extra"
                size="sm"
                fontSize={12}
                fontWeight={500}
                borderRadius={'16px'}
                h="32px"
                minH="32px"
                w="100%"
                px={2}
                onClick={onClickShowOlder}
                bg="#F6F6F6"
                color="#585858"
                _hover={{ bg: '#EBEBEB' }}
                isLoading={loadMoreItemsResult.isLoading}
              >
                {`Show older`}
              </Button>
            )}
            {nextPage === -1 && items && items.length > 0 && (
              <Text
                fontSize={12}
                fontWeight={500}
                mt="8px"
                w="100%"
                px={2}
                color="#A7A7A7"
                textAlign="center"
              >
                {`You've reached the end of your history`}
              </Text>
            )}
          </Box>
        )}
      </SearchResultsReplacementWrapper>
    </Flex>
  )
}

export default CompletedActionsDashboard
