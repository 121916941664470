import React, { FC } from 'react'
import { AuthContextProvider, LocalStorageAuthContextBackend } from '../contexts/AuthContext'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ChakraProvider } from '@chakra-ui/react'
import './WebApp.scss'
import { BrowserRouter } from 'react-router-dom'
import {
  ExtensionHealthContextProvider,
  WebAppExtensionHealthContextBackend,
} from '../contexts/ExtensionHealthContext'
import { FirstLoadContextProvider } from '../contexts/FirstLoadContext'
import { UserContextProvider } from '../contexts/UserContext'
import { OAUTH_WEB_CLIENT_ID } from '../constants'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {
  FeatureFlagContextProvider,
  LocalStorageFeatureFlagContextBackend,
} from '../contexts/FeatureFlagContext'
import { Provider as ReduxProvider } from 'react-redux'
import webappStore from './redux/webappStore'
import chakraTheme from '../styles/chakraTheme'
import MainPage from './pages/MainPage'
import AppLoadingWrapper from '../components/AppLoadingWrapper'

const localStorageAuthContextBackend = new LocalStorageAuthContextBackend('skeema_auth_token')
const webappExtensionHealthContextBackend = new WebAppExtensionHealthContextBackend()
const localStorageFeatureFlagContextBackend = new LocalStorageFeatureFlagContextBackend()

const WebApp: FC = () => {
  return (
    <GoogleOAuthProvider clientId={OAUTH_WEB_CLIENT_ID}>
      <ChakraProvider theme={chakraTheme}>
        <ReduxProvider store={webappStore}>
          <AuthContextProvider backend={localStorageAuthContextBackend}>
            <BrowserRouter>
              <FirstLoadContextProvider>
                <ExtensionHealthContextProvider backend={webappExtensionHealthContextBackend}>
                  <UserContextProvider isInExtension={false}>
                    <FeatureFlagContextProvider backend={localStorageFeatureFlagContextBackend}>
                      <DndProvider backend={HTML5Backend}>
                        <AppLoadingWrapper shouldWaitForAuth={true} shouldWaitForUserInfo={true}>
                          <MainPage />
                        </AppLoadingWrapper>
                      </DndProvider>
                    </FeatureFlagContextProvider>
                  </UserContextProvider>
                </ExtensionHealthContextProvider>
              </FirstLoadContextProvider>
            </BrowserRouter>
          </AuthContextProvider>
        </ReduxProvider>
      </ChakraProvider>
    </GoogleOAuthProvider>
  )
}

export default WebApp
