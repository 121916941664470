import React, { FC } from 'react'
import styled from 'styled-components'
import { AiOutlineChrome } from 'react-icons/ai'
import { Button, Tooltip } from '@chakra-ui/react'
import { sendMessageToExtension } from '../../webapp/utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../extension/models/messaging.types'
import { useUserContext } from '../../contexts/UserContext'
import { shouldOpenInNewTab } from '../../utils/utils'

const faviconSizeMap: { [k: string]: string } = {
  sm: '12px',
  md: '16px',
  lg: '32px',
}
const containerSizeMap: { [k: string]: string } = {
  sm: '16px',
  md: '28px',
  lg: '48px',
}

const ChildIconContainer = styled.span<{
  $size: string
  $isClickable: boolean
}>`
  display: flex;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  flex: 0 0 auto;

  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  color: black;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: ${({ $isClickable }) => ($isClickable ? '#EBEBEB' : 'none')};
  }
`

export interface FavIconListItemType {
  id: string
  faviconUrl: string
  isOpen?: boolean
  url?: string
  title?: string
  tabId?: number
  windowId?: number
  maxLength?: number
}

interface Props {
  items: FavIconListItemType[]
  areaName?: string
  isClickable?: boolean
  showTitleTooltip?: boolean
  size?: keyof typeof faviconSizeMap
  maxLength?: number
  onClickExpand?: () => void
}

const FavIconList: FC<Props> = (props) => {
  const {
    items,
    areaName,
    maxLength,
    onClickExpand,
    isClickable = false,
    showTitleTooltip = false,
    size = 'sm',
  } = props
  const { captureAnalytics } = useUserContext()

  const onClickFavicon = async (
    event: React.MouseEvent<Element, MouseEvent>,
    item: FavIconListItemType,
  ) => {
    if (!isClickable) {
      return
    }
    const component = areaName ? `tablist_${areaName}` : 'tablist'
    captureAnalytics(`${component}:favicon_list_click`, {})

    if (item.isOpen) {
      console.log('Switching to tab', item.id, item.tabId, item.windowId)
      await sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.SWITCH_TO_TAB, {
        tabId: item.tabId,
        windowId: item.windowId,
      })
    } else {
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.OPEN_TABS, {
        urls: [item.url],
        shouldActivate: !shouldOpenInNewTab(event),
      })
    }
  }

  const itemsToDisplay = maxLength ? items.slice(0, maxLength) : items
  const numExtraItems = maxLength ? items.length - maxLength : 0
  const moreThanSingleDigit = numExtraItems > 9

  return (
    <>
      {itemsToDisplay.map((item, idx) => (
        <ChildIconContainer
          key={`${idx}_${item.faviconUrl}`}
          $size={containerSizeMap[size]}
          $isClickable={isClickable}
        >
          <Tooltip
            placement="top"
            label={item.title}
            isDisabled={!showTitleTooltip}
            shouldWrapChildren={true}
          >
            {item.faviconUrl ? (
              <img
                src={item.faviconUrl}
                style={{ width: faviconSizeMap[size], height: faviconSizeMap[size] }}
                onClick={(e) => onClickFavicon(e, item)}
              />
            ) : (
              <AiOutlineChrome
                style={{
                  width: faviconSizeMap[size],
                  height: faviconSizeMap[size],
                  color: 'black',
                }}
                onClick={(e) => onClickFavicon(e, item)}
              />
            )}
          </Tooltip>
        </ChildIconContainer>
      ))}
      {numExtraItems > 0 && !moreThanSingleDigit && (
        <Button
          key="extra"
          size="sm"
          fontSize={12}
          h={faviconSizeMap[size]}
          px={1}
          onClick={onClickExpand && (() => onClickExpand())}
          bg="#F6F6F6"
          _hover={{ bg: '#EBEBEB' }}
        >
          +{numExtraItems}
        </Button>
      )}
      {numExtraItems > 0 && moreThanSingleDigit && (
        <Button
          key="extra"
          size="sm"
          fontSize={12}
          fontWeight={500}
          h={containerSizeMap[size]}
          minH={containerSizeMap[size]}
          px={2}
          onClick={onClickExpand && (() => onClickExpand())}
          bg="#F6F6F6"
          color="#585858"
          _hover={{ bg: '#EBEBEB' }}
        >
          See more
        </Button>
      )}
    </>
  )
}

export default FavIconList
