import { DateTime } from 'luxon'
import { TabRecordType } from '../extension/models/tabRecord.types'
import { TablistPageType, TABLIST_PAGE_ENUM } from '../models/tablist_pages.types'
import { TOLERATED_TAB_URLS, WEBAPP_BASE_URL } from '../constants'

export const isSkeemaUrl = (params: {
  url?: string
  shouldIncludeToleratedTabUrls?: boolean
}): boolean => {
  const { url, shouldIncludeToleratedTabUrls = true } = params
  if (!url) {
    return false
  }

  const isToleratedUrl = TOLERATED_TAB_URLS.some((toleratedUrl) => url.startsWith(toleratedUrl))
  if (!shouldIncludeToleratedTabUrls && isToleratedUrl) {
    return false
  }

  return url.startsWith(WEBAPP_BASE_URL)
}

export const getStaleTabRecords = (params: {
  tabRecords: TabRecordType[]
  shouldHaveExperimentalPutawayV2: boolean
  windowId: number
  tabOverwhelmThreshold: number
  stalenessThresholdBeforeOverwhelm: number
  stalenessThresholdAfterOverwhelm: number
}): TabRecordType[] => {
  const {
    tabRecords,
    shouldHaveExperimentalPutawayV2,
    windowId,
    tabOverwhelmThreshold,
    stalenessThresholdBeforeOverwhelm,
    stalenessThresholdAfterOverwhelm,
  } = params

  const qualifiedTabRecords = tabRecords
    .filter((record) => {
      return (
        !record.active &&
        !record.pinned &&
        record.windowId === windowId &&
        !isSkeemaUrl({ url: record.url, shouldIncludeToleratedTabUrls: false }) &&
        !record.audible &&
        !record.isFocused
      )
    })
    .sort((a, b) => a.lastVisitTime - b.lastVisitTime)

  const unpinnedTabRecords = tabRecords.filter((record) => {
    return !record.pinned && record.windowId === windowId
  })
  const beyondOverwhelmThreshold = unpinnedTabRecords.length > tabOverwhelmThreshold
  const staleThreshold = beyondOverwhelmThreshold
    ? stalenessThresholdAfterOverwhelm
    : stalenessThresholdBeforeOverwhelm
  const maxNumTabsToPutaway = beyondOverwhelmThreshold
    ? unpinnedTabRecords.length - tabOverwhelmThreshold
    : unpinnedTabRecords.length

  if (shouldHaveExperimentalPutawayV2) {
    const tabRecordsToPutaway = qualifiedTabRecords
      .filter((record) => {
        return staleThreshold !== 0 && record.staleness > staleThreshold
      })
      .slice(0, maxNumTabsToPutaway)

    return tabRecordsToPutaway
  } else {
    const cutoffTime = DateTime.now().minus({ milliseconds: staleThreshold }).toMillis()
    const tabRecordsToPutaway = qualifiedTabRecords
      .filter((record) => {
        return staleThreshold !== 0 && record.lastVisitTime < cutoffTime
      })
      .slice(0, maxNumTabsToPutaway)

    return tabRecordsToPutaway
  }
}

function isArticle(url: string): boolean {
  // NOTE: This implementation should be kept in sync with the frontend implementation
  // in order to detect which open tabs are articles.  If it becomes too complex, we should
  // consider requesting the frontend to send open tabs to the backend.
  try {
    const hasKeywords = /\b(article|news|blog|post|story)\b/i.test(url)
    const isSubstackPattern = /^https:\/\/[\w.]*\/p\/[\w-]*$/i.test(url.split('?')[0])
    const fromKnownDomains =
      /\b(wikipedia|medium|nytimes|theatlantic|washingtonpost|theguardian|bbc|cnn|reuters|bloomberg|wsj|forbes|businessinsider|techcrunch|arstechnica|theverge|engadget|wired|gizmodo|lifehacker|slashdot|digg|reddit|quora|pdf)\b/i.test(
        url,
      )
    if (hasKeywords || fromKnownDomains || isSubstackPattern) {
      return true
    }
  } catch (e) {
    console.error(`Error checking if url is article:`, e)
  }
  return false
}

export const mapTabRecordToTablistPage = (record: TabRecordType): TablistPageType => {
  return {
    id: record.id,
    title: record.title,
    url: record.url,
    favicon_url: record.favIconUrl,
    last_access_timestamp_ms: record.lastVisitTime,
    last_access_datetime_utc_iso: new Date(record.lastVisitTime).toISOString(),
    entity_type: TABLIST_PAGE_ENUM.OPEN,
    is_pinned: record.pinned,
    index: record.index,
    window_id: record.windowId,
    group_id: record.groupId,
    is_audible: record.audible,
    is_toread: isArticle(record.url),
    is_open: true,
  }
}
