import { defineStyleConfig } from '@chakra-ui/react'

// define the base component styles
const baseStyle = {
  px: '12px',
  py: '8px',
  bg: '#000000',
  borderRadius: '8px',
  color: '#ffffff',
  fontSize: '12px',
}

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle })
