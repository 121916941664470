import React, { FC } from 'react'
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  Heading,
} from '@chakra-ui/react'
import { modalCloseButtonClass } from './PaymentFlowModal'

interface Props {
  onClickRetry: () => void
}

const CheckoutErrorModalContent: FC<Props> = (props) => {
  const { onClickRetry } = props

  return (
    <ModalContent p="32px" borderRadius="16px" width="450px">
      <ModalHeader p="0" mb="16px">
        <Heading fontSize="20px" fontWeight={600} color="#000" lineHeight="28px">
          Something went wrong
        </Heading>
      </ModalHeader>
      <ModalCloseButton className={modalCloseButtonClass} />

      <ModalBody p="0" mb="32px" overflow="hidden">
        <Text fontSize="16px" color="#000" lineHeight="24px">
          There was an error processing your payment.
          <br />
          Please try again.
        </Text>
      </ModalBody>
      <ModalFooter p="0">
        <Button
          bg="black"
          color="white"
          borderRadius="100px"
          height="36px"
          _hover={{
            bg: '#585858',
          }}
          ml="auto"
          onClick={onClickRetry}
        >
          Try again
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default CheckoutErrorModalContent
