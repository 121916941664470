import {
  useRef,
  useEffect,
  DependencyList,
  EffectCallback,
  useState,
  Dispatch,
  useCallback,
} from 'react'
import { setWindowStorage } from './utils'

export function useDebouncedEffect(
  timeout: number,
  callback: EffectCallback,
  deps?: DependencyList | undefined,
) {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  return useEffect(() => {
    timer.current = setTimeout(callback, timeout)

    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, ...(deps ? deps : [])])
}

export function useDebounced<T>(timeout: number, state: T) {
  const [debouncedState, setDebouncedState] = useState<T>(state)

  useDebouncedEffect(timeout, () => setDebouncedState(state), [state])

  return debouncedState
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

//Old version from react-use-localstorage package can be found here: https://github.com/dance2die/react-use-localstorage/blob/master/src/index.ts
//Somewhat misnamed since not actually a generic function (though used that way below)
function useWindowStorageGeneric(
  key: string,
  initialValue: string | null = null,
  storageArea: 'localStorage' | 'sessionStorage' = 'localStorage',
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [string | null, Dispatch<string | null>] {
  const { listenToCurrentWindow = false, messageCurrentWindow = false } = options ?? {}
  const [INITIAL_VALUE] = useState<string | null>(initialValue)
  const [value, setValue] = useState<string | null>(() => {
    const storedValue = window[storageArea].getItem(key)
    return storedValue ?? INITIAL_VALUE
  })

  //Update value on key change
  useEffect(() => {
    const storedValue = window[storageArea].getItem(key)
    setValue(storedValue ?? INITIAL_VALUE)
  }, [key, INITIAL_VALUE, storageArea])

  //Watch localStorage for key change
  useEffect(() => {
    const handleStorage = (event: StorageEvent) => {
      if (event.storageArea !== window[storageArea]) {
        return
      }

      if (listenToCurrentWindow && event.key === undefined) {
        //Manually dispatched event
        setValue(window[storageArea].getItem(key) ?? INITIAL_VALUE)
      } else if (event.key === key) {
        setValue(event.newValue ?? INITIAL_VALUE)
      }
    }

    window.addEventListener('storage', handleStorage)
    return () => window.removeEventListener('storage', handleStorage)
  }, [INITIAL_VALUE, key, listenToCurrentWindow, storageArea])

  const setItem = useCallback(
    (newValue: string | null) => {
      setWindowStorage(key, newValue, storageArea, messageCurrentWindow)
      setValue(newValue) //Don't exclusively rely on eventListener to update local state
    },
    [key, messageCurrentWindow, storageArea],
  )

  return [value, setItem]
}

export function useLocalStorageNonNullable<T extends string = string>(
  key: string,
  initialValue: T,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [T, Dispatch<T>] {
  return useWindowStorageGeneric(key, initialValue, 'localStorage', options) as unknown as [
    T,
    Dispatch<T>,
  ]
}

export function useLocalStorageBoolean(
  key: string,
  initialValue: boolean | null,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [boolean | null, Dispatch<boolean>] {
  const [strVal, setStrVal] = useWindowStorageGeneric(
    key,
    initialValue === null ? null : String(initialValue),
    'localStorage',
    options,
  )
  const boolVal = strVal === null ? null : strVal === 'true'
  const dispatchBool = useCallback((newValue: boolean) => setStrVal(String(newValue)), [setStrVal])
  return [boolVal, dispatchBool]
}

export function useLocalStorageNumber(
  key: string,
  initialValue: number | null,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [number | null, Dispatch<number>] {
  const [strVal, setStrVal] = useWindowStorageGeneric(
    key,
    initialValue === null ? null : String(initialValue),
    'localStorage',
    options,
  )
  const numVal = strVal === null ? null : parseFloat(strVal)
  const dispatchNum = useCallback((newValue: number) => setStrVal(String(newValue)), [setStrVal])
  return [numVal, dispatchNum]
}

export function useLocalStorageObject<T>(
  key: string,
  initialValue: T | null = null,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [T | null, Dispatch<T | null>] {
  const [strVal, setStrVal] = useWindowStorageGeneric(
    key,
    initialValue === null ? null : JSON.stringify(initialValue),
    'localStorage',
    options,
  )
  const val = strVal === null ? null : (JSON.parse(strVal) as T)
  const dispatchVal = useCallback(
    (newValue: T | null) => {
      setStrVal(newValue === null ? null : JSON.stringify(newValue))
    },
    [setStrVal],
  )
  return [val, dispatchVal]
}

export function useLocalStorage<T extends string | null = string | null>(
  key: string,
  initialValue: T | null = null,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [T | null, Dispatch<T | null>] {
  return useWindowStorageGeneric(key, initialValue, 'localStorage', options) as [
    T | null,
    Dispatch<T | null>,
  ]
}

export function useSessionStorageBoolean(
  key: string,
  initialValue: boolean,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [boolean, Dispatch<boolean>] {
  const [strVal, setStrVal] = useWindowStorageGeneric(
    key,
    String(initialValue),
    'sessionStorage',
    options,
  )
  const boolVal = strVal === 'true'
  const dispatchBool = useCallback((newValue: boolean) => setStrVal(String(newValue)), [setStrVal])
  return [boolVal, dispatchBool]
}

export function useSessionStorage<T extends string | null = string | null>(
  key: string,
  initialValue: T | null = null,
  options?: { listenToCurrentWindow?: boolean; messageCurrentWindow?: boolean },
): [T | null, Dispatch<T | null>] {
  return useWindowStorageGeneric(key, initialValue, 'sessionStorage', options) as [
    T | null,
    Dispatch<T | null>,
  ]
}

export function useOnClickOutsideRefs(
  refs: (React.MutableRefObject<Node> | React.RefObject<Node>)[],
  handler: (event?: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      for (let i = 0; i < refs.length; i++) {
        // Do nothing if clicking ref's element or descendent elements
        if (!refs[i].current || refs[i].current?.contains(event.target as Node | null)) {
          return
        }
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [refs, handler])
}
