import React, { FC } from 'react'
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom'
import AnnouncementsPage from '../../components/AnnouncementsPage'
import NotFoundPage from '../../pages//notFound/NotFoundPage'
import LoginHandler from './login/LoginHandler'
import ExtensionInstalledPage from './extensionInstalled/ExtensionInstalledPage'
import InstallExtensionPage from './installExtension/InstallExtensionPage'
import UpdateExtensionPage from '../../pages/updateExtension/UpdateExtensionPage'
import MorningPageV3 from '../../pages/morningPageV3/MorningPage'
import MorningPageV4 from '../../pages/morningPageV4/MorningPage'
import ExtensionUninstalledPage from './extensionUninstalled/ExtensionUninstalledPage'
import { useFeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { FEATURE_FLAG_KEYS_ENUM } from '../../models/feature_flags.types'
import { useAuthContext } from '../../contexts/AuthContext'
import AuthSyncPage from './authSync/AuthSyncPage'
import { CONTENT_TYPE as CONTENT_TYPE_V4 } from '../../pages/morningPageV4/MainDashboard'
import { CONTENT_TYPE as CONTENT_TYPE_V3 } from '../../pages/morningPageV3/MainDashboard'
import MobileSignupPage from './mobileSignup/MobileSignupPage'
import { isMobileOrTabletDevice } from '../../utils/deviceUtils'

enum ROUTES_ENUM {
  EMPTY = '/',
  AUTH_HANDLER = '/auth/handler',
  INSTALL_EXTENSION = '/extension/install',
  UPDATE_EXTENSION = '/extension/update',
  EXTENSION_INSTALLED = '/extension/installed',
  EXTENSION_UNINSTALLED = '/extension/uninstalled',
  SAVED = '/saved',
  COMPLETED_ACTIONS = '/actions',
  ARCHIVE = '/archive',
  PROJECTS = '/projects',
  FOLDERS = '/folders',
  SETTINGS = '/settings',
  AUTH_SYNC = '/auth-sync',
  SESSIONS = '/sessions',
  MOBILE_SIGNUP = '/mobile/signup',
}

const getVersionOverrideParamValue = (searchParams: URLSearchParams) => {
  const versionOverrideParam = searchParams.get('__version')
  return versionOverrideParam === 'v4' ? 'v4' : versionOverrideParam === 'v3' ? 'v3' : null
}

const MorningPageSwitcher: FC<{
  pageType: 'v3' | 'v4'
  contentType: CONTENT_TYPE_V3 | CONTENT_TYPE_V4
}> = (props) => {
  const { pageType, contentType } = props

  if (pageType === 'v3') {
    return <MorningPageV3 contentType={contentType} />
  }

  if (pageType === 'v4') {
    return <MorningPageV4 contentType={contentType} />
  }

  return <NotFoundPage />
}

const RequireAuth: FC<{ children: React.JSX.Element; loginPath: string }> = ({
  children,
  loginPath,
}) => {
  const { isAuthenticated } = useAuthContext()

  if (!isAuthenticated) {
    return <Navigate to={loginPath} />
  }
  return children
}

const RequireDesktop: FC<{ children: React.JSX.Element }> = ({ children }) => {
  if (isMobileOrTabletDevice) {
    return (
      <AnnouncementsPage
        heading={"This page doesn't support mobile.  For now. 😉"}
        content={['Please use your desktop browser to access Skipper.']}
      />
    )
  }
  return children
}

const MainPage: FC = () => {
  const { featureFlags } = useFeatureFlagContext()
  const isAlpha = featureFlags[FEATURE_FLAG_KEYS_ENUM.IsAlphaUser]
  const [searchParams] = useSearchParams()
  const versionOverride = getVersionOverrideParamValue(searchParams)
  const ffMorningPageType = isAlpha ? 'v4' : 'v3'
  const morningPageType = versionOverride || ffMorningPageType

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Routes>
        <Route
          path={ROUTES_ENUM.AUTH_HANDLER}
          element={
            <RequireDesktop>
              <LoginHandler />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.INSTALL_EXTENSION}
          element={
            <RequireDesktop>
              <InstallExtensionPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.UPDATE_EXTENSION}
          element={
            <RequireDesktop>
              <UpdateExtensionPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.EXTENSION_INSTALLED}
          element={
            <RequireDesktop>
              <ExtensionInstalledPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.EXTENSION_UNINSTALLED}
          element={
            <RequireDesktop>
              <ExtensionUninstalledPage />
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.EMPTY}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="tablist" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.COMPLETED_ACTIONS}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="actions" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.SAVED}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="saved" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.SESSIONS}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="sessions" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={ROUTES_ENUM.ARCHIVE}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="archive" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route
          path={`${ROUTES_ENUM.FOLDERS}/:projectId`}
          element={<MorningPageSwitcher pageType={morningPageType} contentType="project" />}
        />
        <Route
          path={`${ROUTES_ENUM.PROJECTS}/:projectId`}
          element={<MorningPageSwitcher pageType={morningPageType} contentType="project" />}
        />
        <Route
          path={ROUTES_ENUM.SETTINGS}
          element={
            <RequireDesktop>
              <RequireAuth loginPath={ROUTES_ENUM.EXTENSION_INSTALLED}>
                <MorningPageSwitcher pageType={morningPageType} contentType="settings" />
              </RequireAuth>
            </RequireDesktop>
          }
        />
        <Route path={ROUTES_ENUM.MOBILE_SIGNUP} element={<MobileSignupPage />} />
        <Route
          path={ROUTES_ENUM.AUTH_SYNC}
          element={
            <RequireDesktop>
              <AuthSyncPage />
            </RequireDesktop>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}

export default MainPage
