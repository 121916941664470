import React, { FC, ReactNode } from 'react'
import { MdClose } from 'react-icons/md'
import styled, { css } from 'styled-components'

const Container = styled.button<{ $isToggledOn: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #d5d5d5;
  color: #a7a7a7;
  transition: all 0.2s ease-in-out;

  ${({ $isToggledOn }) =>
    $isToggledOn
      ? css`
          border-color: #000;
          color: #fff;
          background-color: #000;
        `
      : ''};

  cursor: pointer;
  * {
    pointer-events: none;
  }

  & .main-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  & .close-icon {
    height: 16px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 0px;
    transition: width 0.2s ease-in-out;

    ${({ $isToggledOn }) =>
      $isToggledOn
        ? css`
            width: 16px;
          `
        : ''};

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  & .label {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    ${({ $isToggledOn }) =>
      $isToggledOn
        ? ''
        : css`
            color: #585858;
            border-color: #585858;
          `};
  }
`

interface Props {
  icon: ReactNode
  label: string
  isToggledOn: boolean
  onClick: () => void
}

const ToggleButton: FC<Props> = (props) => {
  const { icon, label, isToggledOn, onClick } = props

  return (
    <Container $isToggledOn={isToggledOn} onClick={onClick}>
      <span className="main-icon">{icon}</span>
      <label className="label">{label}</label>
      <span className="close-icon">
        <MdClose />
      </span>
    </Container>
  )
}

export default ToggleButton
